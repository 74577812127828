<template lang="pug">
.HeaderAvatar
    .HeaderAvatar__badge(v-if="showModeratingBadge")
        .HeaderAvatar__badge-text(v-html="text.avatarInModeration")

    .HeaderAvatar__icon(
        :class="classList"
        :style="avatarSizeStyle"
    )
        .HeaderAvatar__image-container(:style="sizeStyle")
            VIcon.HeaderAvatar__image(icon="profile")

        .HeaderAvatar__overlay(
            v-if="showOverlay"
            :style="sizeStyle"
        )
            transition(
                name="fade"
                duration="200"
                mode="out-in"
            )
                VIcon(
                    :icon="icon"
                    :key="icon"
                )
</template>

<script>
import { mapGetters } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';
import VDropdown from '@/components/VDropdown/VDropdown.vue';
import VDropdownItem from '@/components/VDropdown/VDropdownItem.vue';

export default {
    components: {
        VDropdown,
        VDropdownItem,
        VIcon,
    },

    props: {
        active: Boolean,
        showOverlay: Boolean,
        showModeratingBadge: Boolean,
        size: {
            type: Number,
            default: 35,
        },
    },

    computed: {
        ...mapGetters([
            'showVipDomain',
            'langCommon',
        ]),

        text() {
            return this.langCommon.UserAvatar;
        },

        sizeStyle() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
            };
        },

        avatarSizeStyle() {
            const size = this.showVipDomain ? this.size + 2 : this.size;

            return {
                width: `${size}px`,
                height: `${size}px`,
            };
        },

        classList() {
            return {
                'HeaderAvatar--vip': this.showVipDomain,
                'HeaderAvatar--active': this.active,
            };
        },

        icon() {
            return this.isActive ? 'times' : 'dots';
        },
    },
};
</script>

<style lang="stylus">
.HeaderAvatar
    position relative

    &.HeaderAvatar--vip
        background-image linear-gradient(47deg, #d0a938 3%, #dab94d 10%, #fef19b 62%, #d5b245 88%, #c7940f)
        width 37px
        height 37px

    &.HeaderAvatar--active
    &:hover
    &:focus
        .HeaderAvatar__overlay
            opacity 1

.HeaderAvatar
.HeaderAvatar__overlay
    border-radius 50%
    display flex
    align-items center
    justify-content center

.HeaderAvatar__overlay
    opacity 0
    position absolute
    background-color #1f2841
    font-weight 600
    transform rotateZ(90deg)
    font-size 15px
    transition opacity .2s, filter .3s

.HeaderAvatar__icon
    overflow hidden

.HeaderAvatar__badge
    position absolute
    width 12px
    height 12px
    border solid 2px #111829
    background-color #ffd471
    top -3px
    right 1px
    border-radius 50%
    z-index 1
    cursor pointer

    &:hover .HeaderAvatar__badge-text
        display block

.HeaderAvatar__badge-text
    text-align center
    display none
    position absolute
    background-color #fff
    color #000
    width 147px
    padding 10px
    font-size 11px
    border-radius 12px
    transform translate(calc(-50% - 1.5px), 16px)

    &:after
        content ''
        background-color #fff
        width 12px
        height 12px
        display block
        position absolute
        top -6px
        left 50%
        transform rotateZ(45deg)

.HeaderAvatar__image-container
    background-color #1f2841
    border-radius 50%
    display flex
    align-items center
    justify-content center

.HeaderAvatar__image
    vertical-align middle
    user-select none
</style>
