<template lang="pug">
#navigation(:class="navClass")
    MenuBar.bar(
        ref="bar"
        @scroll="updateMenuTint"
    )
        NavigationItem(
            v-for="link in links"
            :key="link.name + (link.route.hash || '')"
            :link="link"
            ref="links"
            @updateScroll="updateScroll"
        )
    Controls(
        v-if="controls"
        :config="controls"
    )
</template>

<script>
import { mapGetters } from 'vuex';

import {
    HEADER_INVEST,
    HEADER_LEADERBOARD,
    OFFSET_HEADER_ITEM_FROM_BLUR,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
} from '@/constant/constants';

import HorizontalMenu from '@mobile/components/HorizontalMenu.vue';
import MenuBar from '@mobile/components/MenuBar.vue';

import Controls from './NavigationControls/Controls.vue';
import NavigationItem from './NavigationItem.vue';

const menuBarTintMaxWidth = 85;

export default {
    components: {
        NavigationItem,
        MenuBar,
        Controls,
    },

    mixins: [
        HorizontalMenu,
    ],

    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        controls: {
            type: Object, // { search: { routeName: String }, filter: {} }
            default: null,
        },
    },

    computed: {
        ...mapGetters('navigationMenu', [
            'orderedTopNavigationMenuItems',
            'isRouteActive',
        ]),
        ...mapGetters('permissions', ['isTester']),

        links() {
            return this.orderedTopNavigationMenuItems(this.tabs);
        },

        isInvestPage() {
            return this.customHeader === HEADER_INVEST;
        },

        isLeaderboardPage() {
            return this.customHeader === HEADER_LEADERBOARD;
        },

        customHeader() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_HEADER_BOTTOM];
        },

        navClass() {
            return {
                'invest-theme': this.isInvestPage,
                'with-controls': this.controls,
                'leaderboard-theme': this.isLeaderboardPage,
            };
        },
    },

    watch: {
        '$route.name': function handleRouteName() {
            this.updateScroll();
        },
    },

    mounted() {
        this.updateScroll();
        this.updateMenuTint();
    },

    methods: {
        updateScroll() {
            this.$nextTick(() => {
                const $nav = this.$refs.bar;
                const indexActiveLink = this.links.findIndex((link) => this.isRouteActive(this.$route, link));
                const { $el: $cur } = this.$refs.links?.[indexActiveLink] ?? {};

                if (!$cur) {
                    return;
                }

                this.scrollTo($nav, $cur, ...this.$route.meta?.[OFFSET_HEADER_ITEM_FROM_BLUR] ? [51] : []);
            });
        },

        updateMenuTint() {
            const { /** @type {HTMLElement|undefined} */ $el } = this.$refs.bar ?? {};
            if (!$el) {
                return;
            }

            const { clientWidth, scrollWidth, scrollLeft } = $el;
            $el.style.setProperty(
                '--navigation__tint-width',
                `${Math.min(scrollWidth - clientWidth - scrollLeft, menuBarTintMaxWidth)}px`,
            );
        },
    },
};
</script>

<style lang="stylus" scoped>
$menuBarTintMaxWidth = 85px
$menuBarTintMaxWidthModern = var(--navigation__tint-width, $menuBarTintMaxWidth)

#navigation
    display flex
    width 100%
    background-color $dark-900
    overflow hidden
    justify-content space-between
    height $layout-default-nav-top-height
    border-bottom 1px solid $dark-700

    &.invest-theme
        background #0a052c
        background var(--main-background-color, #0a052c)

    &.leaderboard-theme
        background-color #000113
        background var(--main-background-color, #000113)
        border-bottom none

    &.with-controls
        .bar
            mask-image linear-gradient(to left, transparent 0%, black $menuBarTintMaxWidth)
            mask-image linear-gradient(to left, transparent 0%, black $menuBarTintMaxWidthModern)
            &::after
                content ''
                padding-left 80px

    .navigation-item-badge
        display: flex
        justify-content: center
        align-items: center
        border-radius: 1em
        margin-right: 1em
        height: 2em
        width: 4em
        font-size: 8px
        font-weight 800

    .navigation-item-image
        height 15px
</style>
