<template>
  <RouterLink
    :class="isDiminished ? $style.rootDiminished : $style.root"
    :style="$options.rootStyle"
    :to="{ name: ROUTE_NAME.FREE_MONEY }"
  >
    <span
      :class="$style.image"
      :style="imageStyle"
    />
    <span
      :class="$style.coin"
      :style="coinStyle"
    />
    <template v-if="!isDiminished">
      <span :class="$style.flare1" />
      <span :class="$style.flare2" />
    </template>
    <span :class="$style.header">Free money</span>
    <span :class="$style.subheader">{{ $store.getters.langMobile.retention.retentionLinkSubheader }}</span>
  </RouterLink>
</template>

<script>
import FreeMoneyLinkCoinDiminished from '@mobile/assets/images/free-money-link-coin.png?sizes[]=38';
import FreeMoneyLinkCoin from '@mobile/assets/images/free-money-link-coin.png?sizes[]=52';
import FreeMoneyLinkDiminished from '@mobile/assets/images/free-money-link-image-diminished.png';
import FreeMoneyLinkImage from '@mobile/assets/images/free-money-link-image.png';
import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    props: {
        isDiminished: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        imageStyle() {
            return {
                backgroundImage: this.isDiminished
                    ? `url("${FreeMoneyLinkDiminished}")`
                    : `url("${FreeMoneyLinkImage}")`,
            };
        },

        coinStyle() {
            return {
                backgroundImage: this.isDiminished
                    ? `url("${FreeMoneyLinkCoinDiminished}")`
                    : `url("${FreeMoneyLinkCoin}")`,
            };
        },
    },

    ROUTE_NAME,
};
</script>

<style lang="stylus" module>
.root
    background-image linear-gradient(283.27deg, #FFB800 0%, #FFF173 138.82%)
    box-shadow 0 5px 15px rgba(255, 217, 19, 0.4)
    height 50px
    display flex
    justify-content center
    flex-direction column
    padding-left 20px
    position relative

.header
    display block
    font-size 13px
    font-weight 600
    color #090F1E
    margin-bottom 2px

.subheader
    display block
    color rgba(9, 15, 30, 0.8)
    opacity .9
    font-weight 600
    font-size 9px

.image
    width 117px
    height 43px
    background-repeat no-repeat
    background-size contain
    background-position right bottom
    position absolute
    right 0
    bottom 0
    border-bottom-right-radius 8px

.coin
    width 26px
    height 29px
    background-repeat no-repeat
    background-size contain
    position absolute
    top 2px
    left -10px
    filter drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.21)) drop-shadow(-1px 0px 6px rgba(251, 226, 147, 0.6))

.flare
    position absolute
    border-radius 50px
    background-color #fff
    opacity .5
    filter blur(1px)

.flare1
    composes flare
    width 4px
    height 4px
    top 6px
    left 18px

.flare2
    composes flare
    width 2px
    height 2px
    top 33px
    left 9px

.rootDiminished
    composes root

    @media (min-width 521px)
        align-items center
        padding-left 0

        .image
            width 110px

        .coin
            width 19px
            top 1px
            left -7px
</style>
