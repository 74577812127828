import { GUARD_VERIFY } from '@/constant/constants';

import { MODAL_VERIFICATION } from '@mobile/constant/modalName';

export default (to, from, next, router) => {
    const isVerifyRequired = to.matched.some((route) => route.meta[GUARD_VERIFY] === true);

    if (isVerifyRequired) {
        const intl = setInterval(
            async () => {
                if (router.app != null) {
                    clearInterval(intl);
                    await router.app.$store.dispatch('getVerifyStatus', null, { root: true });

                    const { blockedByVerification } = router.app.$store.getters;

                    if (blockedByVerification) {
                        next(false);
                        router.replace({
                            query: {
                                modal: JSON.stringify({ name: MODAL_VERIFICATION }),
                            },
                        });
                    } else {
                        next();
                    }
                }
            },
            16,
        );
    } else {
        next();
    }
};
