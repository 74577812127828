<template lang="pug">
.search
    VInput.search-input(
        key="input"
        type="search"
        ref="input"
        :class="{ 'cancelable': isSearchPage }"
        :placeholder="placeholder"
        :value="searchNew"
        @focus="focused"
        @input="onInput"
        @enter="onEnter"
    )
    transition(name="fade" mode="out-in")
        .search-cancel(
            v-if="isSearchPage"
            :class="{ 'cancelable': isSearchPage }"
            @click="goBack"
        ) {{ text.cancel }}
</template>

<script>
import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';

import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    data: () => ({
        prevWidth: 0,
        textLang: {
            all: {
                search: 'all',
            },
            [ROUTE_NAME.BETS_LIVE]: {
                search: 'live',
            },
            [ROUTE_NAME.BETS_PREMATCH]: {
                search: 'prematch',
            },
            [ROUTE_NAME.BETS_CYBERSPORT]: {
                search: 'cybersport',
            },
            // [ROUTE_NAME.BETS_RESULT]: {
            //     search: 'results',
            // },
            [ROUTE_NAME.CASINO]: {
                search: 'casino',
            },
            [ROUTE_NAME.CASINO_LIVE]: {
                search: 'casino-live',
            },
            [ROUTE_NAME.TV]: {
                search: 'tv',
            },
        },
        searchNew: '',
    }),

    computed: {
        ...mapGetters([
            'localeFormat',
            'lang',
        ]),

        search() {
            return this.$route.query.search;
        },

        routeName() {
            return this.$route.params.routeName;
        },

        textPlaceholder() {
            return this.textLang[this.$route.query.tab || 'all'] || {};
        },

        text() {
            return this.lang['search-input'];
        },

        placeholder() {
            return this.text[this.textPlaceholder.search];
        },

        isSearchPage() {
            return this.$route.name === ROUTE_NAME.SEARCH_RESULT;
        },

        searchNormalized() {
            return this.searchNew.trim().replace(/\s+/g, ' ');
        },
    },

    watch: {
        '$route.name': function handleRouteName() {
            this.setFocus();
        },
    },

    created() {
        this.searchNew = this.search;
        this.updateSearchDebounced = debounce(this.updateSearch, 800);
    },

    mounted() {
        setTimeout(() => {
            this.setFocus();
        }, 200);
    },

    methods: {
        onEnter(e) {
            this.updateSearch(this.searchNormalized);
            e.target.blur();
        },

        updateSearch(search) {
            if (this.$route.query.search === search) {
                return;
            }

            const query = {
                ...this.$route.query,
                search,
            };

            if (!query.search) {
                delete query.search;
            }

            this.$router.push({
                name: this.$route.name,
                query,
            });
        },

        focused() {
            if (!this.isSearchPage) {
                this.$router.push({
                    name: ROUTE_NAME.SEARCH_RESULT,
                    params: {
                        routeName: this.routeName,
                    },
                });
            }
        },

        setFocus() {
            if (this.isSearchPage) {
                this.$refs.input.$refs.input.focus();
            }
        },

        goBack() {
            this.searchNew = '';
            this.$router.push({ name: this.routeName });
        },

        onInput(val) {
            this.searchNew = val;
            this.updateSearchDebounced(this.searchNormalized);
        },
    },
};
</script>

<style lang="stylus" scoped>
$width-cancel = 83px

.search
    display flex
    margin 0 $md

.search-input
    flex-basis calc(100vw - 30px)
    flex-shrink 0
    width auto
    will-change flex, flex-basis

    &.cancelable
        flex-basis 'calc(100vw - 30px - %s)' % $width-cancel

    .app-is-animate &
        transition-timing-function linear
        transition-duration .3s
        transition-property flex

.search-cancel
    align-items center
    color #096dff
    display flex
    font-size 13px
    font-weight $fw-medium
    justify-content center
    margin-left $lg
    text-align center
    will-change opacity
</style>
