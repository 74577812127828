import { render, staticRenderFns } from "./EmptyLayout.vue?vue&type=template&id=376bfdb8&"
var script = {}
import style0 from "./EmptyLayout.vue?vue&type=style&index=0&id=376bfdb8&prod&lang=stylus&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports