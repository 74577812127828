<template lang="pug">
div(:class="$style.appInfo" @click="onClick") Application version: {{ mobileAppInfo.binaryVersion }}
    span(v-if="mobileAppInfo.updateVersion") &nbsp;/ update: {{ mobileAppInfo.updateVersion }}
    span &nbsp;/ deployment target: {{ deploymentTarget }}
    span &nbsp;/ version: {{ $options.branchName }}

</template>

<script>
import { mapGetters } from 'vuex';

import { MODAL_APPLICATION_DEPLOYMENT_MANAGER } from '@mobile/constant/modalName';

const SECRET_COUNTER_NUMBER = 9;

export default {
    name: 'AppVersionSection',

    inject: ['$modal'],

    computed: {
        ...mapGetters(['mobileAppInfo']),
        ...mapGetters('application', ['deploymentTarget']),
    },

    created() {
        this.counter = 0;
    },

    methods: {
        onClick() {
            if (this.counter >= SECRET_COUNTER_NUMBER) {
                this.counter = 0;
                this.$modal.open(MODAL_APPLICATION_DEPLOYMENT_MANAGER);
                return;
            }

            this.counter += 1;
        },
    },
    branchName: process.env.BRANCH_NAME,
};
</script>

<style lang="stylus" module>
.appInfo
    margin-top 10px
    font-size 9px
    line-height 15px
    color alpha(#5c72a3, .5)
</style>
