<template lang="pug">
div(
    v-if="notPwa"
    :class="$style.container"
)

    PWAInstall(
        v-for="type in $options.types"
        :key="type"
        :instruction="type === 'ios'"
        :mobile-app="$platform.mobile.android.any"
    )
        div(:class="$style.button")
            VIcon(
                :icon="type === 'android' ? 'android-2' : 'ios'"
                :size="23"
                :class="$style.icon"
            )

            div(:class="$style.text")
                div(:class="$style.title") {{ text.title }}

                div(:class="$style.subtitle") {{ text[type].subtitle }}

            VIcon(
                icon="chevron-right"
                :size="7"
                :class="$style.chevron"
            )
</template>

<script>
import { mapGetters } from 'vuex';

import PWAInstall from '@mobile/elements/PWAInstall';

export default {
    name: 'ApplicationSection',

    components: {
        PWAInstall,
    },

    types: ['ios', 'android'],

    computed: {
        ...mapGetters([
            'lang',
            'isPwaIgnored',
        ]),

        text() {
            return this.lang.Footer.applicationButtons;
        },

        notPwa() {
            return !process.env.IS_MOBILE_APP && !this.$platform.pwa.any && !this.isPwaIgnored;
        },
    },
};
</script>

<style lang="stylus" module>
.container
    display flex
    margin 0 -7.5px

.button
    width 175px
    height 42px
    display flex
    border 1px solid alpha(#fff, .15)
    border-radius 12px
    align-items center
    padding 0 15px
    transition border .25s ease-out
    cursor pointer
    margin 0 7.5px
    flex 1

    &:hover
        border 1px solid alpha(#fff, .3)

        .chevron
            opacity 1

.icon
    width 27px

.text
    margin-left 8px

.title
    font-size 8px
    line-height 10px
    color alpha(#fff, .5)

.subtitle
    font-weight 600
    font-size 10px
    line-height 12px
    margin-top 1px

.chevron
    margin-left auto
    opacity .3
    transition opacity .25s ease-out
</style>
