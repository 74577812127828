<template lang="pug">
div(:class="$style.container")
    div(
        v-if="!isReducedFunctionalityInvest"
        :class="$style.button"
        @click="toggle"
    )
        VIcon(
            :class="[$style.icon, opened && $style.open]"
            icon="chevron-down"
        )

    table
        tbody
            tr
                td
                    div(:class="$style.title") {{ text.titleInformation }}

            tr
                td(
                    v-for="columnNum in numColumns(informationList)"
                    :key="columnNum"
                    :class="$style.linkColumn"
                )
                    div(
                        v-for="item in numRowsInColumn(informationList, columnNum)"
                        :key="item.id"
                        :class="$style.linkWrapper"
                    )
                        a(
                            v-if="item.target"
                            :href="item.href"
                            :target="item.target"
                        ) {{ item.text }}
                        RouterLink(
                            v-else
                            :to="item.href"
                        ) {{ item.text }}

            tr(v-if="!isReducedFunctionalityInvest")
                td
                    VCollapse(:opened="opened")
                        div(:class="$style.title") {{ text.titleCategories }}

            tr(v-if="!isReducedFunctionalityInvest")
                td(
                    v-for="columnNum in numColumns(categoriesList)"
                    :key="`categories${columnNum}`"
                    :class="$style.linkColumn"
                )
                    VCollapse(:opened="opened")
                        div(
                            v-for="item in numRowsInColumn(categoriesList, columnNum)"
                            :class="$style.linkWrapper"
                            :key="item.id"
                        )
                            RouterLink(
                                :class="$style.item"
                                :to="item.to"
                            ) {{ item.name }}
</template>

<script>
import { mapGetters } from 'vuex';

import { SERVICE_LIVE, SERVICE_PREMATCH } from '@/constant/constants';

import AppConfig from '@config/app.config';
import ROUTE_NAME from '@mobile/constant/routeName';
import VCollapse from '@mobile/elements/VCollapse.vue';

export default {
    name: 'NavigationSection',

    components: {
        VCollapse,
    },

    props: {
        investRules: {
            type: Boolean,
            default: false,
        },
        rulesFooter: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        opened: false,
    }),

    computed: {
        ...mapGetters([
            'currentLang',
            'isDesktopApp',
            'isPwaIgnored',
            'lang',
            'isReducedFunctionalityInvest',
            'isReducedFunctionalityBetsOnly',
        ]),
        ...mapGetters('invest', ['isAllowedInvest']),

        text() {
            return this.lang.Footer;
        },

        textNavigation() {
            return this.lang.navigation;
        },

        informationList() {
            return [
                {
                    text: this.text.links.rules,
                    href: this.investRules ? this.$router.resolve({ name: 'rules', hash: '#70' }).href : this.$router.resolve({ name: 'rules' }).href,
                },
                {
                    text: this.lang.rules.bonuses,
                    href: this.$router.resolve({ name: ROUTE_NAME.BONUSES }).href,
                },
                ...(this.currentLang === 'ru' && this.isAllowedInvest) ? [{
                    text: '1w Invest',
                    href: this.$router.resolve({ name: 'invest' }).href,
                }] : [],
                {
                    text: this.text.links.affiliate,
                    href: AppConfig.partnerUrl,
                    target: '_blank',
                },
                ...this.currentLang === 'en' ? [{
                    text: 'Risk Disclosure',
                    href: this.$router.resolve({ name: 'rules', hash: '#28' }).href,
                }] : [],
            ];
        },

        categoriesList() {
            const categoriesList = [
                {
                    id: 'live',
                    name: this.textNavigation.live,
                    to: { name: ROUTE_NAME.BETS, params: { service: SERVICE_LIVE } },
                    isShown: true,
                },

                {
                    id: 'line',
                    name: this.textNavigation.prematch,
                    to: { name: ROUTE_NAME.BETS, params: { service: SERVICE_PREMATCH } },
                    isShown: true,
                },

                {
                    id: 'promotions',
                    name: this.textNavigation.promotions,
                    to: { name: ROUTE_NAME.BONUSES },
                    isShown: true,
                },

                {
                    id: 'live_games',
                    name: this.textNavigation['casino-live'],
                    to: { name: ROUTE_NAME.CASINO_LIVE },
                    isShown: !this.isReducedFunctionalityBetsOnly,
                },

                {
                    id: 'poker',
                    name: this.textNavigation.poker,
                    to: { name: ROUTE_NAME.POKER },
                    isShown: !this.isReducedFunctionalityBetsOnly,
                },

                {
                    id: 'casino',
                    name: this.textNavigation.casino,
                    to: { name: ROUTE_NAME.CASINO },
                    isShown: !this.isReducedFunctionalityBetsOnly,
                },
            ];

            return categoriesList.filter((v) => v.isShown);
        },
    },

    methods: {
        numRows(list) {
            return Math.ceil(list.length / 2);
        },

        numColumns(list) {
            return Math.ceil(list.length / this.numRows(list));
        },

        numRowsInColumn(list, el) {
            return list.slice((el - 1) * this.numRows(list), (el - 1) * this.numRows(list) + this.numRows(list));
        },

        toggle() {
            this.opened = !this.opened;
        },
    },
};
</script>

<style lang="stylus" module>
.container
    display flex
    width 100%
    position relative
    margin-bottom -10px

.button
    position absolute
    bottom 10px
    right 0
    background alpha(#5c72a3, .3)
    backdrop-filter blur(13.5914px)
    border-radius 6px
    width 24px
    height 24px
    display flex
    align-items center
    justify-content center

.icon
    color alpha($dark-10, .5)
    transition transform .3s ease
    width 8px

.open
    transform rotate(180deg)

.linkWrapper
    padding-bottom 10px
    font-weight 600
    font-size 10px
    line-height 13px

.title
    font-weight 600
    font-size 10px
    line-height 13px
    font-variant all-small-caps
    color alpha(#5c72a3, .5)
    padding-bottom 10px

.linkColumn
    vertical-align top

    &:last-child
        padding-left 22px
</style>
