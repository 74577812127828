<template lang="pug">
nav.Tabs
    ul.Tabs__list(
        :style=`{
            '--tabs__count-tabs': tabList.length,
            '--tabs__active-tab': activeTabIndex + 1,
            '--tabs__opacity-indicator': opacityIndicator
        }`
    )
        li.Tabs__list-item(
            v-for="tab in tabList"
            :key="tab.name"
            :class="tabClassList(tab)"
        )
            RouterLink.Tabs__link(
                :to="tab.currentRoute || tab.route"
                @click.native="changeTab(tab)"
            )
                .Tabs__link-content
                    VIcon.Tabs__link-icon(
                        :icon="tab.icon"
                        square
                    )
                    span.Tabs__link-label {{ tab.label }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const COUPON_TAB_NAME = 'coupons';

export default {
    inject: ['$modal'],

    computed: {
        ...mapGetters(['lang', 'user', 'showBalance']),
        ...mapGetters('coupon', ['activeCouponsList']),
        ...mapGetters('navigationMenu', [
            'activeClass',
            'bottom',
        ]),

        text() {
            return this.lang.tabs;
        },

        tabList() {
            return this.bottom.map((tab) => ({
                ...tab,
                isCouponTab: this.isCouponTab(tab),
                label: this.isCouponTab(tab) ? this.activeCouponsList.length : this.text[tab.name],
            }));
        },

        hasCoupons() {
            return this.activeCouponsList.length > 0;
        },

        currentTab() {
            return this.tabList.find((it) => it.routeActive.includes(this.$route.name));
        },

        activeTabIndex() {
            return this.tabList.findIndex((it) => it === this.currentTab);
        },

        opacityIndicator() {
            return this.isCouponTab(this.currentTab) && this.hasCoupons ? 0 : 1;
        },
    },

    methods: {
        ...mapActions('navigationMenu', ['tabBeforeClick']),

        isCouponTab(tab) {
            return tab?.name === COUPON_TAB_NAME;
        },

        tabClassList(tab) {
            return {
                'Tabs__list-item--coupon': tab.isCouponTab,
                'Tabs__list-item--has-coupons': tab.isCouponTab && this.hasCoupons,
                'Tabs__list-item--active': tab === this.currentTab,
            };
        },

        changeTab(tab) {
            this.$modal.close();
            this.tabBeforeClick({
                tab,
                currentRoute: this.$route,
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
.Tabs
    background-color $dark-900
    border-top 1px solid #1f2841
    contain layout style size
    height $layout-default-tabs-height
    position relative
    user-select none
    width 100%

.Tabs__list
    display flex
    height 100%
    position relative

    &::before
        background-color $primary-800
        border-radius 3px
        box-shadow 0 0 1px $dark-900
        content ''
        height 6px
        position absolute
        top @height * -.5
        transition transform .4s ease-out, opacity .4s ease-out
        width 45px
        transform translateX(-9999px) // don`t remove it, it`s default value
        $tabs = unquote('var(--tabs__count-tabs) * var(--tabs__active-tab)')
        transform 'translate(calc(100vw / %s - 100vw / var(--tabs__count-tabs) / 2 - 22.5px))' % ($tabs)
        opacity var(--tabs__opacity-indicator)

.Tabs__list-item
    flex 1

    &.Tabs__list-item--active
        .Tabs__link-label
            color #fff

    &.Tabs__list-item--coupon
        border-radius $radius-circle
        position relative

        &.Tabs__list-item--active
            .Tabs__link-content
                background-color #fff
                box-shadow 0 1px 10px 0 blend(alpha(#fff, .5), $dark-900)

                &::before
                    background-color #84878f

            .Tabs__link-label
            .Tabs__link-icon
                color #0854c3

        .Tabs__link
            align-items center
            display flex
            justify-content center
            position relative

        .Tabs__link-content
            width 43px
            height 43px
            background-color $primary-800
            border-radius $radius-circle
            box-shadow 0 1px 10px 0 blend(alpha(#0854c3, .7), $dark-900)
            display flex
            flex-flow revert
            justify-content center
            align-items center
            flex-direction row

            &::before
                border-radius $radius-circle
                content ''
                height 100%
                position absolute
                transition all .3s
                transform translateY(-50%) scale(1)
                width 100%
                z-index -1
                top: 50%

        .Tabs__link-label
            display none

    &.Tabs__list-item--has-coupons
        .Tabs__link-content
            box-shadow 0 1px 4px 0 blend(alpha(#000, .2), blend(alpha(#0854c3, .5), $dark-900))

            &::before
                background-color blend(alpha(#0854c3, .5), $dark-900)
                transform translateY(-50%) scale(1.35)

        .Tabs__link-label
            display block
            color #fff
            font-size 14px
            font-weight 600
            margin-left 2px

        .Tabs__link-icon
            font-size 18px
            margin 0

.Tabs__link
    display block
    position relative
    height 100%

.Tabs__link-content
    width 100%
    display flex
    align-items center
    flex-direction column
    justify-content space-between
    padding 11px 0 8px
    position relative

.Tabs__link-icon
    +repeat-selector(2)
        display block
        font-size 22px
        margin-bottom 5px
        max-height 22px
        max-width 22px

.Tabs__link-label
    color #536387
    font-size 11px
    text-align center
</style>
