<template lang="pug">
#app.app-is-animate(v-if="siteShowed")
    SvgGradientsDictionary
    Forbidden(v-if="blockWholeSite" key="forbidden")

    .main-view(
        v-else
        key="content"
    )
        TopNotification
        transition(name="main-background")
            img.main-view-background(
                v-if="background"
                :src="background.src"
                :srcset="background.srcSet"
            )

        router-view(name="layout")
            router-view(
                v-for="(_, name) in components"
                :key="name"
                :slot="name"
                :name="name"
            )

        PushNotification(:hasTabs="components && components.tabs && showedBottomNavigation")

        Burger(v-if="isBurgerMenuAllowed")
            BurgerMenu

    ModalLayout
    NotifyLayout
    PortalTarget(name="portal")
</template>

<script>
import { mapGetters } from 'vuex';

import Forbidden from '@/components/Forbidden.vue';
import ModalLayout from '@/components/ModalLayout.vue';
import NotifyLayout from '@/components/NotifyLayout.vue';
import SvgGradientsDictionary from '@/components/SvgGradientsDictionary.vue';
import TopNotification from '@/components/TopNotification.vue';
import { closeLoader, openLoaderOnOAuthPage } from '@/utils/globalLoader';

import Burger from '@mobile/components/Burger/Burger.vue';
import BurgerMenu from '@mobile/components/Burger/BurgerMenu.vue';
import PushNotification from '@mobile/components/PushNotification.vue';
import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    components: {
        ModalLayout,
        NotifyLayout,
        PushNotification,
        Forbidden,
        SvgGradientsDictionary,
        TopNotification,
        Burger,
        BurgerMenu,
    },

    data: () => ({
        notifyId: null,
    }),

    computed: {
        ...mapGetters([
            'blockWholeSite',
            'siteShowed',
            'isPwaIgnored',
            'isNotReducedFunctionality',
            'isBurgerMenuAllowed',
        ]),

        ...mapGetters('layout', [
            'showedBottomNavigation',
        ]),

        background() {
            return this.$route.meta.background;
        },

        components() {
            const matched = this.$route.matched[0];
            return matched && matched.components;
        },
    },

    created() {
        if (window !== window.top) {
            window.top.postMessage({ redirect: true }, '*');
        }
    },

    mounted() {
        if (!this.blockWholeSite || !openLoaderOnOAuthPage(this.$route, ROUTE_NAME.OAUTH)) {
            closeLoader();
        }

        let platform = null;

        if (process.env.IS_MOBILE_APP) {
            platform = 'apk';
        } else if (this.$platform.pwa.any) {
            platform = 'pwa';
        } else {
            platform = 'web';
        }

        this.$googleTagManager.setUserProperties({
            device_type: 'mobile',
            platform,
            os: this.$platform.os,
        });
    },
};
</script>

<style lang="stylus">
@require '~@/styles/index.styl';
@require '~@mobile/styles/index.styl';

html
    height calc(100 * var(--vh))
    background-color $dark-900
    position relative
    top 0 // support for old
    top var(--safe-area-inset-top, 0)

body
    height 100%
    overflow-x hidden
    overflow-y auto
    width 100%
    will-change scroll-position

#app
.main-view
    height 1px /* Required to make the child % of the min-height */
    min-height 100%
    width 100%

.main-view-background
    height 100vh
    object-fit cover
    object-position center calc(var(--layout-header-height) + env(safe-area-inset-top, 0))

    position fixed
    width 100%
</style>
