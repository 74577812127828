<template lang="pug">
button.burger-menu-button(
    v-on="$listeners"
    v-bind="$attrs"
)
    VIcon.burger-menu-button__icon(icon="burger_icon")
    // Оставляем в заготовках значок для уведомлений
    //.burger-menu-button__circle(v-if="hasNotifications")
</template>

<script>
export default {
    name: 'BurgerMenuButton',
    // data: () => ({
    // Оставляем в заготовках значок для уведомлений
    // hasNotifications: false,
    // }),
};
</script>

<style scoped lang="stylus">
.burger-menu-button
    touchable-area-extension()
    position relative
    display block

.burger-menu-button__icon
    font-size 19px

.burger-menu-button__circle
    position absolute
    top -7px
    right -7px
    display block
    width 15px
    height 15px
    border-radius 50%
    background linear-gradient(144deg, #0095ff -59%, #0855c4 96%)
    border 3px solid #090f1e
</style>
