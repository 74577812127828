<template lang="pug">
.burger-menu(
    :class="{ 'burger-menu__edit-mode': listEditMode }"
)
    .burger-menu__header
        .menu__user(
            v-if="isAuthed"
            @click="userProfileClickHandler"
        )
            UserAvatar.menu__user-avatar(:size="45")
            .menu__user-info
                .menu__user-name {{ user.name }}
                .menu__user-id(:class="{ 'menu__user-id--with-vip-domain': isVipUser }")
                    VIcon.menu__vip-domain-icon(
                        v-if="isVipUser"
                        icon="burger-vip-domain"
                    )
                    | ID {{ user.id }}
            VIcon.menu__user-chevron(icon="chevron-right-new")

        .menu__not-authed(v-else)
            VButton.menu__login-button(
                theme="default"
                @click="openLoginModal"
            )
                VIcon(icon="user")
            VButton.menu__registration.menu__control(
                size="sm"
                theme="success"
                @click="openRegistrationModal"
            )
                .control-item__inner
                    span.control-item__title {{ langCommon.registration.registrationButtonText }}
                    small.control-item__subtitle(v-if="isRegisterStarted")
                        | {{ langCommon.registration.registrationButtonTextComplete }}

    .burger-menu__content(
        ref="burgerMenuScrolledBlockRef"
    )
        BonusFreespinsHelper
            template(#default="{ hidePromo, showPromo }")
                BonusFreespins.bonus-freespins(
                    v-if="showPromo"
                    :mode="$options.Modes.MOBILE"
                    @hide="hidePromo"
                    @redirect="onRedirectedPromo"
                )

        .cashback-timer-container(@click="onCashbackTimerClick")
            CashbackTimerTight.timer(v-if="isAuthed")

        BurgerMenuItemsList.menu__items-list(
            ref="menuItemsList"
            @update:editMode="onUpdateItemsListEditMode"
        )

        .menu__cards
            PWAInstall(
                v-if="showPwaCard"
                :close-modal="false"
                :mobile-app="$platform.mobile.android.any"
            )
                BurgerMenuCard.menu__card-pwa(
                    :backgroundGradient="$options.backgroundGradientPwa"
                    :bump-colors="['#ffffff', '#ff00dd']"
                    :title="text.cardPwaTitle"
                    @click="$burgerMenu.close"
                )
                    PwaInfoDescription(
                        slot="description"
                        :rawMessage="text.cardPwaDescription"
                    )
                        template(#default="{ message, isLoading }")
                            .menu__card-pwa-description
                                VLoader.menu__card-pwa-description-loader(v-if="isLoading")
                                div(
                                    v-else
                                    v-html="message"
                                )
                    img.menu__card-pwa-image(:src="burgerPwaImage")

            SupportChatTrigger(chatIcon="comment-circle")
                template(#default="{ icon, open, spin }")
                    BurgerMenuCard.menu__card-support(
                        :backgroundGradient="$options.backgroundGradientSupport"
                        :bump-colors="['#0095ff', '#0855c4']"
                        :title="text.cardSupportTitle"
                        :description="text.cardSupportDescription"
                        @click="onSupportClick(open)"
                    )
                        .menu__card-support-buttons
                            VIconSvgGradient.menu__card-support-button(
                                :icon="icon"
                                :spin="spin"
                                gradient="blue-gradient"
                            )
                            SupportCall(
                                v-if="supportNumber"
                                @click.stop="$burgerMenu.close"
                            )
                                template(#default="{ call }")
                                    VIconSvgGradient.menu__card-support-button(
                                        icon="phone-circle"
                                        gradient="blue-gradient"
                                        @click="call"
                                    )
        .menu__footer
            .menu__socials
                BurgerMenuSocialList

            .menu__lang
                BurgerMenuLangSelect
</template>

<script>
import { mapGetters } from 'vuex';

import SoccerBallIcon from '@/assets/images/emoji/soccer-ball.png';
import { Modes } from '@/components/BaseBonusFreespins.vue';
import BonusFreespins from '@/components/BonusFreespins.vue';
import BonusFreespinsHelper from '@/components/BonusFreespinsHelper.vue';
import VIconSvgGradient from '@/components/Icons/VIconSvgGradient.vue';
import PwaInfoDescription from '@/components/PwaInfoDescription.vue';
import Spinner from '@/components/Spinner.vue';
import SupportCall from '@/components/SupportCall.vue';
import SupportChatTrigger from '@/components/SupportChatTrigger.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import { AppStorage, REGISTER_FORM } from '@/plugins/AppStorage';
import { getFirstScrolledParentDOMElement } from '@/utils/dom';

import burgerBonusImage from '@mobile/assets/images/burgermenu/burger_bonus_card.png';
import burgerPwaImage from '@mobile/assets/images/burgermenu/burger_pwa_card.png';
import BurgerMenuCard from '@mobile/components/Burger/BurgerMenuComponents/BurgerMenuCard.vue';
import BurgerMenuItemsList from '@mobile/components/Burger/BurgerMenuComponents/BurgerMenuItemsList.vue';
import BurgerMenuLangSelect from '@mobile/components/Burger/BurgerMenuComponents/BurgerMenuLangSelect.vue';
import BurgerMenuSocialList from '@mobile/components/Burger/BurgerMenuComponents/BurgerMenuSocialList.vue';
import CashbackTimerTight from '@mobile/components/Timers/CashbackTimerTight.vue';
import { MODAL_LOGIN, MODAL_REGISTRATION, MODAL_CASINO_CASHBACK } from '@mobile/constant/modalName';
import ROUTE_NAME from '@mobile/constant/routeName';
import PWAInstall from '@mobile/elements/PWAInstall';

export default {
    name: 'BurgerMenu',

    components: {
        SupportChatTrigger,
        Spinner,
        BonusFreespins,
        BonusFreespinsHelper,
        PWAInstall,
        CashbackTimerTight,
        PwaInfoDescription,
        BurgerMenuItemsList,
        BurgerMenuSocialList,
        BurgerMenuLangSelect,
        SupportCall,
        BurgerMenuCard,
        UserAvatar,
        VIconSvgGradient,
    },

    inject: ['$modal'],

    data() {
        return {
            ROUTE_NAME,
            burgerPwaImage,
            burgerBonusImage,
            SoccerBallIcon,
            isIntersecting: false,
            listEditMode: false,
        };
    },

    computed: {
        ...mapGetters([
            'user',
            'lang',
            'isAuthed',
            'currency',
            'isVipUser',
            'isPwaIgnored',
            'langCommon',
            'isDesktopApp',
            'currentLang',
            'supportNumber',
        ]),

        isRegisterStarted() {
            return AppStorage.reactiveStorageObject[REGISTER_FORM];
        },

        text() {
            return this.lang.burgerMenu;
        },

        showPwaCard() {
            return !(process.env.IS_MOBILE_APP || this.isPwa || this.isPwaIgnored || this.isDesktopApp);
        },

        isPwa() {
            return this.$platform.pwa.any;
        },
    },

    mounted() {
        this.initAllowedBurgerScrollActionsMonitor();
    },

    methods: {
        initAllowedBurgerScrollActionsMonitor() {
            this.$refs.burgerMenuScrolledBlockRef.addEventListener('touchstart', (event) => {
                const { clientY } = event.changedTouches ? event.changedTouches[0] : event;
                this.lastTouchY = clientY;
            });
            this.$refs.burgerMenuScrolledBlockRef.addEventListener('touchmove', (event) => {
                const firstParentScrollableElement = getFirstScrolledParentDOMElement(event.target);
                if (firstParentScrollableElement !== this.$refs.burgerMenuScrolledBlockRef) {
                    return;
                }

                const { clientHeight, scrollHeight, scrollTop } = firstParentScrollableElement;
                const { clientY } = event.changedTouches?.[0] ?? event;

                const isDown = clientY - this.lastTouchY > 0;

                if ((isDown && scrollTop === 0) || (!isDown && scrollTop + clientHeight >= scrollHeight)) {
                    return;
                }

                event.burgerAllowedTouchmoveAction = true;
            });
        },

        openRegistrationModal() {
            this.$burgerMenu.close();
            this.$modal.open(MODAL_REGISTRATION);
        },

        openLoginModal() {
            this.$burgerMenu.close();
            this.$modal.open(MODAL_LOGIN);
        },

        onUpdateItemsListEditMode(value) {
            this.listEditMode = value;
        },

        onCashbackTimerClick() {
            this.$burgerMenu.close();
            if (this.$router.currentRoute.name !== ROUTE_NAME.BONUSES) {
                this.$router.push({ name: ROUTE_NAME.BONUSES });
            }
            this.$modal.open(MODAL_CASINO_CASHBACK);
        },

        userProfileClickHandler() {
            this.$burgerMenu.close();
            this.$router.push({ name: ROUTE_NAME.PROFILE });
        },

        onRedirectedPromo() {
            this.$burgerMenu.close();
        },

        async onSupportClick(openChat) {
            await openChat();
            this.$burgerMenu.close();
        },
    },

    Modes,
    backgroundGradientPwa: 'linear-gradient(107deg, rgba(253, 26, 198, 1) -60%, rgba(21, 29, 49, 0) 35%)',
    backgroundGradientSupport: 'linear-gradient(105deg, rgba(26, 150, 253, 1) -60%, rgba(21, 29, 49, 0) 35%)',
};
</script>

<style scoped lang="stylus">
$tsDuration = .3s

.burger-menu
    height 100%
    display flex
    flex-direction column

    &.burger-menu__edit-mode
        .burger-menu__header,
        .menu__footer,
        .menu__cards, :deep(.cashback-timer)
            opacity: 0.3

    .burger-menu__header
        flex 0 0 0
        padding 30px 30px 0
        transition opacity $tsDuration
        will-change opacity

    .burger-menu__content
        flex auto
        overflow-y auto
        padding 0 30px 0
        margin-top 15px
        overflow-scrolling touch
        -webkit-overflow-scrolling touch

        &:after
            content: '';
            height: 30px;
            display: block;

.menu__user
    display flex
    align-items center
    padding-top 5px
    max-width 190px

.menu__user-info
    margin-left 10px

.menu__user-name
    font-size 20px
    font-weight bold
    font-family $ff-heading
    max-width 105px
    ellipsis()

.menu__user-id
    ellipsis()
    max-width 105px
    line-height 25px
    font-size 11px
    color #94a6cd

    &.menu__user-id--with-vip-domain
        padding-left 38px
        position relative

.menu__vip-domain-icon
    position absolute
    left -5px
    height 18px
    top calc(50% - 10px)

.menu__user-chevron
    margin-left 7px
    color alpha(#fff, .2)
    font-size 11px

.menu__items-list
    margin-bottom 35px

.menu__cards
    margin-bottom 15px
    transition opacity $tsDuration
    will-change opacity

    & :deep(.menu__card:not(:last-child))
        margin-bottom 10px

.menu__card-pwa-image
    width 65px

.menu__card-pwa
    margin-top 20px

    :deep()
        .menu__card-left
            padding-right 65px

        .menu__card-aside
            position absolute
            right 15px
            top -5px

.menu__card-pwa-description > span
    font-weight 600

.menu__card-bonus
    cursor pointer

    :deep()
        .menu__card-left
            padding-right 75px

        .menu__card-aside
            position absolute
            right 25px
            top -5px

.menu__card-bonus-image
    width 65px

.menu__card-bets-button
    background rgba(151, 174, 225, 0.11)
    color #94a6cd

.menu__card-support-buttons
    display flex

    > *:not(:last-child)
        margin-right 7px

    .menu__card-support-button
        height 35px
        width 35px
        margin 0

.menu__footer
    display flex
    justify-content space-between
    align-items center
    transition opacity $tsDuration
    will-change opacity

.menu__not-authed
    display flex
    align-items center
    padding-right 35px

    & > *:not(:last-child)
        margin-right 10px

.menu__registration
    border-radius 10px
    overflow hidden
    position relative
    transform translateZ(0)

    &::before
        background-color alpha(white, .2)
        bottom 0
        content ''
        position absolute
        top 0
        width 30px
        will-change transform
        z-index 1
        animation flare 5s infinite ease

    :deep(.control-item__inner)
        white-space nowrap

        .control-item__title
            line-height 15px

.control-item__inner
    display inline-flex
    flex-flow column nowrap
    align-items center
    justify-content center
    padding 0 5.5px

.menu__login-button
    width 30px
    height 30px
    min-width 30px
    background-color #4e5d86

@keyframes flare
    0%
        transform skewX(-30deg) translateX(-100px)
    20%
        transform skewX(-30deg) translateX(80px)
    100%
        transform skewX(-30deg) translateX(80px)

.menu__card-bets-title
    display flex
    align-items center

    .menu__card-bets-title-icon
        margin-left 5px
        width 13px

.menu__card-pwa-description
    :deep(b)
        font-weight bold

.menu__card-pwa-description-loader
    min-height auto
    padding 5px 0 0 0

    :deep(.icon-spinner)
        font-size 10px

.control-item__subtitle
    font-size 8px
    font-weight 400
    opacity .7

:deep(.cashback-timer)
    border-radius 10px
    margin-bottom 16px
    transition opacity $tsDuration

.timer
    margin-top 20px

.bonus-freespins
  border-radius 10px
</style>
