const listeners = new WeakMap();

/**
 * Директива для отлавливания события ручного фокуса на элементе.
 * Эта директива игнорирует вызов события фокуса при браузерном автокомплите. (Обычное события фокуса вызывается)
 * @param {Function} value - функция которая будет вызвана в случае успешного ручного фокуса
 * @param {number} arg - число задающее задержку таймаута до события 'blur' в миллисекундах
 */
export default {
    bind(el, { arg: focusDelay = 100 }, node) {
        let timeoutId;

        const onBlur = () => {
            clearTimeout(timeoutId);
        };

        const onFocus = (e) => {
            timeoutId = setTimeout(
                () => {
                    if (node.context != null) {
                        node.context.$emit('deferred-focus', e);
                    } else {
                        console.error("Somehow node.context is not defined meaning there's no parent component");
                    }
                },
                focusDelay,
            );
        };

        el.addEventListener('blur', onBlur);
        el.addEventListener('focus', onFocus);

        listeners.set(el, {
            onBlur,
            onFocus,
        });
    },

    unbind(el) {
        const { onBlur, onFocus } = listeners.get(el);
        el.removeEventListener('blur', onBlur);
        el.removeEventListener('focus', onFocus);
    },
};
