<template lang="pug">
.nav-controls()
    .filter(v-if="config.filter")
        FiltersControlResults(v-if="config.filter.type === 'results'")
        FiltersControlPrematch(v-if="config.filter.type === 'prematch'")

    .search(v-if="config.search")
        VIcon.icon(
            icon="search"
            @click="onSearchClick"
            margin
            square
        )
</template>

<script>

import ROUTE_NAME from '@mobile/constant/routeName';

import FiltersControlPrematch from './FiltersControlPrematch.vue';
import FiltersControlResults from './FiltersControlResults.vue';

export default {
    components: {
        FiltersControlResults,
        FiltersControlPrematch,
    },

    props: {
        // { search: { routeName: String }, filter: { type: 'results' || 'prematch' } }
        config: {
            type: Object,
            required: true,
        },
    },

    methods: {
        onSearchClick() {
            this.$router.push({
                name: ROUTE_NAME.SEARCH_RESULT,
                params: {
                    routeName: this.config.search.routeName,
                },
            });
        },
    },
};
</script>

<style lang="stylus" scoped>
.nav-controls
    display flex
    min-height 100%
    justify-content flex-end
    align-items center
    padding 0 $md 0 4px
    position:relative

    .icon
        width 15px

    & > div:not(:last-child):not(.shadow)
        margin-right $md
</style>
