<script>
import throttle from 'lodash.throttle';

const animate = (draw, duration, callbackStop = () => {}) => {
    const start = performance.now();
    let forceStop = false;
    // Функция анимации
    // eslint-disable-next-line no-restricted-properties
    const easeOut = (x, p) => 1 - Math.pow(1 - x, p);

    const run = (time) => {
        if (forceStop) {
            setTimeout(() => callbackStop(true));
            return;
        }

        // определить, сколько прошло времени с начала анимации
        let timePassed = time - start;

        // возможно небольшое превышение времени, в этом случае зафиксировать конец
        if (timePassed >= duration) timePassed = duration;

        const progress = timePassed / duration;
        const easing = Math.round(easeOut(progress, 10) * 10000) / 10000;
        // нарисовать состояние анимации в момент timePassed
        draw(easing);

        // если время анимации не закончилось - запланировать ещё кадр
        if (easing < 1) {
            requestAnimationFrame(run);
        } else {
            setTimeout(() => callbackStop());
        }
    };

    requestAnimationFrame(run);

    return () => { forceStop = true; };
};
const unify = (event) => (event.changedTouches ? event.changedTouches[0] : event);
const DURATION = 1500;
const MIN_SCROLL_NEXT = 80;
const MIN_START_OFFSET = 20;
const FAST_SWIPE_SPEED = 300;

export default {
    props: {
        centered: {
            type: Boolean,
            default: false,
        },
        alignRight: {
            type: Boolean,
            default: false,
        },
        padding: {
            type: Number,
            default: 15,
        },
        marginItem: {
            type: Number,
            default: 10,
        },
    },

    data: () => ({
        itemsToShowStart: [],
        itemsToShowEnd: 0,
        stopAnimation: null,
        addCountItems: 3,
        startKey: 0,
        vnodes: new Map(),
    }),

    computed: {
        leftOffset() {
            return this.itemsToShowStart.reduce((accum, cur) => accum + cur + this.marginItem, 0);
        },
    },

    created() {
        this.throttledUpdateItems = throttle(this.updateItems, 100);
    },

    mounted() {
        this.setListeners();
    },

    beforeDestroy() {
        this.removeListeners();
    },

    methods: {
        setListeners() {
            // !!! not use for render function, does not work on Safari
            this.$el.addEventListener('mousedown', this.down, false);
            this.$el.addEventListener('touchstart', this.down, false);
            this.$el.addEventListener('mouseout', this.up, false);
            this.$el.addEventListener('mouseup', this.up, false);
            this.$el.addEventListener('touchend', this.up, false);
            this.$el.addEventListener('mousemove', this.move, false);
            this.$el.addEventListener('touchmove', this.move, false);
        },
        removeListeners() {
            this.$el.removeEventListener('mousedown', this.down);
            this.$el.removeEventListener('touchstart', this.down);
            this.$el.removeEventListener('mouseout', this.up);
            this.$el.removeEventListener('mouseup', this.up);
            this.$el.removeEventListener('touchend', this.up);
            this.$el.removeEventListener('mousemove', this.move);
            this.$el.removeEventListener('touchmove', this.move);
        },
        updateItems() {
            const { rightSlots, leftSlots } = this.getSlots();

            // если возле правой границы нет слота то добавим
            if (this.countSlots > this.itemsToShowEnd && !rightSlots.length) {
                this.itemsToShowEnd += 1;
                setTimeout(() => {
                    this.throttledUpdateItems();
                });
                // если возле правой границы есть лишние слоты то удалем
            } else if (this.itemsToShowEnd > 0 && rightSlots.length >= 2) {
                this.itemsToShowEnd -= rightSlots.length - 1;
            }

            // если возле левой границы нет слота то добавим
            if (this.itemsToShowStart.length > 0 && !leftSlots.length) {
                this.itemsToShowStart.pop();
                setTimeout(() => {
                    this.throttledUpdateItems();
                });
                // если возле левой границы есть лишние слоты то удалем
            } else if (this.countSlots > this.itemsToShowStart.length && leftSlots.length >= 2) {
                leftSlots.slice(0, leftSlots.length - 1).forEach((el) => {
                    this.itemsToShowStart.push(el.clientWidth);
                });
            }
        },
        getSlots() {
            const {
                $el: { scrollLeft = 0, clientWidth = 0 } = {},
            } = this;
            const boundRight = scrollLeft + clientWidth * (2 + this.addCountItems);
            const boundLeft = scrollLeft - clientWidth * (1 + this.addCountItems);
            const scrollItems = this.getScrollItems();

            const rightSlots = scrollItems
                .filter((elm) => this.leftOffset + elm.offsetLeft + elm.clientWidth >= boundRight);
            const leftSlots = scrollItems
                .filter((elm) => this.leftOffset + elm.offsetLeft <= boundLeft);

            return { rightSlots, leftSlots };
        },
        getPosition(left, width) {
            return this.leftOffset + (
                // eslint-disable-next-line no-nested-ternary
                this.centered
                    ? left + width / 2
                    : this.alignRight
                        ? left + width
                        : left
            );
        },

        getScrollItems() {
            return Array.from(this.$el?.getElementsByClassName('horizontal-carousel-item') || []);
        },

        findLeftSlots(targetPosition) {
            return this.getScrollItems()
                .filter((elm) => this.getPosition(elm.offsetLeft, elm.clientWidth) <= targetPosition)
                .sort((a, b) => {
                    const aPosition = this.getPosition(a.offsetLeft, a.clientWidth);
                    const bPosition = this.getPosition(b.offsetLeft, b.clientWidth);

                    return bPosition - aPosition;
                });
        },

        findRightSlots(targetPosition) {
            return this.getScrollItems()
                .filter((elm) => this.getPosition(elm.offsetLeft, elm.clientWidth) >= targetPosition)
                .sort((a, b) => {
                    const aPosition = this.getPosition(a.offsetLeft, a.clientWidth);
                    const bPosition = this.getPosition(b.offsetLeft, b.clientWidth);

                    return aPosition - bPosition;
                });
        },

        startAnimation(event) {
            this.dx = this.dx || this.direction || 0;
            const isScrollRight = this.dx < 0;
            const offsetDx = Math.abs(this.dx);
            const { scrollLeft, clientWidth } = this.$el;
            // eslint-disable-next-line no-nested-ternary
            const marginLeft = this.centered
                ? clientWidth / 2
                : this.alignRight
                    ? clientWidth - this.padding
                    : this.padding;
            let offsetAnimation = 0;

            let swapTime = performance.now() - this.lastTouchStartTime;
            const coefSwapTime = offsetDx / 100;
            swapTime = swapTime >= FAST_SWIPE_SPEED * coefSwapTime
                ? FAST_SWIPE_SPEED * coefSwapTime
                : swapTime;

            // Получаем оффсет после которого стригериться переход на другой слайд
            const isFastSwipe = performance.now() - this.lastTouchStartTime < FAST_SWIPE_SPEED * coefSwapTime;
            const triggerOffset = isFastSwipe ? 0 : MIN_SCROLL_NEXT;

            if (!this.$el || this.swipe !== 'x' || !this.getScrollItems().length) return;

            const fastOffset = !isFastSwipe ? 0 : offsetDx * (1 - swapTime / (FAST_SWIPE_SPEED * coefSwapTime)) * 1.5;
            this.direction = isScrollRight ? 1 : -1;
            const targetPosition = scrollLeft + marginLeft + fastOffset * this.direction;
            const leftSlots = this.findLeftSlots(targetPosition);
            const rightSlots = this.findRightSlots(targetPosition);
            const [leftSlot] = leftSlots;
            const [rightSlot] = rightSlots;
            const slot = (isScrollRight && offsetDx > triggerOffset) || (!isScrollRight && offsetDx <= triggerOffset)
                ? rightSlot || leftSlot
                : leftSlot || rightSlot;
            const centerRes = this.getPosition(slot.offsetLeft, slot.clientWidth);
            offsetAnimation = centerRes - scrollLeft - marginLeft;

            const dur = (Math.abs(scrollLeft - (slot.offsetLeft + this.leftOffset)) / clientWidth) * DURATION;

            this.stopAnimation = animate((easing) => {
                const scrollToX = Math.floor(scrollLeft + offsetAnimation * easing);
                this.$el.scrollTo(scrollToX, 0);
            }, dur, () => {
                this.stopAnimation = null;
                this.throttledUpdateItems();
            });

            const { $slots: { default: defaultSlots = ([]) } = {} } = this;
            this.$emit('change', defaultSlots.findIndex(({ elm }) => (elm === slot)));

            if (event && event.cancelable) {
                event.preventDefault();
                event.stopPropagation();
            }
        },

        move(event) {
            if (!this.downed) return;
            const { clientX, clientY } = unify(event);
            this.dx = this.startPositionX - clientX;
            this.dy = this.startPositionY - clientY;

            if (!this.swipe && Math.abs(this.dx) > MIN_START_OFFSET) {
                this.swipe = 'x';
            }
            if (!this.swipe && Math.abs(this.dy) > MIN_START_OFFSET) {
                this.swipe = 'y';
            }

            if (this.swipe === 'x') {
                this.$el.scrollTo(Math.floor(this.prevScrollLeft + this.dx - this.moveDxStartOffset), 0);
                // Отрубаем скролл по вертикали
                if (event.cancelable) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            } else {
                this.moveDxStartOffset = this.dx;
            }
        },

        down(event) {
            this.downed = true;
            this.lastTouchStartTime = performance.now();
            this.moveDxStartOffset = 0;
            if (this.stopAnimation) {
                this.stopAnimation();
                this.stopAnimation = null;
            } else {
                this.swipe = null;
            }

            const { clientX, clientY } = unify(event);
            const { scrollLeft } = this.$el;

            this.prevScrollLeft = scrollLeft;
            this.startPositionX = clientX;
            this.startPositionY = clientY;
        },

        up(event) {
            if (!this.downed) return;
            this.downed = false;
            const { clientX } = unify(event);
            this.dx = clientX - this.startPositionX;
            this.startAnimation(event);
        },
    },

    render(h) {
        const { $slots: { default: defaultSlots = ([]) } = {} } = this;

        this.countSlots = defaultSlots.length;
        this.throttledUpdateItems();
        const translateX = `translateX(${this.leftOffset}px)`;

        return h(
            'div',
            {
                class: 'horizontal-carousel',
                on: this.$listeners,
            },
            [
                h('div', {
                    class: 'horizontal-carousel-item-empty',
                    style: {
                        width: `${this.padding}px`,
                    },
                    key: 'padding-left',
                }),
                ...defaultSlots.map((vnode, index) => {
                    const marginRight = `${index !== this.itemsToShowEnd - 1 ? this.marginItem : 0}px`;
                    const key = (vnode.key ?? ++this.startKey).toString(); // eslint-disable-line no-plusplus
                    vnode.key = key;
                    const vnodeCache = this.vnodes.get(key);
                    if (vnodeCache && vnodeCache.componentInstance) {
                        vnode.componentInstance = vnodeCache.componentInstance;
                    } else {
                        this.vnodes.set(key, vnode);
                    }
                    vnode.data = vnode.data ?? {};
                    vnode.data.on = vnode.componentOptions && vnode.componentOptions.listeners;
                    if (!Array.isArray(vnode.data.class) || !vnode.data.class.includes('horizontal-carousel-item')) {
                        vnode.data.class = [vnode.data.class, 'horizontal-carousel-item'];
                    }
                    vnode.data.style = {
                        '-webkit-transform': translateX,
                        transform: translateX,
                        marginRight,
                    };
                    if (vnode.elm && vnode.elm instanceof HTMLElement) {
                        vnode.elm.style.setProperty('-webkit-transform', translateX);
                        vnode.elm.style.setProperty('transform', translateX);
                        vnode.elm.style.setProperty('margin-right', marginRight);
                    }
                    return vnode;
                }).slice(this.itemsToShowStart.length, this.itemsToShowEnd),
                h('div', {
                    class: 'horizontal-carousel-item-empty',
                    style: {
                        '-webkit-transform': translateX,
                        transform: translateX,
                        width: `${this.padding}px`,
                    },
                    key: 'padding-right',
                }),
            ],
        );
    },
};
</script>

<style lang="stylus" scoped>
$scroll-padding = $md

.horizontal-carousel
    display flex
    flex-wrap nowrap
    overflow hidden
    width 100%
    user-select none
    -webkit-overflow-scrolling auto
    transform translateZ(0)

    &::-webkit-scrollbar
        display none

    .horizontal-carousel-item-empty
        flex none
        height 1px
        user-select none
        pointer-events none

    .horizontal-carousel-item
        flex none
        user-select none
</style>
