<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="$options.isActive"
    key="apgSeal"
    v-html="apgHtml"
  />
</template>

<script>
const oneWinPro = '1win.pro';
const isLegal = window.location.host === oneWinPro;
const isForceEnabled = false;

export default {
    name: 'ApgSeal',

    data: () => ({
        apgHtml: '',
    }),

    mounted() {
        if (!this.$options.isActive) {
            return;
        }

        this.$iframe = document.createElement('iframe');
        this.$iframe.style.display = 'none';
        this.$iframe.src = `https://${oneWinPro}/apg-seal.html`;
        document.body.appendChild(this.$iframe);

        window.addEventListener('message', this.setApg);
    },

    beforeDestroy() {
        window.removeEventListener('message', this.setApg);
        this.$iframe?.remove();
    },

    methods: {
        setApg({ data } = {}) {
            try {
                const body = JSON.parse(data);
                if (body.type === 'apgSeal') {
                    this.apgHtml = body.html;
                }
            } catch {
                // noop
            }
        },
    },

    isActive: isLegal || isForceEnabled,
};
</script>
