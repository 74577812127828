function getBurgerMenuVueInstance(Vue) {
    return new Vue({
        data: {
            isOpened: false,
        },

        methods: {
            open() {
                this.isOpened = true;
                this.applyBurgerBodyDOMStyles();
            },
            close() {
                this.isOpened = false;
                this.resetBurgerBodyDOMStyles();
            },

            applyBurgerBodyDOMStyles() {
                document.body.style.overflowY = 'hidden';
            },
            resetBurgerBodyDOMStyles() {
                document.body.style.overflowY = 'auto';
            },
        },
    });
}

const BurgerMenuPlugin = {
    install(Vue) {
        Vue.prototype.$burgerMenu = getBurgerMenuVueInstance(Vue);
    },
};

export default BurgerMenuPlugin;
