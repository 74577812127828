<template lang="pug">
.bets-header
    .bets-header-tabs
        EventsOrSportsTabs(
            :active="activeTab"
            @tabChanged="tab => setActiveTab(tab)"
        )
        VButton.collapse-all(
            theme="primary"
            :disabled="activeTab !== BETS_TAB_EVENTS"
            @click="toogleCollapseOpenned()"
        )
            VIcon(
                :class="{ 'collapse-icon-up': collapseOpenned }"
                icon="angle-down-slide"
                square
            )
</template>

<script>
import { SERVICE_LIVE, BETS_TAB_EVENTS, BETS_TAB_SPORTS } from '@/constant/constants';
import { getMobileBetsRoute } from '@/utils/betsRoute';

import EventsOrSportsTabs from '@mobile/components/Bets/EventsOrSportsTabs.vue';
import ROUTE_NAME from '@mobile/constant/routeName';

const availableTabs = [
    BETS_TAB_EVENTS,
    BETS_TAB_SPORTS,
];

export default {
    components: {
        EventsOrSportsTabs,
    },

    props: {
        service: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            BETS_TAB_EVENTS,
        };
    },
    computed: {
        prefixHeader() {
            return `bets/mobileHeader/${this.service}`;
        },
        activeTab() {
            return this.$store.state.bets.mobileHeader[this.service].activeTab;
        },
        collapseOpenned() {
            return this.$store.state.bets.mobileHeader[this.service].collapseOpenned;
        },
        pageIsBetsLiveTournament() {
            return this.$route.name === ROUTE_NAME.BETS_TOURNAMENT && this.$route.params.service === SERVICE_LIVE;
        },
        activeTabIsEvents() {
            return this.activeTab === BETS_TAB_EVENTS;
        },
    },
    watch: {
        activeTab() {
            if (this.pageIsBetsLiveTournament && this.activeTabIsEvents) {
                this.$router.push(getMobileBetsRoute({ service: SERVICE_LIVE }));
            }
        },
    },

    created() {
        if (availableTabs.includes(this.$route.query?.tab)) {
            this.setActiveTab(this.$route.query.tab);
        }
    },

    methods: {
        toogleCollapseOpenned(value) {
            this.$store.commit(`${this.prefixHeader}/toogleCollapseOpenned`, value);
        },
        setActiveTab(value) {
            this.$store.commit(`${this.prefixHeader}/setActiveTab`, value);
        },
    },
};
</script>

<style scoped lang="stylus">
.bets-header-tabs
    display flex
    padding 0 $md
    margin-bottom $xs

.collapse-all
    display flex
    justify-content center
    font-size 16px
    width 40px
    height 40px
    margin-left $sm
    padding 0

.collapse-icon-up
    transform rotate(180deg)

.icon-angle-down-slide
    display block

.control-row
    align-items center
    display flex
    margin $md 0
</style>
