import { NAVIGATION_ITEM_SORTING_KEY, NAVIGATION_ITEMS_SORTING_VALUES } from '@/utils/navigation';

import ROUTE_NAME from '@mobile/constant/routeName';

const BURGER_DEVIDERS = [{
    name: ROUTE_NAME.PSEUDO_ROUTE_BURGER_DIVIDER_1,
    IS_DIVIDER: true,
    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.DIVIDER_1,
},
{
    name: ROUTE_NAME.PSEUDO_ROUTE_BURGER_DIVIDER_2,
    IS_DIVIDER: true,
    [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.DIVIDER_2,
}];

export const BURGER_NAVIGATION_ROUTE_NAMES = [
    ROUTE_NAME.BETS_LIVE,
    ROUTE_NAME.BETS_PREMATCH,
    ROUTE_NAME.CASINO,
    ROUTE_NAME.CASINO_GAMES_ONE_WIN,
    ROUTE_NAME.CASINO_LIVE,
    ROUTE_NAME.BETS_CYBERSPORT,
    ROUTE_NAME.CASINO_GAME_FANTASY_SPORT,
    ROUTE_NAME.INVEST,
    ROUTE_NAME.CASINO_GAMES,
    ROUTE_NAME.TVBET,
    ROUTE_NAME.BETGAMES,
    ROUTE_NAME.VIRTUAL_SPORT,
    ROUTE_NAME.POKER,
    ROUTE_NAME.LEADERBOARD,
    ROUTE_NAME.TV,
    ROUTE_NAME.CASINO_GAME_AVIATOR,
    ROUTE_NAME.BONUSES,
];

export const injectBurgerDividers = (menuItems) => {
    const step = Math.round(menuItems.length / (BURGER_DEVIDERS.length + 1));
    BURGER_DEVIDERS.forEach((divider, index) => {
        menuItems.splice(index + step * (index + 1), 0, divider);
    });
};
