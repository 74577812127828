<template lang="pug">
.custom-message(:class="{ mobile: $store.getters.isMobile }")
    .custom-message-container
        span.custom-message-text(
            v-html="customMessage.text"
        )
        button.custom-message-action(
            v-if="customMessage.actionText"
            @click="$emit('customMessageAction', customMessage.actionName)"
            type="button"
        ) {{ customMessage.actionText }}
    VIcon.custom-message-close(
        v-if="customMessage.closeable"
        :icon="closeIcon"
        @click.prevent="$emit('customMessageClose')"
    )
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';
import { V_INPUT_CUSTOM_MESSAGE_PROP_VALIDATOR } from '@/constant/constants';

export default {
    components: {
        VIcon,
    },

    props: {
        customMessage: {
            type: Object,
            validator: V_INPUT_CUSTOM_MESSAGE_PROP_VALIDATOR,
            required: true,
        },
    },
    data() {
        return {
            closeIcon: this.$store.getters.isMobile ? 'close' : 'times',
        };
    },
};
</script>

<style scoped lang="stylus">
.custom-message
    position absolute
    top 0
    left 0
    display flex
    align-items center
    padding 10px
    height 100%
    width 100%
    background-color #edf0f7
    border-radius $radius
    color alpha(#070c19, .8)
    z-index 1

    &.mobile
        padding-left 0
        padding-right 0
        .custom-message-close
            font-size 10px

.custom-message-container
    font-size 11px
    flex-grow 1

.custom-message-text
    margin-right 3px
    color #070c19

.custom-message-action
    font-size inherit
    color #02a04c
    font-weight 500

.custom-message-close
    margin-left 5px
    cursor pointer
    color rgba(#090f1e, .15)
    font-size 15px
</style>
