<template>
  <div :class="[$style.license, investRules && $style.invest]">
    <div :class="$style.topContainer">
      <div :class="$style.leftContainer">
        <ApgSeal :class="$style.apg" />

        <Listings :class="$style.listings">
          <template #bookmakerIcon>
            <div :class="$style.bookmakerIconWrapper">
              <VIcon
                :icon="bookmakerIcon"
                :size="22"
              />
            </div>
          </template>
          <template #ageLimit>
            <div :class="$style.ageLimit">
              18+
            </div>
          </template>
        </Listings>
      </div>

      <div :class="$style.rightContainer">
        <div
          v-if="showModeSwitch"
          :class="[$style.button, $style.modeSwitcher]"
          @click="switchAppMode"
        >
          <VIcon
            icon="app/laptop"
            :size="16"
          />
        </div>

        <label
          v-if="isShowChangeLanguage"
          :class="$style.langSelect"
        >
          <div :class="$style.currentLangLabel">{{ $filters.capitalize(selectedLangVisualKey) }}</div>
          <CountryIcon
            rounded
            :class="$style.langIcon"
            :code="currentLang"
          />
          <VSelectNative
            :items="$options.langOptionList"
            :value="currentLang"
            :class="$style.langInput"
            @input="switchLang"
          />
        </label>

        <div
          :class="[$style.button, $style.upWindow]"
          @click="scrollToTop"
        >
          <VIcon
            icon="up-window"
            :size="14"
          />
        </div>
      </div>
    </div>

    <div :class="$style.bottomContainer">
      <div :class="$style.text">
        <span>© {{ $options.currentYear }} 1WIN.&nbsp;</span>
        <span v-if="licenseText">{{ licenseText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ApgSeal from '@/components/apgSeal.vue';
import CountryIcon from '@/components/CountryIcon.vue';
import Listings from '@/components/Footer/Listings.vue';
import { APP_MODE_DESKTOP, LangItemsEnum } from '@/constant/constants';
import { APP_MODE, AppStorage } from '@/plugins/AppStorage';

export default {
    components: {
        ApgSeal,
        CountryIcon,
        Listings,
    },

    inject: ['$filters'],

    props: {
        investRules: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters([
            'currentLang',
            'isPwaIgnored',
            'lang',
            'licenseText',
            'isShowChangeLanguage',
        ]),

        text() {
            return this.lang.Footer;
        },

        bookmakerIcon() {
            return this.currentLang === 'ru' ? 'bookmaker-rating' : 'bookmaker-rating-en';
        },

        showModeSwitch() {
            return !(process.env.IS_MOBILE_APP || this.$platform.pwa.any || this.isPwaIgnored);
        },

        selectedLangVisualKey() {
            return LangItemsEnum.getItemByOneWinKey(this.currentLang).visualKey;
        },
    },

    methods: {
        switchAppMode() {
            AppStorage.set(APP_MODE, APP_MODE_DESKTOP);
            window.location.reload();
        },

        switchLang({ value }) {
            this.$store.dispatch('setCurrentLanguage', { lang: value });
        },

        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },

    currentYear: new Date().getFullYear(),
    langOptionList: LangItemsEnum.sortedValuesByOneWinKey.map(
        ({ label, oneWinKey }) => ({ label, value: oneWinKey }),
    ),
};
</script>

<style lang="stylus" module>
.leftContainer
.rightContainer
.button
.topContainer
    display flex
    align-items center

.bookmakerIconWrapper
    position relative
    margin-right 5px

    &::before
        content ''
        position absolute
        min-width 1px
        background alpha(#fff, .1)
        top 5px
        bottom 5px
        right -5px

.license
    &.invest
        .button
        .langSelect
            background-image $gray-gradient-2

.separator
    width 100%
    height 1px
    background-color alpha(#5c72a3, .5)
    margin 15px 0

.topContainer
    justify-content space-between

.text
    font-size 8px
    line-height 12px
    color rgba(#5c72a3, .5)
    margin-left 5px
    text-align center

.button
.langSelect
    background-image $dark-gradient

.button
    height 24px
    width 24px
    justify-content center
    border-radius 6px
    cursor pointer
    margin-left 10px

.langSelect
    max-width 51px
    display flex
    align-items center
    padding 0 8px
    text-shadow 0 3px 6px alpha(#000, .2)
    font-size 13px
    margin-left 10px
    min-width 18px
    overflow hidden
    position relative
    flex-shrink 0
    height 24px
    border-radius 6px

.currentLangLabel
    font-size 12px
    line-height 14px
    color alpha(#fff, .9)

.langIcon
    height 12px!important
    width 12px!important
    flex-shrink 0
    margin-left 8px

.langInput
    height 100%
    left 0
    opacity 0
    position absolute
    top 0
    width 100%

.apg
    width 15px
    height 22px
    align-items center
    display flex
    justify-content center
    position relative
    margin-right 10px

    div
    img
        min-height 22px!important
        min-width 15px!important
        width 15px!important
        height 22px!important

    &::before
        content ''
        position absolute
        min-width 1px
        background alpha(#fff, .1)
        top 5px
        bottom 5px
        right -5px

.bottomContainer
    margin-top 15px

.ageLimit
    font-weight 800
    font-size 14px
    line-height 16px
    color alpha(#77829b, .4)
    margin-left 5px

@media screen and (max-width 374px)
    .bookmakerIconWrapper
        margin-right 2px

        &::before
            display none

    .apg
        width 30px
        height 46px
        margin-right 30px

        div
        img
            min-height 46px!important
            min-width 30px!important
            width 30px!important
            height 46px!important

        &::before
            top 0
            bottom 0
            right -15px

    .rightContainer
        flex-wrap wrap
        max-width 58px

    .button
        margin-left 5px
        margin-top 10px

    .langSelect
        margin-left 5px

    .modeSwitcher
        order 1

    .upWindow
        order 2
</style>
