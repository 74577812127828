<template lang="pug">
.menu__card(:style="backgroundStyles" v-on="$listeners")
    .menu__card-bump(:style="bumpStyles")
    .menu__card-left
        .menu__card-title
            slot(name="title") {{ title }}
        .menu__card-description
            slot(name="description") {{ description }}
    .menu__card-aside
        slot
</template>

<script>
export default {
    name: 'BurgerMenuCard',
    props: {
        title: String,
        description: String,
        backgroundGradient: String,
        bumpColors: Array,
    },
    computed: {
        backgroundStyles() {
            return {
                'background-image': this.backgroundGradient || null,
            };
        },
        bumpStyles() {
            return {
                'background-image': this.bumpColors && this.bumpColors.length === 2 ? `linear-gradient(to bottom, ${this.bumpColors[0]}, ${this.bumpColors[1]})` : null,
            };
        },
    },
};
</script>

<style scoped lang="stylus">
.menu__card
    position relative
    display flex
    align-items center
    min-height 59px
    border-radius $radius-md
    background-color rgba(151, 174, 225, 0.11)
    padding 12px 15px 12px 20px

.menu__card-bump
    position absolute
    height 62.4%
    width 3px
    border-radius 0 3px 3px 0
    left 0
    top 50%
    transform translateY(-50%)

.menu__card-description
    color alpha(#94a6cd, .7)
    font-size 10px
    line-height 12px

.menu__card-title
    font-size 13px
    font-weight 600
    margin-bottom 2px

.menu__card-left
    flex 1 1 auto
</style>
