<script>
export default {
    methods: {
        scrollTo($nav, $cur, bonus = 10) {
            if ($nav && $cur) {
                const scrollLeft = ($cur.offsetLeft + $cur.clientWidth) - $nav.clientWidth + bonus;
                const scrollRight = $cur.offsetLeft - bonus;

                if (scrollLeft - $nav.scrollLeft > 0) {
                    $nav.scrollTo(scrollLeft, 0);
                }

                if ($nav.scrollLeft - scrollRight > 0) {
                    $nav.scrollTo(scrollRight, 0);
                }
            }
        },
    },
};
</script>
