export default {
    toogleCollapseOpenned(state) {
        state.collapseOpenned = !state.collapseOpenned;
    },

    setActiveTab(state, tab) {
        state.activeTab = tab;
    },

    setSportsFilter(state, value) {
        state.sportsFilter = value;
    },

    clearSportsFilter(state) {
        state.sportsFilter = [];
    },

    setHotFilter(state, value) {
        state.hotFilter = value;
    },

    setFavouriteFilter(state, value) {
        state.favouriteFilter = value;
    },

    setOptions(state, value) {
        state.options = value;
    },
};
