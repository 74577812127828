<template>
  <Component
    :is="tag"
    :class="rootClassName"
    @click="$emit('click', $event)"
  >
    <span class="button-content">
      <slot />
      <VIcon
        v-if="icon"
        :icon="icon"
      />
    </span>
  </Component>
</template>

<script>
export const THEMES = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    ORANGE: 'orange',
    PURPLE: 'purple',
    VIOLET: 'violet',
    GREEN: 'green',
    SECONDARY_FILLED: 'secondary-filled',
});

export const THEMES_LIST = Object.values(THEMES);

export const SIZES = Object.freeze({
    MEDIUM: 'm',
    LARGE: 'l',
    EXTRA_LARGE: 'xl',
});

export const SIZES_LIST = Object.values(SIZES);

export default {
    name: 'BaseButton',

    props: {
        tag: {
            type: String,
            default: 'button',
        },
        size: {
            type: String,
            default: SIZES.MEDIUM,
            validator: (it) => SIZES_LIST.includes(it),
        },
        theme: {
            type: String,
            default: THEMES.SECONDARY,
            validator: (it) => THEMES_LIST.includes(it),
        },
        loading: Boolean,
        rounded: Boolean,
        icon: {
            type: String,
            default: null,
        },
    },

    computed: {
        rootClassName() {
            return [
                'button',
                this.loading && 'loading',
                this.rounded && 'rounded',
                this.size && `size-${this.size}`,
                this.theme && `theme-${this.theme}`,
            ];
        },
    },
};
</script>

<style lang="stylus">
button-color($color)
    &
    a&:link
    a&:visited
    a&:-webkit-any-link
        color $color

.button
    border-radius $radius-md
    button-color($white)
    cursor pointer
    display block
    font-size 13px
    font-weight $fw-semi-bold
    height 35px
    min-width 36px
    overflow hidden
    padding 0 15px
    position relative
    text-align center
    text-decoration none
    transition all .2s
    user-select none

    // Темы
    &.theme-secondary
        background-color $neutral-bg-secondary

        &:disabled
            opacity .5
            cursor default

        &:not(:disabled)
            &:active
                opacity .8

        &.size-m
            font-size 11px

    &.theme-primary
        background-image $primary-gradient

        &:disabled
            opacity .5
            cursor default

        &:not(:disabled)
            box-shadow $primary-shadow

            &:active
                box-shadow 0 6px 22px 0 alpha(#056dda, 0)
                opacity .8

    &.theme-orange
        background-image $orange-gradient
        color #fff

        &:disabled
            opacity .5
            cursor default

        &:not(:disabled)
            box-shadow $orange-shadow

            &:active
                opacity .8

    &.theme-purple
        background-image $purple-gradient
        color #fff

        &:disabled
            opacity .5
            cursor default

        &:not(:disabled)
            box-shadow $purple-shadow

            &:active
                opacity .8

    &.theme-violet
        background-image $violet-gradient
        color #fff

        &:disabled
            opacity .5
            cursor default

        &:not(:disabled)
            box-shadow $violet-shadow

            &:active
                opacity .8

    &.theme-green
        background-image linear-gradient(225deg, #009746 0%, #38C172 100%)
        border-radius 8px
        text-shadow 0 3px 6px rgba(0, 0, 0, 0.2)

        & > *
            position relative

        &:before
            position absolute
            content ''
            left 0
            right 0
            top 0
            bottom 0
            background-color #000
            z-index 0
            opacity 0
            transition opacity .2s ease

        &:disabled
            opacity .5
            cursor default

        &:not(:disabled)
            box-shadow 0 6px 18px alpha(#38c171, .3)

            &:active:before
                opacity .2

    &.theme-secondary-filled
        background-color #304057
        text-shadow 0 3px 5px rgba(9, 15, 30, 0.2)

        & > *
            position relative

        &:before
            position absolute
            content ''
            left 0
            right 0
            top 0
            bottom 0
            background-color #000
            z-index 0
            opacity 0
            transition opacity .2s ease

        &:disabled
            opacity .5
            cursor default

        &:not(:disabled):active:before
            opacity .2

    &.size-m
        height 35px
        border-radius 8px
        font-size 13px

        .icon
            font-size 15px
            margin-left 5px

    &.size-l
        height 40px
        border-radius 10px
        font-size 14px

        .icon
            font-size 15px
            margin-left 5px

    &.size-xl
        height 45px
        border-radius 10px
        font-size 15px
        padding-left 25px
        padding-right 25px

        .icon
            font-size 15px
            margin-left 5px

    &.loading
        .button-content
            opacity 0

        &::after
            animation spinner .75s infinite linear
            border 2px solid white
            border-radius $radius-circle
            border-right-color transparent
            border-top-color transparent
            content ''
            display block
            height 1em
            left calc(50% - (1em / 2))
            pointer-events none
            position absolute
            top calc(50% - (1em / 2))
            transition all .2s
            width 1em

    .icon
        display inline
        vertical-align middle

    &.rounded
        border-radius 10000px

.button-content
    align-items center
    display flex
    height 100%
    justify-content center
    transition all .2s
    width 100%
</style>
