import VueRouter from 'vue-router';

import { NOT_SAVE_PREV_ROUTE } from '@/plugins/NotSavePrevRouteRules';
import {
    checkRoutesForReducedFunctionalityPoker,
    checkRoutesForReducedFunctionalityInvest,
} from '@/router/guards';
import routingGuard from '@/router/guards/main';
import { openLoaderOnOAuthPage } from '@/utils/globalLoader';

import ROUTE_NAME from '@mobile/constant/routeName';
import verifyGuard from '@mobile/router/verifyGuard';

import routes from './routes';

/**
 * @param {import('vuex').Store} store
 * @returns {VueRouter}
 */
export function createRouter(store) {
    const router = new VueRouter({
        routes,
        mode: 'history',
        scrollBehavior(to, from, savedPosition) {
            if (to.name === from.name) {
                return savedPosition || null;
            }
            return savedPosition || { x: 0, y: 0 };
        },
    });

    router.$prevRoute = function $prevRoute(to, label = '') {
        const { currentRoute = {} } = this;
        const { prevRoute } = currentRoute.params;
        const { name: currentRouteName } = currentRoute;
        const { name: prevRouteName } = prevRoute || {};
        const { name: toName } = to || {};
        const langLabel = router.app.$store.getters.langMobile.navigation;
        let newLabel = langLabel[ROUTE_NAME.HOME];
        let newRoute = { name: ROUTE_NAME.HOME, params: { dontSavePrevRoute: true } };

        if (
            prevRouteName
            && prevRouteName !== currentRouteName
            && !prevRoute.matched.some((route) => route.meta[NOT_SAVE_PREV_ROUTE] === true)
        ) {
            newLabel = label && toName === prevRouteName
                ? label
                : langLabel[prevRouteName];
            newRoute = prevRoute;
        } else if (to) {
            to.params = Object.assign(to.params || {}, { dontSavePrevRoute: true });

            newLabel = label || langLabel[to.name];
            newRoute = { ...to };
        }

        return {
            label: newLabel,
            route: newRoute,
            go: () => {
                this.push(newRoute).catch(() => {
                    this.push(ROUTE_NAME.HOME);
                });
            },
            onClick: () => this.push(newRoute),
        };
    };

    router.beforeEach(async (to, from, next) => {
        await store.dispatch('navigationMenu/beforeRoute', { to, from });
        await routingGuard(to, from, next, router, store);
    });

    router.beforeEach((to, from, next) => {
        verifyGuard(to, from, next, router);
    });

    router.beforeEach((to, from, next) => {
        checkRoutesForReducedFunctionalityPoker({
            to,
            next,
            store,
            routePoker: { name: ROUTE_NAME.TVBET_POKER_PRE },
        });
    });

    router.beforeEach((to, from, next) => {
        checkRoutesForReducedFunctionalityInvest({
            to,
            next,
            store,
            routeInvest: { name: ROUTE_NAME.INVEST },
        });
    });

    router.beforeEach((to, from, next) => {
        openLoaderOnOAuthPage(to, ROUTE_NAME.OAUTH);
        next();
    });

    return router;
}
