<template lang="pug">
Spinner(style="margin-top: 100px")
</template>

<script>
import Spinner from '@/components/Spinner.vue';

/*
    Компонент для авторизации через JWT (JSON Web Token)
    Используется для предоставления прямой ссылки на сайт,
    при переходе по такой ссылке пользователь авторизуется через этот,
    заранее сгенерированный специально для него токен, без логина/пароля
    Сам токен состоит из трех частей формата `headers.payload.verification` и закодирован в base64
    на фронте нас интересует только вторая часть
    Наш конкретный токен имеет payload-структуру { exp: Number, userId: String }
    Со стороны фронта мы убеждаемся что он не протух и отправляем его на бэкенд
    После этого бэкенд либо говорит, что токен валидный и авторизует нас, либо отвечает ошибкой, которую мы выводим
*/
export default {
    name: 'JWTAuth',
    components: { Spinner },
    inject: ['$notify'],
    async mounted() {
        const authToken = this.$route.params.token;
        try {
            const [, payload] = authToken.split('.');
            const decodedPayload = JSON.parse(atob(payload));
            // TODO Возможно время будет в миллисекундах, а не в секундах
            if (decodedPayload.exp < (Date.now() / 1000)) {
                this.showErrorAndRedirect('Token expired');
                return;
            }
        } catch (e) {
            this.showErrorAndRedirect('Invalid token');
            return;
        }
        try {
            await this.$store.dispatch('signInByJWTToken', authToken);
            this.onSuccess();
        } catch (error) {
            this.showErrorAndRedirect(error);
        }
    },
    methods: {
        showErrorAndRedirect(error = 'Error') {
            this.$notify.error(error);
            this.$router.replace('/');
        },
        onSuccess() {
            this.$router.push('/');
        },
    },
};
</script>
