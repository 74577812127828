import Vue from 'vue';

import resizeDirective from '@/directives/resize';
import scrollLoad from '@/directives/scrollLoad';
import {
    useLocale,
    useModalProvider,
    useNotification,
    useParseError,
    useUserIntentionMemo,
    useFilters,
} from '@/hooks';
import { useAmplitudeExperiment } from '@/hooks/useAmplitudeExperiment';
import AppTitlePlugin from '@/plugins/AppTitle';
import { start as startDynamicCSSVariablesPlugin } from '@/plugins/DinamicCSSvariablesPlugin';
import existInvest from '@/plugins/ExistInvest';
import GoogleTagManager from '@/plugins/GoogleTagManager';
import NavigationMenuPlugin from '@/plugins/NavigationMenuPlugin';
import { devicePlugin } from '@/plugins/Platform';
import PushApiPlugin from '@/plugins/PushApiPlugin';
import SaveOauthDataPlugin from '@/plugins/SaveOauthDataPlugin';
import CheckVersionService from '@/services/CheckVersionService';

import { MODAL_LOCALE } from '@mobile/constant/modalName';
import globalMobileMixin from '@mobile/mixins/global';
import modalDescriptions from '@mobile/modals';
import modalGuard from '@mobile/modals/guard';
import BurgerMenuPlugin from '@mobile/plugins/BurgerMenuPlugin';
import MobileAppPromoPlugin from '@mobile/plugins/MobileAppPromoPlugin';
import { createStore } from '@mobile/store';

import App from './App.vue';
import { createRouter } from './router/router';

/**
 * @returns {void}
 */
const registerDirectives = () => {
    Vue.directive('resize', resizeDirective);
    Vue.directive('scroll-load', scrollLoad);
};

/**
 * @param {import('vue-router').VueRouter} router
 * @param {import('vuex').Store} store
 * @returns {void}
 */
const registerPlugins = ({ router, store }) => {
    Vue.use(AppTitlePlugin({ router, store }));
    Vue.use(BurgerMenuPlugin);
    Vue.use(devicePlugin(navigator.userAgent));
    Vue.use(existInvest(store));
    Vue.use(GoogleTagManager);
    Vue.use(MobileAppPromoPlugin({ store }));
    Vue.use(NavigationMenuPlugin({ store }));
    Vue.use(PushApiPlugin(store, router));
    Vue.use(SaveOauthDataPlugin(store));
};

/**
 * @returns {Promise<{ app: Vue, router: import('vue-router').VueRouter, store: import('vuex').Store }>}
 */
export const createApp = async () => {
    const store = createStore();
    const router = createRouter(store);

    registerDirectives();
    registerPlugins({ router, store });

    // TODO: избавиться от глобального миксина
    Vue.mixin(globalMobileMixin);

    const button = document.querySelector('#call-support_2 .button');
    if (button) {
        if (button.classList.contains('active')) {
            button.click();
        }
        button.parentElement.remove();
    }

    CheckVersionService.start(router);
    startDynamicCSSVariablesPlugin(store);

    const parseError = useParseError(store);
    const $notify = useNotification(parseError);

    /**
     * @property {Filters} $filters
     * @property {Modal} $modal
     * @property {Object} $notify
     * @property {Function} parseError
     * @property {Object} supportChat
     * @property {Object} locale
     * @property {Object} userIntention
     */
    const app = new Vue({
        router,
        store,
        provide() {
            const $modal = useModalProvider(store, modalDescriptions, modalGuard);
            const locale = useLocale(store, () => {
                $modal.openOver(MODAL_LOCALE);
            });
            const userIntention = useUserIntentionMemo(store, router);
            const $filters = useFilters(store);
            const amplitudeExperiment = useAmplitudeExperiment(store);

            return {
                $filters,
                $modal,
                $notify,
                parseError,
                locale,
                userIntention,
                amplitudeExperiment,
                store,
            };
        },
        render: (h) => h(App),
    });

    if (process.env.IS_MOBILE_APP) {
        const { default: setupNativeMobile } = await import('./setupNativeMobile');
        setupNativeMobile({ router, store, $notify });
    }

    return {
        app,
        router,
        store,
    };
};
