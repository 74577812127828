<template>
  <div
    :class="$style.root"
    :style="{ '--padding-x': paddingX }"
    data-body-scroll-lock-ignore
  >
    <slot />
  </div>
</template>

<script>
export default {
    props: {
        paddingX: {
            default: '15px',
            type: [Number, String],
        },
    },
};
</script>

<style lang="stylus" module>
$padding-x = $md

.root
    --padding-x $padding-x
    display flex
    list-style none
    overflow-x auto
    padding 0 $padding-x
    padding 0 var(--padding-x)
    scrollbar-width none
    width 100%
    -webkit-overflow-scrolling touch

    &::-webkit-scrollbar
        display none

    &::after
        content ''
        height 1px
        padding-right $padding-x
        padding-right var(--padding-x)
</style>
