import { GUARD_SHOW_VERIFY } from '@/constant/constants';
import { ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS } from '@/constant/rules';
import { AUTH, checkAuthValidate } from '@/plugins/AuthRules';
import { PromiseEnum } from '@/store/promise';
import { isOneClickModalSettingsValid } from '@/utils/modalSettingsValidators';

const authGuard = async (modal, store) => {
    const { meta = {} } = modal;

    if (AUTH in meta) {
        await Promise.all([
            store.rootGetters.promiseByName[PromiseEnum.AUTH],
            store.rootGetters.promiseByName[PromiseEnum.AUTH_TOKEN],
        ]);

        const isAllowRegister = (
            meta[ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS]
            && isOneClickModalSettingsValid(modal.options.props?.modalSettings)
        );
        const isAuthValid = checkAuthValidate(meta[AUTH], store.rootGetters.isAuthed);

        if (isAuthValid === false && !isAllowRegister) {
            await Promise.reject(new Error('only authorized'));
        }
    }
};

const showVerifyGuard = async (modal, store) => {
    const { meta = {} } = modal;

    if (GUARD_SHOW_VERIFY in meta && !store.rootGetters.showVerify) {
        await Promise.reject(new Error('show verify'));
    }
};

export default async function modalsGuard(modal, store) {
    await Promise.all([
        store.rootGetters.promiseByName[PromiseEnum.LANG],
        authGuard(modal, store),
        showVerifyGuard(modal, store),
    ]);
}
