<template lang="pug">
div(:class="$style.container")
    VIcon(
        v-for="sport in $options.sports"
        :size="15"
        :key="sport"
        :icon="`sports-promotion/${sport}`"
    )
</template>

<script>
import { SPORTS_PROMOTION } from '@/constant/constants';

export default {
    name: 'SportsPromotionSection',

    sports: SPORTS_PROMOTION,
};
</script>

<style lang="stylus" module>
.container
    display flex
    align-items center
    justify-content space-between
</style>
