<template>
  <RouterLink
    ref="rootRef"
    :class="[$style.root, isDiminished && $style.diminished]"
    :to="{ name: ROUTE_NAME.BONUSES }"
  >
    <VIcon
      :class="$style.icon"
      icon="present-bonus"
    />
    <div :class="$style.circleText">
      <div
        :class="$style.circleTitle"
        v-text="text['description-str1']"
      />
      <div
        :class="$style.circleSubtitle"
        v-text="text['description-str2']"
      />
    </div>
    <img
      :class="$style.img"
      :src="presentWithLight"
    >
  </RouterLink>
</template>

<script setup>
import { computed, ref } from 'vue';

import presentWithLight from '@/assets/images/bonus/present-with-light.png';
import { useDiminishedSize, useStore } from '@/hooks';

import ROUTE_NAME from '@mobile/constant/routeName';

const rootRef = ref();
const { isDiminished } = useDiminishedSize(
    rootRef,
    200,
);
const store = useStore();
const text = computed(() => store.getters.lang.payments.bonusLink);
</script>

<style lang="stylus" module>
.root
    height 50px
    display flex
    align-items center
    position relative
    cursor pointer
    background linear-gradient(86.37deg, #D062FF 2.96%, #7BB0FF 99.68%),
        linear-gradient(90deg, #ED6EA0 0%, #EC8C69 100%)
    box-shadow 0 5px 15px rgba(201, 85, 255, .5)
    border-radius 12px
    padding-left 12px

    .icon
        font-size 28px
        margin-right 8px

    &.diminished
        .icon
            display none

.circleText
    margin-right 48px
    z-index 1

.circleTitle
    font-weight 600
    font-size 13px
    line-height 16px

.circleSubtitle
    font-weight 600
    font-size 11px
    line-height 13px
    white-space nowrap

.img
    position absolute
    height 95px
    right -30px
    top -20px
    pointer-events none
</style>
