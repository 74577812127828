import { mapGetters } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';

import ROUTE_NAME from '@mobile/constant/routeName';
import VButton from '@mobile/elements/VButton.vue';
import VCheckbox from '@mobile/elements/VCheckbox.vue';
import VCollapse from '@mobile/elements/VCollapse.vue';
import VHorizontalCarousel from '@mobile/elements/VHorizontalCarousel.vue';
import VInput from '@mobile/elements/VInput.vue';
import VLoader from '@mobile/elements/VLoader.vue';
import VRadio from '@mobile/elements/VRadio.vue';
import VScrollX from '@mobile/elements/VScrollX.vue';
import VSelect from '@mobile/elements/VSelect.vue';
import VSelectNative from '@mobile/elements/VSelectNative.vue';

export default {
    components: {
        VButton,
        VCheckbox,
        VCollapse,
        VHorizontalCarousel,
        VIcon,
        VInput,
        VLoader,
        VRadio,
        VScrollX,
        VSelect,
        VSelectNative,
    },

    data: () => ({
        ROUTE_NAME,
    }),

    computed: {
        ...mapGetters([
            'currentLang',
            'isAuthed',
        ]),
    },
};
