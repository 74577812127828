<template lang="pug">
RouterLink(
    :to="link.route"
    :active="isRouteActive($route, link)"
    :target="link.target"
    ref="links"
    @click.native="onRouteClick(link)"
)
    img.navigation-item-image(
        v-if="link.image"
        :src="link.image"
        @load="$emit('updateScroll')"
    )
    .navigation-item-badge(
        v-if="link.badge"
        :style="{ background: link.badge.background }"
    ) {{ link.badge.text }}
    template(v-if="!link.image && (text[link.name] || link.lang)")
        | {{ text[link.name] || $filters.locale(link.lang) }}
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'NavigationItem',

    inject: ['$filters'],

    props: {
        link: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            isRouteActive: 'navigationMenu/isRouteActive',
        }),

        text() {
            return this.$store.getters.lang.navigation;
        },
    },

    methods: {
        onRouteClick(item) {
            if (item.scrollTop) {
                window.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: 'smooth',
                });
            }
        },
    },
};
</script>

<style scoped lang="stylus">
.navigation-item-badge
    display flex
    justify-content center
    align-items center
    border-radius 1em
    margin-right 1em
    height 2em
    width 4em
    font-size 8px
    font-weight 800

.navigation-item-image
    height 15px
</style>
