<template lang="pug">
.filter-control
    VIcon.icon(
        :class="{ active }"
        icon="clock2"
        margin
        square
    )

    .select
        slot(name="select")
</template>

<script>
export default {
    name: 'FiltersControl',

    props: {
        active: Boolean,
    },
};
</script>

<style lang="stylus" scoped>
.filter-control
    position relative
    height 16px
    width 16px

    .icon
        color $white
        &.active
            color #005AFF

    .select
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        opacity 0
</style>
