<template lang="pug">
.tabs
    a.tab(
        v-for="(item, i) in items"
        :key="i"
        :class="{ active: item === active }"
        @click="$emit('select', item)"
    )
        span.helper-line {{ $filters.locale(itemName(item)) }}
</template>

<script>
export default {
    name: 'Tabs',

    inject: ['$filters'],

    props: {
        items: {
            type: Array,
            default: () => ([]),
        },
        active: {
            type: [String, Object],
            required: false,
            default: null,
        },
    },

    methods: {
        itemName(item) {
            if (typeof item.text === 'string') {
                return item.text;
            }

            return item;
        },
    },
};
</script>

<style lang="stylus" scoped>
.tabs
    background-color $dark-500
    border-radius $radius-md
    display flex

.tab
    align-items center
    color $white
    cursor pointer
    display flex
    flex 1
    font-size 14px
    font-weight $fw-medium
    height 37px
    justify-content center
    min-width 36px
    overflow hidden
    padding 0 $xs
    text-align center
    text-decoration none
    user-select none
    letter-spacing .3px

.active
    background-image $primary-gradient
    transition background-color .2s
    border-radius $radius-md
    box-shadow $primary-shadow
</style>
