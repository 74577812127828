<template>
  <Component
    :is="tag"
    :class="rootClassName"
    :style="rootStyle"
    @click="$emit('click', $event)"
  >
    <span class="button-content">
      <slot />
    </span>
  </Component>
</template>

<script>
const BUTTON_THEMES = [
    'default',
    'text',
    'success',
    'primary',
    'primary-flat',
    'yellow',
    'outline',
    'grey',
    'dark',
    'wallet',
    'golden',
    'green',
    'blue-light',
    'grey-light',
    'red',
];

export default {
    props: {
        active: Boolean,
        loading: Boolean,
        size: {
            type: String,
            default: null,
            validator: (it) => ['sm', 'lg', 'md'].includes(it),
        },
        squareSize: {
            type: String,
            default: null,
        },
        tag: {
            type: String,
            default: 'button',
        },
        theme: {
            type: String,
            default: 'default',
            validator: (it) => BUTTON_THEMES.includes(it),
        },
    },

    computed: {
        rootClassName() {
            return [
                'button',
                this.active && 'active',
                this.loading && 'loading',
                this.squareSize && 'square',
                this.size,
                this.theme,
            ];
        },

        rootStyle() {
            return {
                '--square-size': this.squareSize,
            };
        },
    },
};
</script>

<style lang="stylus">
button-color($color)
    &
    a&:link
    a&:visited
    a&:-webkit-any-link
        color: $color

.button
    border-radius $radius-md
    button-color($white)
    cursor pointer
    display block
    font-size 13px
    font-weight $fw-semi-bold
    height 36px
    min-width 36px
    overflow hidden
    padding 0 15px
    position relative
    text-align center
    text-decoration none
    transition all .2s
    user-select none

    &:not(.loading):disabled
        opacity .5

    // Темы
    &.default
        background-image $transparent-gradient
        background-color $dark-700

    // TODO: Добавить стили состояния
    &.success
        background-image linear-gradient(80deg, #31bc69 -8%, #089e4e 93%)
        box-shadow $green-shadow

    &.red
        background-image linear-gradient(135deg, #FF4F4F 0%, #FF0000 100%);
        box-shadow $red-shadow

    &.dark
        background-image $transparent-gradient
        background-color $dark-325

        &:not(:disabled)
            &:active
            &.active
                background-color alpha($dark-325, .9)

            &:focus
            &:hover
                background-color alpha($dark-325, .9)
                box-shadow 0 2px 14px 0 alpha($dark-325, .9)

    &.primary
        background-image $primary-gradient
        box-shadow $primary-shadow

        &:not(:disabled)
            &:active
            &.active
                background-color #053f93

            &:focus
            &:hover
                background-color #1564d8
                box-shadow 0 2px 14px 0 #1564d8

    &.primary-flat
        background #1A88F3
        text-shadow: 0 3px 5px rgba(9, 15, 30, 0.2)

    &.wallet
        background-color alpha($dark-10, .2)
        padding 10px

        .button-content
            display flex
            flex-direction column
            align-items center

        &.active
            background-image $primary-gradient

    &.yellow
        background-image $transparent-gradient
        background-color #f8ab00
        button-color($dark-900)

        &:not(:disabled)
            &:active
            &.active
                background-color #d99000

            &:focus
            &:hover
                background-color #ffbd2a
                box-shadow 0 2px 14px 0 #ffbd2a

        &.loading::after
            border-color $dark-900

    &.text
        background-image $transparent-gradient
        Button-Text()

        background-color transparent
        border none
        border-radius unset
        height auto
        min-width unset
        padding 0

    &.outline
        background-image $transparent-gradient
        background-color transparent
        border-color #1f2841

    &.grey
        background-image $transparent-gradient
        background-color #ebebeb
        button-color(#090e1d)

    &.golden
        box-shadow 0 6px 18px 0 rgba(#ffca09, .3)
        background-image linear-gradient(96deg, #ffe036, #ffc600)
        text-shadow 0 3px 6px alpha(#000, .2)
        font-size 14px
        font-weight 600
        color $dark-900

    &.green
        background-image linear-gradient(70deg, #31bc69 -8%, #089e4e 96%)
        border-style none
        box-shadow $green-shadow

    &.blue-light
        background linear-gradient(107.15deg, #0095ff 0%, #0855c4 100%)
        box-shadow $primary-shadow
        border-radius 10px

    &.grey-light
        background-image $transparent-gradient
        background-color #f3f6fb
        font-weight 500
        font-size 14px
        line-height 20px
        letter-spacing -0.15px
        color #5c72a3
        border-radius 10px

    // Размеры
    &.sm
        height 30px

    &.md
        height 40px

    &.lg
        border-width 2px
        height 45px

    &.square
        width var(--square-size)
        height var(--square-size)
        min-width 0
        min-height 0
        padding 0
        border-radius 8px

    &.loading
        .button-content
            opacity 0

        &::after
            animation spinner .75s infinite linear
            border 2px solid white
            border-radius $radius-circle
            border-right-color transparent
            border-top-color transparent
            content ''
            display block
            height 1em
            left calc(50% - (1em / 2))
            pointer-events none
            position absolute
            top calc(50% - (1em / 2))
            transition all .2s
            width 1em

    .icon
        display inline
        vertical-align middle

    &:disabled
        cursor default

.button-content
    align-items center
    display flex
    height 100%
    justify-content center
    transition all .2s
    width 100%
    line-height 115%
</style>
