/* eslint-disable no-underscore-dangle */

import Vue from 'vue';

export class Counter {
    constructor(value, options = {}) {
        this._value = Vue.observable({
            value: 0,
        });
        this.startVal = 0;
        this.endVal = 0;
        this.duration = 1000;

        this._startTime = 0;
        this._frameVal = 0;
        this._countDown = false;

        Object.assign(this, options);
    }

    get value() {
        return this._value.value;
    }

    start() {
        if (this.duration > 0) {
            this._determineDirectionAndSmartEasing();
            this.rAF = requestAnimationFrame((time) => this._count.call(this, time));
        } else {
            this._printValue(this.endVal);
        }
    }

    /**
     * @param {Number} newEndVal
     */
    update(newEndVal) {
        cancelAnimationFrame(this.rAF);
        this._startTime = null;
        this.endVal = newEndVal;
        if (this.endVal === this._frameVal) {
            return;
        }
        this.startVal = this._frameVal;
        this._determineDirectionAndSmartEasing();
        this.rAF = requestAnimationFrame((time) => this._count.call(this, time));
    }

    setStartValue(value) {
        this.startVal = value;
        this._frameVal = value;
        this._printValue(value);
    }

    /**
     * @param {Number} timestamp
     */
    _count(timestamp) {
        if (!this._startTime) {
            this._startTime = timestamp;
        }

        const progress = timestamp - this._startTime;

        if (this._countDown) {
            this._frameVal = this.startVal - ((this.startVal - this.endVal) * (progress / this.duration));
        } else {
            this._frameVal = this.startVal + (this.endVal - this.startVal) * (progress / this.duration);
        }

        if (this._countDown) {
            this._frameVal = (this._frameVal < this.endVal) ? this.endVal : this._frameVal;
        } else {
            this._frameVal = (this._frameVal > this.endVal) ? this.endVal : this._frameVal;
        }

        this._printValue(this._frameVal);

        if (progress < this.duration) {
            this.rAF = requestAnimationFrame((time) => this._count.call(this, time));
        }
    }

    /**
     * @param {Number} val
     */
    _printValue(val) {
        this._value.value = Math.floor(val);
    }

    _determineDirectionAndSmartEasing() {
        // eslint-disable-next-line no-underscore-dangle
        this._countDown = this.startVal > this.endVal;
    }
}
