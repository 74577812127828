import Vue from 'vue';

import JWTAuth from '@/components/JWTAuth.vue';
import OAuth from '@/components/OAuth/OAuthMain.vue';
import {
    BETGAME_TV,
    TWAINSPORT_TV,
    DetailingSelection,
    GUARD_VERIFY,
    HEADER_INVEST,
    HEADER_LEADERBOARD,
    FOOTER_LEADERBOARD,
    LANG_ALLOWED_INVEST,
    LANG_ALLOWED_TV,
    LANG_RU,
    META_FORCE_CHANGE_LANG,
    META_USE_GAME_RELATED_VIEW,
    META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY,
    META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST,
    META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER,
    META_NOT_ALLOWED_FOR_BLOCKABLE_DOMAIN,
    META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES,
    OFFSET_HEADER_ITEM_FROM_BLUR,
    PromotedGameIdEnum,
    REMOVE_LAYOUT_MARGIN, REMOVE_LAYOUT_MARGIN_BOTTOM,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
    ROUTE_META_CUSTOM_FOOTER,
    ROUTE_META_HIDE_DIVIDENDS_MODAL,
    SERVICE_LIVE,
    SERVICE_PREMATCH,
    TVBET_GAME,
    TVBET_POKER,
} from '@/constant/constants';
import { AUTH } from '@/plugins/AuthRules';
import { LANG } from '@/plugins/LangRules';
import { NOT_SAVE_PREV_ROUTE } from '@/plugins/NotSavePrevRouteRules';
import { TESTER } from '@/plugins/TesterRules';
import { CASINO_CATEGORIES } from '@/store/casino/enums';
import { lazyLoadView, getBetsProps } from '@/utils';

import BannerCarousel from '@mobile/components/BannerCarousel.vue';
import BetsHeader from '@mobile/components/Bets/Header.vue';
import PromotionLinks from '@mobile/components/PromotionLinks.vue';
import ROUTE_NAME from '@mobile/constant/routeName';
import Loader from '@mobile/elements/VLoader.vue';
import Footer from '@mobile/layouts/components/Footer.vue';
import Header from '@mobile/layouts/components/Header.vue';
import Navigation from '@mobile/layouts/components/Navigation.vue';
import Tabs from '@mobile/layouts/components/Tabs.vue';
import EmptyLayout from '@mobile/layouts/EmptyLayout.vue';
import ComponentMainLayout from '@mobile/layouts/MainLayout.vue';
import Search from '@mobile/layouts/Search.vue';

const centeredPreloader = Vue.component('Loader', {
    render() {
        return <Loader center />;
    },
});

const MainLayout = (pageNameOrPageLoader, {
    layout = ComponentMainLayout,
    search,
    load,
    promotionLinks,
    carousel,
    betsHeader,
    header = Header,
    navigation = Navigation,
    tabs = Tabs,
    footer,
} = {}, preloaderDelay) => {
    const pageLoader = typeof pageNameOrPageLoader === 'string'
        ? () => import(`@mobile/pages/${pageNameOrPageLoader}.vue`)
        : pageNameOrPageLoader;

    return {
        default: () => lazyLoadView(pageLoader(), centeredPreloader, preloaderDelay),
        layout,
        load,
        promotionLinks,
        header,
        navigation,
        search,
        carousel,
        betsHeader,
        tabs,
        footer,
    };
};

const homeTabs = [
    ROUTE_NAME.HOME,
    ROUTE_NAME.BETS_LIVE,
    ROUTE_NAME.BETS_PREMATCH,
    ROUTE_NAME.CASINO,
    ROUTE_NAME.CASINO_LIVE,
    ROUTE_NAME.BETS_CYBERSPORT,
    ROUTE_NAME.CASINO_GAME_FANTASY_SPORT,
    ROUTE_NAME.INVEST,
    ROUTE_NAME.CASINO_GAMES,
    ROUTE_NAME.TVBET,
    ROUTE_NAME.BETGAMES,
    ROUTE_NAME.TWAINSPORT,
    ROUTE_NAME.VIRTUAL_SPORT,
    ROUTE_NAME.POKER,
    ROUTE_NAME.CASES,
    ROUTE_NAME.LEADERBOARD,
    ROUTE_NAME.TV,
    ROUTE_NAME.STATS,
    ROUTE_NAME.CASINO_GAME_AVIATOR,
    ROUTE_NAME.BONUSES,
    ROUTE_NAME.CASINO_GAMES_ONE_WIN,
];

const betsTabs = [
    ROUTE_NAME.BETS_LIVE,
    ROUTE_NAME.BETS_PREMATCH,
    ROUTE_NAME.BETS_CYBERSPORT,
    ROUTE_NAME.BETS_RESULT,
    ROUTE_NAME.STATS,
];
const gamesTabs = [
    ROUTE_NAME.CASINO_LIVE,
    ROUTE_NAME.CASES,
    ROUTE_NAME.CASINO_GAMES,
    ROUTE_NAME.CASINO_GAMES_ONE_WIN,
    ROUTE_NAME.VIRTUAL_SPORT,
    ROUTE_NAME.TVBET,
    ROUTE_NAME.BETGAMES,
    ROUTE_NAME.TWAINSPORT,
];

export default [
    {
        path: '/',
        redirect: { name: ROUTE_NAME.HOME },
    },

    {
        path: '/registration',
        components: {
            default: () => import('@mobile/pages/Registration.vue'),
            layout: EmptyLayout,
        },
    },

    {
        name: ROUTE_NAME.HOME,
        path: '/bets/home',
        components: MainLayout('Home', {
            carousel: BannerCarousel,
            promotionLinks: PromotionLinks,
            footer: Footer,
        }),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.HOME,
                    },
                },
                tabs: homeTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.BETS_LIVE,
        path: '/bets/bets-live',
        redirect: {
            name: ROUTE_NAME.BETS,
            params: { service: SERVICE_LIVE },
        },
    },
    {
        name: ROUTE_NAME.BETS_PREMATCH,
        path: '/bets/bets-prematch',
        redirect: {
            name: ROUTE_NAME.BETS,
            params: { service: SERVICE_PREMATCH },
        },
    },
    {
        path: '/bets/new/:service(live|prematch)/:sportId',
        redirect: '/bets/:service/:sportId',
    },
    {
        path: '/bets/new/:service(live|prematch)/:sportId/:categoryId/:tournamentId',
        redirect: '/bets/:service/:sportId/:categoryId/:tournamentId',
    },
    {
        path: '/bets/new/:service(live|prematch)/:sportId/:tournamentId',
        redirect: '/bets/:service/:sportId/с/:tournamentId',
    },
    {
        path: '/match/new/:service(live|prematch)/:matchId',
        redirect: '/bets/:service/s/с/t/:matchId',
    },
    {
        name: ROUTE_NAME.BETS,
        path: '/bets/:service(live|prematch)',
        components: MainLayout('Bets', {
            betsHeader: BetsHeader,
            footer: Footer,
        }),
        props: {
            default: ({ params: { service } }) => ({ service }),
            betsHeader: ({ params: { service } }) => ({ service }),
            navigation: ({ params: { service } }) => ({
                controls: {
                    search: { routeName: service },
                    filter: { type: service },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.BETS_SPORT,
        path: '/bets/:service(live|prematch)/:sportId',
        components: MainLayout('BetsSport'),
        props: {
            default: ({ params }) => getBetsProps(params),
            navigation: ({ params: { service } }) => ({
                controls: {
                    search: { routeName: service },
                    filter: { type: service },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
        },
    },
    {
        name: ROUTE_NAME.BETS_TOURNAMENT,
        path: '/bets/:service(live|prematch)/:sportId/:categoryId/:tournamentId',
        components: MainLayout('BetsTournament'),
        props: {
            default: ({ params }) => getBetsProps(params),
            navigation: ({ params: { service } }) => ({
                controls: {
                    filter: { type: service },
                    search: { routeName: service },
                },
                tabs: betsTabs,
            }),
        },
    },
    {
        name: ROUTE_NAME.BETS_MATCH,
        path: '/bets/:service(live|prematch)/:sportId/:categoryId/:tournamentId/:matchId',
        components: MainLayout('Match'),
        props: {
            default: ({ params }) => getBetsProps(params),
            navigation: ({ params: { service } }) => ({
                controls: {
                    search: { routeName: service },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_CYBERSPORT,
        path: '/bets/cybersport',
        components: MainLayout('BetsEsport', {
            footer: Footer,
        }),
        props: {
            default: ({ query: { service, tab, sports } }) => ({
                service,
                tab,
                sports: sports?.split(',').map(Number) ?? [],
            }),
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BETS_CYBERSPORT,
                    },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.BETS_CYBERSPORT_SPORT,
        path: '/bets/cybersport/:sportId',
        alias: '/bets/cybersport/:service/:sportId',
        components: MainLayout('BetsSportEsport', {
            footer: Footer,
        }),
        props: {
            default: ({ params: { sportId } }) => ({
                sportId: +sportId,
            }),
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BETS_CYBERSPORT,
                    },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.BETS_CYBERSPORT_TOURNAMENT,
        path: '/bets/cybersport/:sportId/:tournamentId',
        alias: '/bets/cybersport/:service/:sportId/:categoryId/:tournamentId',
        components: MainLayout('BetsTournamentEsport', {
            footer: Footer,
        }),
        props: {
            default: ({ params: { sportId, tournamentId } }) => ({
                sportId: +sportId,
                tournamentId: +tournamentId,
            }),
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BETS_CYBERSPORT,
                    },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.BETS_CYBERSPORT_MATCH,
        path: '/match/cybersport/:service/:matchId',
        alias: '/bets/cybersport/:service/:sportId/:categoryId/:tournamentId/:matchId',
        components: MainLayout('Match'),
        props: {
            default: ({ params: { service, matchId } }) => ({
                service,
                matchId: Number(matchId),
                isCybersport: true,
            }),
            navigation: ({ params: { service } }) => ({
                controls: {
                    search: {
                        routeName: service === SERVICE_PREMATCH ? ROUTE_NAME.BETS_PREMATCH : ROUTE_NAME.BETS_LIVE,
                    },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_OUTRIGHTS_SPORT,
        path: '/bets/outrights/:sportId',
        components: MainLayout('BetsSport'),
        props: {
            default: ({ params }) => ({
                ...getBetsProps(params),
                service: SERVICE_PREMATCH,
                outrightsOnly: true,
            }),
            navigation: () => ({
                controls: {
                    filter: { type: SERVICE_PREMATCH },
                    search: { routeName: ROUTE_NAME.BETS_PREMATCH },
                },
                tabs: betsTabs,
            }),
        },
    },
    {
        name: ROUTE_NAME.BETS_OUTRIGHTS_TOURNAMENT,
        path: '/bets/outrights/:sportId/:tournamentId/',
        components: MainLayout('MatchOutright'),
        props: {
            default: ({ params }) => getBetsProps(params),
            navigation: ({ params: { service } }) => ({
                controls: {
                    search: { routeName: service },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_CUSTOM_EVENT,
        path: '/bets/custom/event/:eventId',
        components: MainLayout('BetsCustomEvent'),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BETS_CUSTOM_EVENT,
                    },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.BETS_CUSTOM,
        path: '/bets/custom/:groupId?/:childId?',
        components: MainLayout('BetsCustom'),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BETS_CUSTOM,
                    },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_VSPORT,
        path: '/bets/live/vsport',
        components: MainLayout('BetsVSport'),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BETS_LIVE,
                    },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_COUPONS,
        path: '/coupons',
        components: MainLayout('Coupons', {
            navigation: null,
        }),
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_RESULT,
        path: '/results',
        components: MainLayout('Results'),
        props: {
            navigation: () => ({
                controls: {
                    filter: { type: 'results' },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_RESULT_SPORT,
        path: '/results/:sportId',
        components: MainLayout('ResultsSport'),
        props: {
            default: (route) => ({
                sportId: +route.params.sportId,
            }),
            navigation: () => ({
                controls: {
                    filter: { type: 'results' },
                },
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.BETS_RESULT_TOURNAMENT,
        path: '/results/:sportId/:tournamentId',
        components: MainLayout('ResultsTournament'),
        props: {
            default: (route) => ({
                sportId: +route.params.sportId,
                tournamentId: +route.params.tournamentId,
            }),
            navigation: () => ({
                tabs: betsTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        path: '/casino/list',
        redirect: {
            name: ROUTE_NAME.CASINO,
        },
    },

    {
        name: ROUTE_NAME.CASINO,
        path: '/casino',
        components: MainLayout('Casino', {
            navigation: null,
            footer: Footer,
        }),
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        path: '/casino/live',
        redirect: { name: ROUTE_NAME.CASINO_LIVE },
    },

    {
        path: '/games-1win',
        redirect: { name: ROUTE_NAME.CASINO_GAMES_ONE_WIN },
    },

    {
        path: '/virtual-sport',
        redirect: { name: ROUTE_NAME.VIRTUAL_SPORT },
    },

    {
        path: '/games',
        redirect: { name: ROUTE_NAME.CASINO_GAMES },
    },

    {
        path: '/casino/games',
        redirect: { name: ROUTE_NAME.CASINO_GAMES },
    },

    {
        name: ROUTE_NAME.CASINO_LIVE,
        path: `/casino/list/${CASINO_CATEGORIES.LIVE}`,
        redirect: { name: ROUTE_NAME.CASINO_CATEGORY, params: { categoryId: CASINO_CATEGORIES.LIVE } },
    },

    {
        name: ROUTE_NAME.CASINO_GAMES_ONE_WIN,
        path: `/casino/list/${CASINO_CATEGORIES.ONE_WIN}`,
        redirect: { name: ROUTE_NAME.CASINO_CATEGORY, params: { categoryId: CASINO_CATEGORIES.ONE_WIN } },
    },

    {
        name: ROUTE_NAME.VIRTUAL_SPORT,
        path: `/casino/list/${CASINO_CATEGORIES.VIRTUAL_SPORTS}`,
        redirect: { name: ROUTE_NAME.CASINO_CATEGORY, params: { categoryId: CASINO_CATEGORIES.VIRTUAL_SPORTS } },
    },

    {
        name: ROUTE_NAME.CASINO_GAMES,
        path: `/casino/list/${CASINO_CATEGORIES.OTHER}`,
        redirect: { name: ROUTE_NAME.CASINO_CATEGORY, params: { categoryId: CASINO_CATEGORIES.OTHER } },
    },

    {
        name: ROUTE_NAME.CASINO_CATEGORY,
        path: '/casino/list/:categoryId',
        components: MainLayout('CasinoCategory', {
            navigation: null,
        }),
        props: {
            default: (route) => ({
                categoryId: Number(route.params.categoryId),
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASINO_PROMO,
        path: '/casino/promo/:id',
        components: MainLayout('CasinoPromo', {
            navigation: null,
        }),
        props: {
            default: (route) => ({
                id: Number(route.params.id),
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASINO_PROVIDER,
        path: '/casino/provider/:ownerName',
        components: MainLayout('CasinoProvider', {
            navigation: null,
        }),
        props: {
            default: (route) => ({ ownerName: route.params.ownerName }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASINO_PROVIDERS,
        path: '/casino/providers',
        components: MainLayout('CasinoProviders', {
            navigation: null,
        }),
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASINO_WIDGET,
        path: '/casino/widget/:widgetId',
        components: MainLayout('CasinoWidget', {
            navigation: null,
        }),
        props: {
            default: (route) => ({
                widgetId: route.params.widgetId,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASINO_FAVORITES,
        path: '/casino/favourite',
        components: MainLayout('CasinoCategory', {
            navigation: null,
        }),
        props: {
            default: {
                categoryId: CASINO_CATEGORIES.FAVORITES,
            },
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        path: '/casino/play/spribe_aviator/:any?',
        redirect: { name: ROUTE_NAME.CASINO_GAME_AVIATOR },
    },

    {
        name: ROUTE_NAME.CASINO_GAME_FANTASY_SPORT,
        path: '/casino/play/scout_fantasy',
        redirect: {
            name: ROUTE_NAME.CASINO_GAME,
            params: { id: PromotedGameIdEnum.SCOUT_FANTASY },
        },
    },

    {
        name: ROUTE_NAME.CASINO_GAME_AVIATOR,
        path: '/casino/play/aviator',
        redirect: {
            name: ROUTE_NAME.CASINO_GAME,
            params: { id: 'aviator' },
        },
    },

    {
        name: ROUTE_NAME.CASINO_GAME,
        path: '/casino/play/:id/:isDemo?',
        components: MainLayout('CasinoGame', {
            header: Header,
            navigation: null,
            tabs: null,
        }),
        meta: {
            [REMOVE_LAYOUT_MARGIN]: true,
            [META_USE_GAME_RELATED_VIEW]: true,
            [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
            [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [NOT_SAVE_PREV_ROUTE]: true,
        },
    },

    {
        name: ROUTE_NAME.TVBET,
        path: '/tvbet/play',
        props: {
            default: () => ({
                gameType: TVBET_GAME,
            }),
        },
        components: MainLayout('TvBet', {
            header: Header,
            navigation: null,
            tabs: null,
        }),
        meta: {
            [REMOVE_LAYOUT_MARGIN]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_USE_GAME_RELATED_VIEW]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
        },
    },

    {
        name: ROUTE_NAME.BETGAMES,
        path: '/betgames/play',
        props: {
            default: () => ({
                gameType: BETGAME_TV,
            }),
        },
        components: MainLayout('TvBet', {
            header: Header,
            navigation: null,
            tabs: null,
        }),
        meta: {
            [META_USE_GAME_RELATED_VIEW]: true,
            [REMOVE_LAYOUT_MARGIN]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
        },
    },

    {
        name: ROUTE_NAME.TWAINSPORT,
        path: '/twain-sport/play',
        props: {
            default: () => ({
                gameType: TWAINSPORT_TV,
            }),
        },
        components: MainLayout('TvBet', {
            header: Header,
            navigation: null,
            tabs: null,
        }),
        meta: {
            [META_USE_GAME_RELATED_VIEW]: true,
            [REMOVE_LAYOUT_MARGIN]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
        },
    },

    {
        path: '/tvbet',
        redirect: {
            name: ROUTE_NAME.TVBET,
        },
    },

    {
        path: '/betgames',
        redirect: {
            name: ROUTE_NAME.BETGAMES,
        },
    },

    {
        path: '/twain-sport',
        redirect: {
            name: ROUTE_NAME.TWAINSPORT,
        },
    },

    {
        name: ROUTE_NAME.TVBET_POKER,
        path: '/poker/play',
        props: {
            default: () => ({
                gameType: TVBET_POKER,
                noRedirect: true,
            }),
        },
        components: MainLayout('TvBet', {
            header: Header,
            navigation: null,
            tabs: null,
        }),
        meta: {
            [REMOVE_LAYOUT_MARGIN]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_USE_GAME_RELATED_VIEW]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [ROUTE_META_HIDE_DIVIDENDS_MODAL]: true,
            [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
        },
    },

    {
        name: ROUTE_NAME.TVBET_POKER_PRE,
        path: '/poker/preplay',
        components: MainLayout('PokerPrePlay', {
            navigation: null,
            tabs: null,
        }),
        meta: {
            [REMOVE_LAYOUT_MARGIN]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.POKER,
        path: '/poker',
        components: MainLayout('PokerLanding', {
            footer: Footer,
        }),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BONUSES,
                    },
                },
                tabs: homeTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
        },
    },

    {
        name: ROUTE_NAME.SEARCH_RESULT,
        path: '/search/:routeName',
        components: MainLayout('SearchResult', {
            navigation: null,
            search: Search,
        }),
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.FAVOURITES,
        path: '/favourites',
        components: MainLayout('Favourites', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.FAVOURITES_EVENTS,
        path: '/favourites-events',
        components: MainLayout('FavouritesEvents', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.FAVOURITES_TOURNAMENTS,
        path: '/favourites-tournaments',
        components: MainLayout('FavouritesTournaments', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.FAVOURITES_GAMES,
        path: '/favourites-games',
        components: MainLayout('FavouritesGames', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        name: ROUTE_NAME.FAVOURITES_CUSTOM_BETS,
        path: '/favourites-custom-bets',
        components: MainLayout('FavouritesCustomBets', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },
    {
        // При переходе по ссылке вида '/rules' в новом окне открывается десктопная версия правил.
        // Для строгого перехода в мобильные правила – используется этот редирект
        name: ROUTE_NAME.MOBILE_RULES,
        path: '/mobile-rules',
        redirect: { name: ROUTE_NAME.RULES },
    },
    {
        path: '/rules.html',
        redirect: (to) => ({ ...to, name: ROUTE_NAME.RULES }),
    },
    {
        name: ROUTE_NAME.RULES,
        path: '/rules/:ruleId?',
        components: MainLayout('Rules', {
            navigation: null,
        }),
        beforeEnter: (to, from, next) => {
            if (to.hash && !to.params.ruleId) {
                next({ name: ROUTE_NAME.RULES, params: { ruleId: to.hash.slice(1) } });
            } else {
                next();
            }
        },
    },

    {
        name: ROUTE_NAME.RULE,
        path: '/rule/:id',
        components: MainLayout('Rule', {
            navigation: null,
        }),
    },

    {
        name: ROUTE_NAME.PROFILE,
        path: '/profile',
        components: MainLayout('Profile', {
            navigation: null,
            footer: Footer,
        }),
        props: {
            header: () => ({
                profilePage: true,
            }),
        },
        meta: {
            [AUTH]: true,
        },
    },

    {
        name: ROUTE_NAME.PROFILE_EDIT,
        path: '/profile/edit',
        components: MainLayout('ProfileEdit', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
        },
    },

    {
        name: ROUTE_NAME.DETAILING,
        path: `/detailing/:selection(${Object.values(DetailingSelection).join('|')})?`,
        components: MainLayout('Detailing', {
            navigation: null,
        }),
        props: {
            default: true,
        },
        meta: {
            [AUTH]: true,
        },
    },

    {
        name: ROUTE_NAME.PROFILE_WALLETS,
        path: '/profile/wallets',
        components: MainLayout('ProfileWallets', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
        },
    },

    {
        name: ROUTE_NAME.WALLET_DEPOSIT,
        path: '/deposit',
        components: MainLayout('Deposit', {
            navigation: null,
        }),
        props: {
            default: (route) => ({
                currencyDefault: route.query.currency,
                paymentDefaultAbbreviation: route.params.paymentDefaultAbbreviation,
            }),
        },
        meta: {
            [AUTH]: true,
        },
    },

    {
        name: ROUTE_NAME.WALLET_WITHDRAWAL,
        path: '/money/withdrawal',
        components: MainLayout('Withdrawal', {
            navigation: null,
        }),
        props: {
            default: (route) => ({
                currencyDefault: route.query.currency,
            }),
        },
        meta: {
            [AUTH]: true,
            [GUARD_VERIFY]: true,
        },
    },

    {
        name: ROUTE_NAME.PROFILE_BETS_HISTORY,
        path: '/profile/bets-history/:status(all|opened|closed)',
        components: MainLayout('BetsHistory', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.PROFILE_BETS_HISTORY_COUPON,
        path: '/profile/bets-history/:status(all|opened|closed)?/:betId(\\d+)',
        components: MainLayout('BetsHistoryCoupon', {
            navigation: null,
        }),
        meta: {
            [AUTH]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASES,
        path: '/cases',
        redirect: { name: ROUTE_NAME.CASES_LIST },
        props: {
            navigation: () => ({
                tabs: gamesTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASES_LIST,
        path: '/cases/:theme?',
        components: MainLayout('CasesList'),
        props: {
            default: true,
            navigation: () => ({
                tabs: gamesTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.CASE,
        path: '/case/:caseTypeId',
        components: MainLayout('CasesGame'),
        props: {
            default: (route) => ({ caseTypeId: parseInt(route.params.caseTypeId, 10) }),
            navigation: () => ({
                tabs: gamesTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
        },
    },

    {
        name: ROUTE_NAME.ACCESS,
        path: '/access',
        components: MainLayout('Access', {
            navigation: null,
            header: null,
        }),
    },

    {
        name: ROUTE_NAME.OAUTH,
        path: '/oauth/:type',
        components: {
            layout: OAuth,
        },
        meta: {
            [NOT_SAVE_PREV_ROUTE]: true,
        },
    },

    {
        name: ROUTE_NAME.AUTH_TOKEN,
        path: '/auth/token/:token',
        components: {
            layout: JWTAuth,
        },
        meta: {
            [NOT_SAVE_PREV_ROUTE]: true,
        },
    },

    {
        name: ROUTE_NAME.TV,
        path: '/tv',
        components: MainLayout('TV', {
            navigation: null,
            footer: Footer,
        }),
        meta: {
            [LANG]: LANG_ALLOWED_TV,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [META_NOT_ALLOWED_FOR_BLOCKABLE_DOMAIN]: true,
        },
    },

    {
        name: ROUTE_NAME.TV_CINEMA,
        path: '/tv/:cinemaId',
        components: MainLayout('TVDescription', {
            navigation: null,
            footer: Footer,
        }),
        props: {
            default: (route) => ({
                cinemaId: route.params.cinemaId.toString(),
            }),
        },
        meta: {
            [LANG]: LANG_ALLOWED_TV,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [META_NOT_ALLOWED_FOR_BLOCKABLE_DOMAIN]: true,
            [META_RELOAD_PAGE_ONLY_FOR_MAJOR_UPDATES]: true,
        },
    },

    {
        path: '/tv/:cinemaId/view',
        redirect: { name: ROUTE_NAME.TV_CINEMA },
    },

    {
        name: ROUTE_NAME.STATS,
        path: '/stats/:externalId?',
        components: MainLayout('Stats', {
            header: Header,
            navigation: null,
            footer: null,
        }, 0),
        props: {
            default: ({ params }) => ({
                externalId: params.externalId,
            }),
        },
        meta: {
            [REMOVE_LAYOUT_MARGIN]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
        },
    },

    {
        name: ROUTE_NAME.PWA_INSTRUCTION,
        path: '/pwa-instruction',
        components: MainLayout('PWAInstruction', {
            navigation: null,
        }),
    },

    {
        name: ROUTE_NAME.LEADERBOARD,
        path: '/leaderboard',
        components: MainLayout('Leaderboard', {
            footer: Footer,
        }),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.LEADERBOARD,
                    },
                },
                tabs: homeTabs,
            }),
            default: (route) => ({
                showHistory: Boolean(route.query.history),
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_INVEST]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_LEADERBOARD,
            [ROUTE_META_CUSTOM_FOOTER]: FOOTER_LEADERBOARD,
            [TESTER]: true,
        },
    },

    {
        name: ROUTE_NAME.INVEST,
        path: '/invest',
        components: MainLayout('Invest', {
            tabs: null,
            footer: Footer,
        }),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.INVEST,
                    },
                },
                tabs: homeTabs,
            }),
            footer: () => ({
                isInvest: true,
                investRules: true,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_BETS_ONLY]: true,
            [META_FORCE_CHANGE_LANG]: {
                allowed: LANG_ALLOWED_INVEST,
                changeTo: LANG_RU,
            },
            [ROUTE_META_CUSTOM_HEADER_BOTTOM]: HEADER_INVEST,
            [OFFSET_HEADER_ITEM_FROM_BLUR]: true,
            [REMOVE_LAYOUT_MARGIN_BOTTOM]: true,
        },
    },

    {
        name: ROUTE_NAME.BONUSES,
        path: '/bonuses',
        components: MainLayout('Bonuses', {
            footer: Footer,
        }),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.BONUSES,
                    },
                },
                tabs: homeTabs,
            }),
        },
        meta: {
            [META_NOT_ALLOWED_FOR_REDUCED_FUNCTIONALITY_POKER]: true,
        },
    },

    {
        name: ROUTE_NAME.FREE_MONEY,
        path: '/free-money',
        components: MainLayout(() => import('@/components/FreeMoney/FreeMoneyPage.vue'), {
            footer: Footer,
        }),
        props: {
            navigation: () => ({
                controls: {
                    search: {
                        routeName: ROUTE_NAME.FREE_MONEY,
                    },
                },
                tabs: homeTabs,
            }),
        },
    },

    {
        path: '*',
        redirect: { name: 'home' },
    },
];
