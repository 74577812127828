<template lang="pug">
.jackpot-panel(:class="{'jackpot-panel-tile': tile}")
    .jackpot-panel-wrap(
        v-if="jackpot.current"
        key="panel"
    )
        .jackpot-panel-title(v-if="withTitle")
            .title-decoration-separator
            VIcon.title-decoration-star(icon="star-bold" square)

            p.jackpot-panel-title-name Jackpot

            VIcon.title-decoration-star(icon="star-bold" square)
            .title-decoration-separator

        CasinoRandomGameGetter(:categoryId="$options.CASINO_CATEGORIES.SLOTS")
            template(#default="{ getRandomGame }")
                .jackpot-panel-value-container(
                    :style="{ fontSize: size }"
                    @click="openRandomGame(getRandomGame())"
                )
                    span.jackpot-panel-value(
                        v-for="(value, index) in jackpotValue"
                        :key="`${value}|${index}`"
                    ) {{ value }}

                    span.jackpot-panel-value(key="currency") {{ $filters.currency(jackpotCurrency) }}

        .jackpot-panel-all(v-if="jackpot.issued")
            p.jackpot-panel-all-text {{ text.totalIssued }}:
            p.jackpot-panel-all-value {{ $filters.number(jackpot.issued) }} {{ $filters.currency(jackpotCurrency) }}

    VLoader(
        v-else
        key="loader"
    )
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CasinoRandomGameGetter from '@/components/CasinoRandomGameGetter.vue';
import CasinoGameOpenerMixin from '@/mixins/CasinoGame/CasinoGameOpener';
import { Counter } from '@/plugins/Counter';
import { CASINO_CATEGORIES } from '@/store/casino/enums';

export default {
    name: 'CasinoJackpotPanel',

    components: {
        CasinoRandomGameGetter,
    },

    mixins: [CasinoGameOpenerMixin],

    inject: ['$filters'],

    props: {
        withTitle: {
            type: Boolean,
            default: true,
        },
        tile: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: '40px',
        },
    },

    data: () => ({
        counter: {
            value: 0,
        },
    }),

    computed: {
        ...mapGetters({
            jackpot: 'casino/jackpot',
            jackpotCurrency: 'casino/jackpotCurrency',
        }),

        ...mapGetters(['lang']),

        jackpotValue() {
            const value = this.$filters.number(this.counter.value);

            return value.split(/[^\d]/);
        },

        text() {
            return this.lang['casino-jackpot-panel'];
        },
    },

    watch: {
        'jackpot.current': function handleJackpotCurrent(value) {
            if (this.counter.startVal) {
                this.counter.update(value);
            } else {
                this.counter.setStartValue(this.jackpot.current);
            }
        },
    },

    mounted() {
        this.counter = new Counter({
            duration: 4000,
        });

        if (this.jackpot.current) {
            this.counter.setStartValue(this.jackpot.current);
        } else {
            this.loadJackpot();
        }
    },

    methods: {
        ...mapActions('casino', [
            'loadJackpot',
        ]),

        async openRandomGame(game) {
            this.tryOpenGame(await game);
        },
    },

    CASINO_CATEGORIES,
};
</script>

<style lang="stylus" scoped>
.jackpot-panel
    display flex
    align-items center
    justify-items center
    justify-content center
    z-index 1
    padding 1px
    position relative
    color #fff
    opacity 1

    &::before
    &::after
        content ''
        position absolute

    &::before
        border-radius 12px
        z-index -2
        top 0
        bottom 0
        left -2px
        right -2px
        background-image radial-gradient(
            circle at -65% 157%,
            #c5991e,
            #b68100 7%,
            #fff29d 67%,
            #b57f00 116%,
            #c7940f 141%
        )

    &::after
        border-radius 10px
        z-index -1
        top 2px
        bottom 2px
        left 0
        right 0
        background #090e1e

    &.jackpot-panel-tile
        &::before
        &::after
            content none

    :deep(.loader)
        min-height auto

    :deep(.icon-spinner)
        color #fff29d

    .jackpot-panel-wrap
        align-items center
        display flex
        flex-direction column

    .jackpot-panel-title
        align-items center
        display flex

        .title-decoration-separator
            background linear-gradient(to left, #fff, transparent)
            height 1px
            opacity .3
            width 25px

            &:last-child
                background linear-gradient(to right, #fff, transparent)

        .title-decoration-star
            display block
            fill url('#jackpot-star-radial-gradient')
            font-size 15px
            margin 0 12px

        .jackpot-panel-title-name
            font-size 30px
            font-weight 900
            letter-spacing -1.78px
            text-shadow 0 2px 87px #0c1820

    .jackpot-panel-value-container
        display inline-block
        font-weight 900
        line-height 1.05
        position relative
        font-family $ff-mono

        &::before
            content ""
            position absolute
            height 7px
            bottom -5px
            left -1px
            border-radius 10px
            width calc(100% + 2px)
            z-index 1
            background linear-gradient(90deg, #090f1f, transparent, #090f1f)

        .jackpot-panel-value
            padding 0 4px
            position relative
            //background radial-gradient(circle at 0 128%, #c5991e, #b68100 5%, #fff29d 48%, #b57f00 82%, #c7940f)
            border-bottom 1px solid alpha(#fff, .3)
            border-radius 2px / 10px

            &:nth-child(1)
                background linear-gradient(70deg, #be8e12, #dbba4f)
                -webkit-background-clip text
                -webkit-text-fill-color transparent
                border-bottom-left-radius 0

            &:nth-child(2)
                background linear-gradient(70deg, #dbbb51, #efda7c)
                -webkit-background-clip text
                -webkit-text-fill-color transparent

            &:nth-child(3)
                background linear-gradient(70deg, #ecd576, #b88202)
                -webkit-background-clip text
                -webkit-text-fill-color transparent
                border-bottom-right-radius 0

            &:nth-child(4)
                background linear-gradient(70deg, #bb8605, #c4900c)
                -webkit-background-clip text
                -webkit-text-fill-color transparent
                border-bottom-left-radius 0
                border-bottom-right-radius 0

    .jackpot-panel-all
        display flex
        margin-top 10.5px

        .jackpot-panel-all-text
            font-size 12px
            letter-spacing -.16px
            margin-right 4px

        .jackpot-panel-all-value
            font-size 12px
            color $primary-800
            font-weight bold
            letter-spacing -.16px

        .jackpot-panel-all-button-icon
            font-size 12px
</style>
