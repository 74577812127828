import { SERVICE_PREMATCH } from '@/constant/constants';

import DESKTOP_ROUTES from '@desktop/constant/routeName';
import MOBILE_ROUTES from '@mobile/constant/routeName';

function needToUseOutrightsRouteForTournament({
    countPrematch,
    viewOutrightInPrematch,
    service,
} = {}) {
    return service === SERVICE_PREMATCH
        && countPrematch === 0
        && viewOutrightInPrematch === false;
}

/**
 * @param {'live'|'prematch'} service
 * @param {number?} sportId
 * @param {number?} categoryId
 * @param {number?} tournamentId
 * @param {number?} matchId
 * @param {number?} sport
 * @param {number?} category
 * @param {number?} tournament
 * @param {number?} match
 * @param {object?} options
 * @returns {object}
 */
export function getDesktopBetsRoute({
    service,
    sportId,
    categoryId,
    tournamentId,
    tournamentObject,
    matchId,
    sport = sportId,
    category = categoryId,
    tournament = tournamentId,
    match = matchId,
    options = {},
}) {
    return {
        name: needToUseOutrightsRouteForTournament(tournamentObject)
            ? DESKTOP_ROUTES.BETS_OUTRIGHTS
            : DESKTOP_ROUTES.BETS,
        params: {
            service,
            sport,
            category,
            tournament,
            match,
        },
        ...options,
    };
}

/**
 * @param {'live'|'prematch'} service
 * @param {number?} sportId
 * @param {number?} categoryId
 * @param {number?} tournamentId
 * @param {number?} matchId
 * @param {object?} options
 * @returns {object}
 */
export function getMobileBetsRoute({
    service,
    sportId,
    categoryId,
    tournamentId,
    tournamentObject,
    matchId,
    options = {},
}) {
    const routeParams = {
        params: {
            service,
            sportId,
            categoryId,
            tournamentId,
            matchId,
        },
        ...options,
    };

    const needToUseOutrightsRoute = needToUseOutrightsRouteForTournament(tournamentObject);

    if (Number.isInteger(matchId)) {
        return {
            name: MOBILE_ROUTES.BETS_MATCH,
            ...routeParams,
        };
    }

    if (Number.isInteger(tournamentId)) {
        return {
            name: needToUseOutrightsRoute
                ? MOBILE_ROUTES.BETS_OUTRIGHTS_TOURNAMENT
                : MOBILE_ROUTES.BETS_TOURNAMENT,
            ...routeParams,
        };
    }

    if (Number.isInteger(sportId)) {
        return {
            name: MOBILE_ROUTES.BETS_SPORT,
            ...routeParams,
        };
    }

    return {
        name: MOBILE_ROUTES.BETS,
        ...routeParams,
    };
}

/**
 * @param {boolean} isMobile
 * @returns {function}
 */
export function getBetsRoute(isMobile) {
    return isMobile
        ? getMobileBetsRoute
        : getDesktopBetsRoute;
}
