<script>
import { oneOf } from '@1win/types';

import SocialsEnum, { socialsStaticInfo } from '@/constant/SocialsEnum';

const socialGradientClassMap = {
    [SocialsEnum.TELEGRAM]: 'telegram',
    [SocialsEnum.VKONTAKTE]: 'vkontakte',
    [SocialsEnum.YOUTUBE]: 'youtube',
    [SocialsEnum.INSTAGRAM]: 'instagram',
    [SocialsEnum.FACEBOOK]: 'facebook',
    [SocialsEnum.TWITTER]: 'twitter',
};

export default {
    props: {
        social: {
            required: true,
            type: String,
            validator: oneOf(Object.values(SocialsEnum)),
        },
    },

    render() {
        return this.$scopedSlots.default({
            gradientClassName: this.$style[socialGradientClassMap[this.social]],
            iconName: socialsStaticInfo[this.social].icon,
            text: socialsStaticInfo[this.social].text,
            href: this.$store.getters.socialLinks[this.social],
        });
    },
};
</script>

<style lang="stylus" module>
.telegram
    background linear-gradient(240.2deg, #0FB1D6 0%, #0088CC 61.34%)
    box-shadow 0 5px 16px rgba(#4CA2F6, .3)

.vkontakte
    background linear-gradient(108.65deg, #91B8EF 2.63%, #4288ED 101.05%),
    linear-gradient(135deg, #FF4F4F 0%, #FF0000 100%)
    box-shadow 0 5px 16px rgba(#4CB9F6, .3)

.instagram
    background linear-gradient(214.99deg, #7E2BF4 7.65%, #ED146E 51.93%, #FFC90C 95.29%)
    box-shadow 0 5px 16px rgba(#BA4D65, .3)

.facebook
    background linear-gradient(135deg, #82A4E9 0%, #4267B2 100%)
    box-shadow 0 5px 16px rgba(#7396DC, .3)

.twitter
    background linear-gradient(135deg, #79CCFF 0%, #1DA1F2 100%)
    box-shadow 0 5px 16px rgba(#7396DC, .3)

.youtube
    background linear-gradient(135deg, #FF4F4F 0%, #FF0000 100%)
    box-shadow 0 5px 16px rgba(#F64C4C, .3)
</style>
