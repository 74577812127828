import PWA from '@/plugins/InstallPWA';

import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    name: 'PWAInstall',

    inject: ['$modal'],

    props: {
        instruction: Boolean,
        mobileApp: Boolean,
    },

    render() {
        const actionPWA = !this.$props.instruction && PWA.active;
        const downloadMobileApp = Boolean(this.$props.mobileApp);

        return this.$slots.default.map((vnode) => {
            vnode.data.on = {
                ...vnode.data.on || {},
                ...actionPWA ? {
                    click: () => {
                        PWA.popup();
                        this.$modal.close();
                        this.$emit('click');
                    },
                } : {
                    click: () => {
                        this.$router.push({ name: ROUTE_NAME.PWA_INSTRUCTION });
                        this.$modal.close();
                        this.$emit('click');
                    },
                },
                ...downloadMobileApp ? {
                    click: () => {
                        const link = document.createElement('a');
                        link.setAttribute('download', '1win.apk');
                        link.href = '/application/apk/1win.apk';
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.$emit('click');
                    },
                } : {},
            };

            return vnode;
        });
    },
};
