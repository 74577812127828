<script>
const unify = (event) => (event.changedTouches ? event.changedTouches[0] : event);
const offsetForSwipe = 10;
const FAST_SWIPE_SPEED = 150;

export default {
    props: {
        activeSlideIndex: {
            type: Number,
            default: 0,
        },
        repeat: Boolean,
        width: {
            type: Number,
            default: 100,
        },
        prohibitRightListing: {
            type: Boolean,
            default: false,
        },
        prohibitLeftListing: {
            type: Boolean,
            default: false,
        },
    },

    created() {
        const step = Number.MAX_SAFE_INTEGER / 3;
        this.keys = [0, step, step * 2];
    },

    mounted() {
        const slots = this.$slots.default || [];
        const { style } = this.$refs.content;
        this.lastTouchStartTime = 0;
        this.itemsCount = slots.length;

        this.downed = false;
        this.swipe = undefined;
        this.start = {
            x: 0,
            y: 0,
        };

        let offset = 0;
        Object.defineProperty(this, 'offset', {
            get() { return offset; },
            set(value) {
                offset = value;
                style.transform = `translateX(${value}px)`;
                this.$emit('changeOffsetAndDuration', {
                    offset: offset / this.maxOffset,
                    duration: this.duration,
                });
            },
        });

        let maxOffset = 0;
        Object.defineProperty(this, 'maxOffset', {
            get() { return maxOffset; },
            set(value) { maxOffset = value; },
        });

        let duration = 0;
        Object.defineProperty(this, 'duration', {
            get() { return duration; },
            set(value) { duration = value; style.transitionDuration = `${value}ms`; },
        });

        let marginLeft = 0;
        Object.defineProperty(this, 'marginLeft', {
            get() { return marginLeft; },
            set(value) { marginLeft = value; style.marginLeft = `${value}%`; },
        });

        // !!! not use for render function, does not work on Safari
        const [$contentEl] = this.$el.getElementsByClassName('carousel-content');

        $contentEl.addEventListener('mousedown', this.down, false);
        $contentEl.addEventListener('touchstart', this.down, false);
        $contentEl.addEventListener('mouseout', this.up, false);
        $contentEl.addEventListener('mouseup', this.up, false);
        $contentEl.addEventListener('touchend', this.up, false);
        $contentEl.addEventListener('mousemove', this.move, false);
        $contentEl.addEventListener('touchmove', this.move, false);
        $contentEl.addEventListener('touchcancel', this.cancel, false);
    },

    beforeDestroy() {
        const [$contentEl] = this.$el.getElementsByClassName('carousel-content');

        $contentEl.removeEventListener('mousedown', this.move);
        $contentEl.removeEventListener('touchstart', this.move);
        $contentEl.removeEventListener('mouseout', this.move);
        $contentEl.removeEventListener('mouseup', this.move);
        $contentEl.removeEventListener('touchend', this.move);
        $contentEl.removeEventListener('mousemove', this.move);
        $contentEl.removeEventListener('touchmove', this.move);
        $contentEl.removeEventListener('touchcancel', this.cancel);
    },

    methods: {
        resetOffset(offset) {
            const duration = (Math.sqrt(Math.abs(1 - offset / (this.$refs.content?.offsetWidth ?? offset))) / 3) * 1000;
            const end = performance.now() + duration;

            const animation = (time) => {
                if (time >= end) {
                    this.duration = 0;
                    return;
                }
                if (this.duration !== duration) {
                    this.duration = duration;
                } else if (this.offset !== 0) {
                    this.updateMargin();
                    this.offset = 0;
                }
                requestAnimationFrame(animation);
            };

            requestAnimationFrame(animation);
        },

        updateMargin() {
            if (!this.activeSlideIndex && !this.repeat) {
                this.marginLeft = 0;
                return;
            }
            if (this.activeSlideIndex === this.itemsCount && !this.repeat) this.marginLeft = 100 - this.width;
            else this.marginLeft = (100 - this.width) / 2;
        },

        down(event) {
            const { clientX, clientY } = unify(event);
            this.downed = true;
            this.start.x = clientX;
            this.start.y = clientY;
            this.lastTouchStartTime = performance.now();
        },

        move(event) {
            if (!this.downed) return;
            if (this.duration) {
                this.duration = 64;
            }
            const { clientX, clientY } = unify(event);

            const dx = clientX - this.start.x;
            const dy = clientY - this.start.y;

            // Игнорируем ложные касания
            if (Math.abs(dx) < offsetForSwipe && !this.swipe) return;

            // Игнорируем скролл по горизонтали, если идет скролл по вертикали
            if (Math.abs(dx) < Math.abs(dy) && this.swipe !== 'x') {
                this.swipe = 'y';
                return;
            }

            if (this.prohibitRightListing && dx < 0) return;
            if (this.prohibitLeftListing && dx > 0) return;

            if (this.swipe !== 'y') {
                this.swipe = 'x';

                let offset = dx;
                const sign = Math.sign(offset);
                this.maxOffset = (event.currentTarget.offsetWidth / 100) * this.width - (100 - this.width);

                // Не даем проскролить дальше maxOffset
                offset *= sign;
                if (offset > this.maxOffset) offset = this.maxOffset;
                offset *= sign;

                this.offset = Math.floor(offset);

                // Отрубаем скролл по вертикали
                if (event.cancelable) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        },

        up({ currentTarget }) {
            if (!this.downed) return;

            // Получаем оффсет после которого стригериться переход на другой слайд
            const isFastSwipe = performance.now() - this.lastTouchStartTime < FAST_SWIPE_SPEED;
            const triggerOffset = isFastSwipe ? 0 : (currentTarget.offsetWidth / 100) * 20;

            let { offset } = this;
            const sign = Math.sign(offset);
            offset *= sign;

            // Вычисляем новую позицию
            let newValue = this.activeSlideIndex;
            if (offset > triggerOffset) {
                newValue -= sign;
                if (newValue < 0) newValue = this.repeat ? this.itemsCount : 0;
                else if (newValue > this.itemsCount) newValue = this.repeat ? 0 : this.itemsCount;
            }
            offset *= sign;

            if (this.activeSlideIndex !== newValue) {
                this.$emit('update:activeSlideIndex', newValue);
                offset += -sign * (currentTarget.offsetWidth + 10);
                if (sign > 0) {
                    this.keys[0] += 1;
                } else {
                    this.keys[2] += 1;
                }
            }

            // Вызов анимации обнуления оффсета
            this.duration = 0;
            this.$nextTick(() => {
                this.offset = offset;
                this.resetOffset(Math.abs(this.offset));
            });

            this.downed = false;
            this.swipe = undefined;
        },

        cancel() {
            this.lastTouchStartTime = 0;
            this.itemsCount = 0;

            this.downed = false;
            this.swipe = undefined;
            this.start = {
                x: 0,
                y: 0,
            };
            this.offset = 0;
            this.duration = 0;
            this.marginLeft = 0;
        },
    },

    render(h) {
        const slots = this.$slots.default || [];
        this.itemsCount = slots.length - 1;

        // Выборка и подготовка 3 внод, которые нужно отобразить
        const empty = h('div', { style: { width: this.width } });
        const items = [this.activeSlideIndex - 1, this.activeSlideIndex, this.activeSlideIndex + 1]
            .map((index) => {
                if (this.repeat) {
                    if (index < 0) return this.itemsCount + 1 + index;
                    if (index > this.itemsCount) return index - this.itemsCount - 1;
                }
                return index;
            })
            .map((index) => slots[index] || empty)
            .map((vnode, index) => {
                if (vnode.componentOptions) {
                    vnode.data = {
                        ...(vnode.data ? vnode.data : {}),
                        on: vnode.componentOptions.listeners,
                    };
                    vnode.key = this.keys[index];
                }
                return vnode;
            });

        return h(
            'div',
            {
                class: 'carousel',
            },
            [
                h(
                    'div',
                    {
                        class: 'carousel-content',
                        style: {
                            width: `${this.width}%`,
                        },
                        ref: 'content',
                    },
                    items,
                ),
            ],
        );
    },
};
</script>

<style lang="stylus" scoped>
.carousel
    display flex
    flex-direction column
    overflow hidden

.carousel-content
    display flex
    justify-content center
    transition-delay 0s
    transition-duration 0s
    transition-property all
    transition-timing-function ease-out
    width 100%
    will-change transform

    & > *
        flex none
        margin 0 5px
        user-select none
        width 100%
</style>
