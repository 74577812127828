<template lang="pug">
FiltersControl(:active="isResultsDateFilterActive")
    template(#select)
        VSelectNative.sel(
            :items="items"
            :value="currentFilterValue"
            @input="onFilterSelect"
        )
</template>

<script>
import {
    mapGetters, mapState, mapActions, mapMutations,
} from 'vuex';

import FiltersControl from './FiltersControl.vue';

const filterTypes = {
    DEFAULT: 0,
    TIME: 1,
    DAY: 2,
};

const monthDay = Object.freeze({
    month: 'long',
    day: 'numeric',
});

export default {
    name: 'FiltersControlResult',

    components: {
        FiltersControl,
    },

    computed: {
        ...mapGetters([
            'dateTimeFormat',
            'lang',
        ]),

        ...mapGetters('results', [
            'isResultsDateFilterActive',
        ]),

        ...mapState('results', [
            'resultsTimeFilter',
        ]),

        text() {
            return this.lang['filters-control-result'];
        },

        currentFilterValue() {
            if (!this.isResultsDateFilterActive) {
                return 'default';
            }

            const cur = this.resultsTimeFilter.date || this.resultsTimeFilter.hoursToStart;
            return cur.toString();
        },

        hours() {
            return [4, 5, 6, 7, 8, 9, 10, 11, 12, 24].map((hour) => ({
                type: filterTypes.TIME,
                label: `${hour} ${this.text.hourShort} ${this.text.ago}`,
                value: hour,
            }));
        },

        days() {
            const dateFormatter = this.dateTimeFormat(monthDay);

            return Array.from({ length: 8 }, (it, i) => {
                const date = new Date();
                date.setDate(date.getDate() - 1 - i);
                return {
                    type: filterTypes.DAY,
                    value: `${date
                        .getDate()
                        .toString()
                        .padStart(2, '0')}/${(date.getMonth() + 1).toString()
                        .padStart(2, '0')}`,
                    label: dateFormatter(date),
                };
            });
        },

        items() {
            return [
                { type: filterTypes.DEFAULT, label: this.text.today, value: 'default' },
                ...this.hours,
                ...this.days,
            ];
        },
    },

    methods: {
        ...mapMutations('results', [
            'setResultsTimeFilter',
            'resetResultsTimeFilter',
        ]),

        ...mapActions('results', [
            'loadResults',
        ]),

        onFilterSelect({ type, value }) {
            switch (type) {
                case filterTypes.TIME:
                    this.setResultsTimeFilter({ hoursToStart: value });
                    break;
                case filterTypes.DAY:
                    this.setResultsTimeFilter({ date: value });
                    break;
                default:
                    this.resetResultsTimeFilter();
            }

            this.loadResults();
        },
    },
};
</script>

<style lang="stylus" scoped>
.sel
    width 100%
</style>
