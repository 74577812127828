const cacheRect = new Map();
const cacheResult = new Map();

let div;

/**
 * @param {String} text
 * @param {Object} styles
 * @returns {DOMRect}
 */
const getRect = (text, styles) => {
    let key;
    try {
        key = `${text}${JSON.stringify(styles)}`;
    } catch (e) {
        key = `${text}${styles}`;
    }

    if (cacheRect.has(key)) {
        return cacheRect.get(key);
    }

    Object.keys(styles).forEach((prop) => {
        div.style[prop] = styles[prop];
    });
    div.innerHTML = text;

    const result = div.getBoundingClientRect();
    cacheRect.set(key, result);

    return result;
};

const createDiv = () => {
    div = document.createElement('div');
    div.style.whiteSpace = 'pre-line';
    div.style.display = 'inline-block';
    document.body.prepend(div);
};

const removeDiv = () => {
    document.body.removeChild(div);
};

const getTextTwoString = (text, charsForWrap) => {
    if (charsForWrap && text.length <= charsForWrap) return text;

    const spaceIndexList = Array.from(text.matchAll(/\s/g)).map((space) => space.index);
    const textLength = text.length;
    const halfTextLength = (textLength - 1) / 2;
    const between = spaceIndexList.reduce((acc, space) => [
        space < halfTextLength && space > acc[0] ? space : acc[0],
        space >= halfTextLength && space < acc[1] ? space : acc[1],
    ],
    [-1, textLength]);
    let foundIndex = Math.abs(halfTextLength - between[0]) <= Math.abs(halfTextLength - between[1])
        ? between[0]
        : between[1];
    if (charsForWrap && foundIndex <= 20) {
        foundIndex = spaceIndexList.find((index) => index > 20) || textLength;
    }
    if (foundIndex < 0 || foundIndex >= textLength) return text;
    return `${text.substring(0, foundIndex)}\n${text.substring(foundIndex + 1, textLength)}`;
};

const checkRect = (rect, maxWidth, maxHeight) => rect.width <= maxWidth && rect.height <= maxHeight;

const checkRects = (rect1, rect2, maxWidth, maxHeight) => {
    if (checkRect(rect1, maxWidth, maxHeight)) {
        return 1;
    }
    if (checkRect(rect2, maxWidth, maxHeight)) {
        return 2;
    }
    return 0;
};

const getFitText = (text, size, maxWidth, maxHeight, styles, charsForWrap) => {
    let firstIndex = 0;
    let lastIndex = text.length - 1;
    let currentText;

    const getCurrentIndex = () => (firstIndex + 1 === lastIndex
        ? Math.ceil((firstIndex + lastIndex) / 2)
        : Math.floor((firstIndex + lastIndex) / 2));

    while (firstIndex !== lastIndex) {
        const currentIndex = getCurrentIndex();
        currentText = getTextTwoString(`${text.substring(0, currentIndex)}...`, charsForWrap);
        const rect = getRect(currentText, {
            ...styles,
            fontSize: `${size}px`,
        });

        if (checkRect(rect, maxWidth, maxHeight)) {
            firstIndex = currentIndex;
        } else {
            lastIndex = currentIndex - 1;
        }
    }
    return getTextTwoString(`${text.substring(0, getCurrentIndex())}...`, charsForWrap);
};

/**
 * @param text
 * @param minSize
 * @param maxSize
 * @param maxWidth
 * @param maxHeight
 * @param styles
 * @param charsForWrap
 * @returns {object}
 */
export const getMaximumFontSizeFromRect = ({
    text,
    minSize,
    maxSize,
    maxWidth,
    maxHeight,
    styles,
    charsForWrap = 0,
}) => {
    let key;

    try {
        key = `${text}${minSize}${maxSize}${maxWidth}${maxHeight}${JSON.stringify(styles)}`;
    } catch (e) {
        key = `${text}${minSize}${maxSize}${maxWidth}${maxHeight}${styles}`;
    }

    if (cacheResult.has(key)) {
        return cacheResult.get(key);
    }

    createDiv();
    const rects = Array(2);
    const texts = [text.replace(/\s/g, ' '), getTextTwoString(text, charsForWrap)];
    const onlyTwoString = charsForWrap && texts[0].length > charsForWrap;

    const setRects = (currentSize) => {
        const currentStyles = Object.fromEntries(Object.entries(styles).map((style) => (typeof style[1] === 'function' ? [style[0], style[1](currentSize)] : style)));

        if (!onlyTwoString) {
            rects[0] = getRect(texts[0], {
                ...currentStyles,
                fontSize: `${currentSize}px`,
            });
        }
        rects[1] = getRect(texts[1], {
            ...currentStyles,
            fontSize: `${currentSize}px`,
        });
    };

    let min = minSize;
    let max = maxSize;

    const getCurrentSize = () => (min + 1 === max
        ? Math.ceil((min + max) / 2)
        : Math.floor((min + max) / 2));

    while (min !== max) {
        const currentSize = getCurrentSize();
        setRects(currentSize);

        if (
            (!onlyTwoString && checkRects(rects[0], rects[1], maxWidth, maxHeight))
            || (onlyTwoString && checkRect(rects[1], maxWidth, maxHeight))
        ) {
            min = currentSize;
        } else {
            max = currentSize - 1;
        }
    }

    setRects(getCurrentSize());

    let result;
    let numRect;

    if (!onlyTwoString) {
        numRect = checkRects(rects[0], rects[1], maxWidth, maxHeight);
    } else {
        numRect = checkRect(rects[1], maxWidth, maxHeight) ? 2 : 0;
    }

    if (numRect) {
        result = { fontSize: max, text: texts[numRect - 1] };
    } else {
        result = { fontSize: min, text: getFitText(texts[0], min, maxWidth, maxHeight, styles, charsForWrap) };
    }
    removeDiv();
    cacheResult.set(key, result);
    return result;
};
