<template>
  <footer :class="footerClass">
    <div :class="$style.footerBlock">
      <div :class="$style.logoContainer">
        <div :class="$style.logoWrapper">
          <Logo :class="$style.logo" />
        </div>

        <div :class="$style.logoSeparator" />
      </div>

      <NavigationSection :invest-rules="investRules" />
      <div :class="$style.separator" />
      <ApplicationSection />
      <SupportSection
        :class="$style.supportSection"
        :invest-rules="investRules"
      />
      <SportsPromotionSection :class="$style.sportsPromotionSection" />
      <div :class="$style.separator" />
      <SocialSection />
      <div :class="$style.separator" />
      <PaymentSection />
      <div :class="$style.separator" />
      <LicenseSection
        :class="$style.licenseSection"
        :invest-rules="investRules"
      />
      <AppVersionSection v-if="$options.isMobileApp" />
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/Logo/Logo.vue';
import { FOOTER_LEADERBOARD, ROUTE_META_CUSTOM_FOOTER } from '@/constant/constants';

import ApplicationSection from './FooterSections/ApplicationSection.vue';
import AppVersionSection from './FooterSections/AppVersionSection.vue';
import LicenseSection from './FooterSections/LicenseSection.vue';
import NavigationSection from './FooterSections/NavigationSection.vue';
import PaymentSection from './FooterSections/PaymentSection.vue';
import SocialSection from './FooterSections/SocialSection.vue';
import SportsPromotionSection from './FooterSections/SportsPromotionSection.vue';
import SupportSection from './FooterSections/SupportSection.vue';

export default {
    components: {
        AppVersionSection,
        Logo,
        ApplicationSection,
        SupportSection,
        SportsPromotionSection,
        NavigationSection,
        PaymentSection,
        LicenseSection,
        SocialSection,
    },

    props: {
        isInvest: {
            type: Boolean,
            default: false,
        },

        investRules: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        customFooter() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_FOOTER];
        },

        isLeaderboardPage() {
            return this.customFooter === FOOTER_LEADERBOARD;
        },

        footerClass() {
            return [
                this.$style.footer,
                {
                    [this.$style.investTheme]: this.isInvest,
                    [this.$style.leaderboardTheme]: this.isLeaderboardPage,
                },
            ];
        },
    },

    isMobileApp: process.env.IS_MOBILE_APP,
};
</script>

<style lang="stylus" module>
.separator
    width 100%
    height 1px
    background-color alpha(#5c72a3, .5)
    margin 15px 0

.logoSeparator
    flex 1
    margin-left 30px
    margin-right 15px
    height 1px
    background linear-gradient(90deg, alpha(#5c72a3, .5) 0%, alpha(#5c72a3, 0) 100%)

.footer
    &.investTheme
        background-image linear-gradient(328.61deg, #19105C 38.69%, #100942 71.42%, #0D073A 97.9%)
        mask-image url('~@mobile/assets/images/invest/mask-bg-footer.svg')
        mask-size 100%
        mask-position top
        padding-top 20%
        margin-top -24%

    &.leaderboardTheme
        background-color #000113

.footerBlock
    padding 15px

.logoContainer
    margin-bottom 18px
    display flex
    align-items center

.logoWrapper
    display flex
    align-items center
    justify-content center
    width 40px
    height 16px

    .logo
        display flex
        align-items center
        justify-content center
        min-width 62px

        img
        svg
            height auto !important
            width 62px
            min-width 62px !important
            margin-bottom 3px
            margin-left 7px

.sportsPromotionSection
.licenseSection
.supportSection
    margin-top 15px

.sportsPromotionSection
    padding 0 13px
</style>
