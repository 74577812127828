<template lang="pug">
.banner-carousel
    .carousel-container(:class="{ 'carousel-container_background': !isShowed }")
        FluidCarousel.carousel(
            v-if="isShowed"
            repeat
            :activeSlideIndex.sync="activeSlideIndex"
        )
            figure.slide-container(
                v-for="slide in slides"
                :key="slide.id"
                @click="slideAction(slide)"
            )
                CasinoJackpotPanel.slide.slide-jackpot(
                    v-if="slide.isJackpotSlide"
                )
                UniversalBanner.slide-banner(
                    v-else-if="universalSlideAllowed(slide)"
                    :slide="slide.universal"
                )
                VPicture.slide.slide-banner.image(
                    v-else
                    :picture="slide.imagesByLang"
                    fit="cover"
                )

    .banner-controls
        template(v-if="isShowed")
            .carousel-control(
                v-for="(_, i) in slides"
                :class="{ 'active': i === activeSlideIndex }"
            )
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import VPicture from '@/components/VPicture.vue';
import { LANG_RU } from '@/constant/constants';
import ModalQueryParameters from '@/mixins/ModalQueryParameters';
import { PromiseEnum } from '@/store/promise';

import config from '@config/app.config';
import PokerEnPng from '@mobile/assets/images/srcsets/slides/1win-poker/1winpoker_en_2-min.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320';
import PokerEnWebp from '@mobile/assets/images/srcsets/slides/1win-poker/1winpoker_en_2-min.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320&format=webp';
import PokerRuPng from '@mobile/assets/images/srcsets/slides/1win-poker/1winpoker_ru_2-min.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320';
import PokerRuWebp from '@mobile/assets/images/srcsets/slides/1win-poker/1winpoker_ru_2-min.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320&format=webp';
import SlideBonus500Png from '@mobile/assets/images/srcsets/slides/500_i18_m.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320';
import SlideBonus500Webp from '@mobile/assets/images/srcsets/slides/500_i18_m.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320&format=webp';
import SlideGamesRuPng from '@mobile/assets/images/srcsets/slides/games.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320';
import SlideGamesRuWebp from '@mobile/assets/images/srcsets/slides/games.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320&format=webp';
import SlideIncreaseBonusEnPng from '@mobile/assets/images/srcsets/slides/increase-bonus/increase_bonus_en.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320';
import SlideIncreaseBonusEnWebp from '@mobile/assets/images/srcsets/slides/increase-bonus/increase_bonus_en.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320&format=webp';
import SlideIncreaseBonusRuPng from '@mobile/assets/images/srcsets/slides/increase-bonus/increase_bonus_ru.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320';
import SlideIncreaseBonusRuWebp from '@mobile/assets/images/srcsets/slides/increase-bonus/increase_bonus_ru.png?sizes[]=1024,sizes[]=760,sizes[]=400,sizes[]=320&format=webp';
import FluidCarousel from '@mobile/components/carousels/FluidCarousel.vue';
import CasinoJackpotPanel from '@mobile/components/Casino/CasinoJackpotPanel.vue';
import UniversalBanner from '@mobile/components/UniversalBanner.vue';
import { MODAL_REGISTRATION, MODAL_EXPRESS_BONUS, MODAL_BONUS_DEPOSIT } from '@mobile/constant/modalName';
import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    name: 'Slider',

    components: {
        CasinoJackpotPanel,
        FluidCarousel,
        UniversalBanner,
        VPicture,
    },

    mixins: [ModalQueryParameters],

    inject: ['$modal'],

    props: {
        isCasino: Boolean,
    },

    data() {
        return {
            srcBanners: `${config.staticUrl}/common/banners`,
            activeSlideIndex: 0,
            isShowed: false,
        };
    },

    computed: {
        ...mapGetters('banners', { bannersGetter: 'banners' }),
        ...mapGetters([
            'isAuthed',
            'isSNGCountryLanguage',
            'isPreferCasino',
            'lang',
        ]),
        ...mapState(['locale']),

        bannersType() {
            return this.isCasino ? 'casino' : 'main';
        },

        banners() {
            return Object.values(this.bannersGetter(this.bannersType)).map(
                ({
                    id, areas, name, image, type, universal, ...other
                }) => (type === 'basic' ? {
                    ...other,
                    id,
                    name,
                    src: image,
                    ...areas[0].linkType === 'event' ? {
                        routeProps: areas[0].event,
                    } : {},
                    onClickAction: this.clickActionByArea(areas[0]),
                } : {
                    ...other,
                    type,
                    id,
                    universal,
                    onClickAction: this.clickActionByArea(universal.event),
                }),
            );
        },

        slides() {
            const slides = this.sortedAdditionalSlides
                .map(({ imagesByLang = {}, universal, ...slide }) => {
                    const next = {
                        ...slide,
                        imagesByLang: imagesByLang[this.currentLang] ?? imagesByLang.en ?? {},
                    };

                    if (universal != null) {
                        return {
                            ...next,
                            universal: {
                                ...universal,
                                sport: universal.sport === true && (next.routeProps?.sportId ?? false),
                            },
                        };
                    }

                    return next;
                })
                .filter((slide) => !slide.hideCasino || !this.isCasino);

            if (this.isAuthed) {
                slides.reverse();
            }

            // insert banners from admin panel to its positions
            this.banners.slice().sort((s1, s2) => (s1.position ?? 10000) - (s2.position ?? 10000)).forEach((slide) => {
                slides.splice(slide.position, 0, slide);
            });

            if (this.isPreferCasino) {
                slides.sort((a, b) => (b.isCasino ? 1 : 0) - (a.isCasino ? 1 : 0));
            }

            const forcePushToEndSlides = slides.filter((slide) => slide.forcePushToTheEnd);
            return slides.filter((slide) => !forcePushToEndSlides.includes(slide)).concat(forcePushToEndSlides);
        },

        sortedAdditionalSlides() {
            const slides = [
                {
                    imagesByLang: {
                        en: {
                            png: PokerEnPng,
                            webp: PokerEnWebp,
                        },
                        ru: {
                            png: PokerRuPng,
                            webp: PokerRuWebp,
                        },
                    },
                    id: 'poker',
                    index: 5,
                    hidden: false,
                    hideCasino: true,
                    onClickAction: () => {
                        const name = this.$route.name === ROUTE_NAME.POKER
                            ? ROUTE_NAME.TVBET_POKER
                            : ROUTE_NAME.POKER;

                        this.$router.push({ name });
                    },
                    forcePushToTheEnd: true,
                },
                {
                    universal: {
                        background: {
                            png: SlideBonus500Png,
                            webp: SlideBonus500Webp,
                        },
                        foreground: {
                            png: SlideBonus500Png,
                            webp: SlideBonus500Webp,
                        },
                        heading: this.lang.slider.bonus500.heading,
                        subheading: this.lang.slider.bonus500.subheading,
                    },
                    id: 'bonus',
                    hidden: false,
                    index: 6,
                    onClickAction: () => {
                        this.$modal.open(MODAL_BONUS_DEPOSIT);
                    },
                },
                {
                    imagesByLang: {
                        ru: {
                            png: SlideGamesRuPng,
                            webp: SlideGamesRuWebp,
                        },
                    },
                    id: 'games',
                    isCasino: true,
                    hidden: this.currentLang !== LANG_RU,
                    onClickAction: () => {
                        this.$router.push({ name: ROUTE_NAME.CASINO_GAMES });
                    },
                },
                {
                    imagesByLang: {
                        en: {
                            png: SlideIncreaseBonusEnPng,
                            webp: SlideIncreaseBonusEnWebp,
                        },
                        ru: {
                            png: SlideIncreaseBonusRuPng,
                            webp: SlideIncreaseBonusRuWebp,
                        },
                    },
                    id: 'increaseBonus',
                    hidden: false,
                    hideCasino: true,
                    onClickAction: () => {
                        this.$modal.open(MODAL_EXPRESS_BONUS);
                    },
                },
            ].filter((it) => !it.hidden);

            const order = slides
                .map((it) => it.index)
                .filter((it) => it !== undefined)
                .sort((a, b) => a - b)
                .reduce((acc, curr, index) => ({ ...acc, [curr]: index }), {});

            let previousSpecifiedOrderIndex = Math.max(...Object.values(order));
            const slidesWithNormalizedOrderIndex = slides.map((it) => {
                let index = order[it.index];
                if (order[it.index] == null) {
                    previousSpecifiedOrderIndex += 1;
                    index = previousSpecifiedOrderIndex;
                }

                return { ...it, index };
            });

            return slidesWithNormalizedOrderIndex.sort((a, b) => a.index - b.index);
        },
    },

    watch: {
        locale: 'loadBanners',
    },

    mounted() {
        this.init();
    },

    methods: {
        async loadBanners() {
            await this.$store.dispatch('banners/loadBanners', {
                type: 'mobile',
                bannersType: this.bannersType,
            });
        },

        async init() {
            try {
                await Promise.all([
                    this.$store.getters.promiseByName[PromiseEnum.AUTH],
                    !this.banners.length && this.loadBanners(),
                ]);

                this.isShowed = true;
            } catch (err) {
                console.warn('Error loading banners', err.message);
            }
        },

        redirectToCasinoGame(id) {
            if (!this.isAuthed) this.$modal.open(MODAL_REGISTRATION);
            this.$router.push({
                name: 'casino-game',
                params: { id },
            });
        },

        redirectToCasinoProvider(ownerName) {
            this.$router.push({
                name: ROUTE_NAME.CASINO_PROVIDER,
                params: { ownerName },
            });
        },

        universalSlideAllowed(slide) {
            return this.validUniversalBanner(slide) && !slide.imagesByLang?.[this.currentLang];
        },

        validUniversalBanner(slide) {
            return slide?.universal
                && slide.universal.heading
                && slide.universal.background;
        },

        clickActionByArea(area) {
            switch (area.linkType) {
                case 'link':
                    return async () => {
                        const { link } = area;
                        if (link.match(/^(\/|\?)/)) {
                            await this.$router.push(link).catch(() => { /* noop */ });

                            if (link.includes('modal=')) {
                                this.handleModalQueryParameters();
                            }
                        } else {
                            window.location.href = link;
                        }
                    };
                default:
                    return () => {};
            }
        },

        slideAction(slide) {
            slide.onClickAction();
        },
    },
};
</script>

<style scoped lang="stylus">
.banner-carousel
    --banner-carousel-gap 15px
    --banner-carousel-aspect-ratio-slide 32.464

.carousel-container
    padding-bottom calc((100% - var(--banner-carousel-gap) * 2) * var(--banner-carousel-aspect-ratio-slide) / 100)
    position relative

    &.carousel-container_background::before
        background-color #141b2e
        border-radius 12px
        bottom 0
        content ''
        left var(--banner-carousel-gap)
        position absolute
        right var(--banner-carousel-gap)
        top 0

.carousel
    left 0
    top 0
    position absolute
    width 100%
    height 100%
    padding 0 var(--banner-carousel-gap)

.slide-container
    position relative
    padding-bottom calc(1% * var(--banner-carousel-aspect-ratio-slide))

    .slide
        position absolute
        bottom 0
        left 0
        right 0
        top 0

    .slide-banner
        border-radius $radius-md
        overflow hidden

.banner-controls
    --banner-carousel-size-control 5px
    align-items center
    display flex
    justify-content center
    padding-top $sm
    height var(--banner-carousel-size-control)
    box-sizing content-box

    .carousel-control
        background-color #191e2b
        border-radius $radius-circle
        height var(--banner-carousel-size-control)
        transition background-color .3s ease
        width var(--banner-carousel-size-control)

        &:not(:first-child)
            margin-left $xs

        &.active
            background-color $primary-600
</style>
