<template lang="pug">
#main-layout(
    ref="layout"
    :class=`[
        classObject,
        !!$slots.header && 'has-header',
        !!$slots.tabs && showedBottomNavigation && !!$slots.footer && 'has-tabs',
    ]`
)
    ShowingDividends
    UserNotification
    .safe-area

    slot(name="header")

    transition-group(
        name="main-layout"
        tag="div"
        :class="[wrapperClass, 'wrapper', { 'has-tabs': $slots.tabs && !$slots.footer }]"
    )
        .navigation-top.main-layout-item(
            v-if="$slots.navigation"
            key="navigation"
            :class="navClass"
        )
            slot(name="navigation")

        .search.main-layout-item(v-if="$slots.search" key="search")
            slot(name="search")

        .banner.main-layout-item(v-if="$slots.carousel" key="carousel")
            slot(name="carousel")

        .banner-app.main-layout-item(v-if="$slots.bannerApp && !isPwa && !hideDownloadMobileApp" key="bannerApp")
            slot(name="bannerApp")

        .promotion-links-container.main-layout-item(
            v-if="$slots.promotionLinks"
            key="promotionLinks"
        )
            slot(name="promotionLinks")

        slot(name="layout-body")
            slot.main-layout-item.bets-header(name="betsHeader")
            .body.main-layout-item(key="body" :class="bodyClass")
                slot

    .footer(v-if="$slots.footer" key="footer")
        slot(name="footer")

    .tabs(v-if="$slots.tabs && showedBottomNavigation")
        slot(name="tabs")
</template>

<script>
import { mapGetters } from 'vuex';

import ShowingDividends from '@/components/Abstract/ShowingDividends';
import UserNotification from '@/components/Abstract/UserNotification';
import {
    HEADER_INVEST,
    HEADER_LEADERBOARD,
    NO_STICKY_HEADER,
    REMOVE_LAYOUT_MARGIN, REMOVE_LAYOUT_MARGIN_BOTTOM,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
    META_USE_GAME_RELATED_VIEW,
} from '@/constant/constants';
import { AUTH_REQUIRED } from '@/plugins/AuthRules';

import { MODAL_CASINO_CASHBACK, MODAL_REGISTRATION } from '@mobile/constant/modalName';
import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    components: {
        ShowingDividends,
        UserNotification,
    },

    inject: ['userIntention', '$modal'],

    computed: {
        ...mapGetters([
            'langCommon',
            'socketAlive',
            'hideDownloadMobileApp',
        ]),
        ...mapGetters('layout', [
            'showedBottomNavigation',
        ]),
        classObject() {
            return {
                'has-top-notification': !this.socketAlive,
            };
        },
        isPwa() {
            return this.$platform.pwa.any;
        },
        wrapperClass() {
            return {
                'remove-top-padding': this.$route.meta[REMOVE_LAYOUT_MARGIN],
            };
        },
        bodyClass() {
            return {
                'remove-bottom-margin': this.$route.meta[REMOVE_LAYOUT_MARGIN] || this.$route.meta[REMOVE_LAYOUT_MARGIN_BOTTOM],
                'leaderboard-theme': this.isLeaderboardPage,
                'game-related': this.isGameRelatedView,
            };
        },
        navClass() {
            return {
                'invest-theme': this.isInvestPage,
                'no-sticky': this.$route.meta?.[NO_STICKY_HEADER],
                'leaderboard-theme': this.isLeaderboardPage,
            };
        },
        isInvestPage() {
            return this.customHeader === HEADER_INVEST;
        },

        isLeaderboardPage() {
            return this.customHeader === HEADER_LEADERBOARD;
        },

        customHeader() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_HEADER_BOTTOM];
        },

        isGameRelatedView() {
            return this.$route.meta?.[META_USE_GAME_RELATED_VIEW];
        },
    },

    created() {
        if (this.$route.meta[AUTH_REQUIRED] && !this.isAuthed) {
            this.$modal.open(MODAL_REGISTRATION, {
                onClose: () => {
                    this.$nextTick(() => {
                        if (!this.isAuthed) {
                            this.$router.push({ name: ROUTE_NAME.HOME });
                        }
                    });
                },
            });
        }
    },

    mounted() {
        this.userIntention.resolve();
    },

    methods: {
        onCashbackTimerClick() {
            if (this.$router.currentRoute.name !== ROUTE_NAME.BONUSES) {
                this.$router.push({ name: ROUTE_NAME.BONUSES });
            }
            this.$modal.open(MODAL_CASINO_CASHBACK);
        },
    },
};
</script>

<style lang="stylus" scoped>
.safe-area
    width 100%
    // There were flickering on invest page so I needed to make the thing somewhat bigger
    // (but not make it appear if there is no safe-area-inset so I could not use just pixels) and move it higher a bit
    height calc(env(safe-area-inset-top, 0) * 1.1)
    background-color $base-color-8
    background-color var(--main-background-color, $base-color-8)
    position fixed
    top -1px
    z-index 1000

#main-layout
    min-height 100%
    position relative
    width 100%
    display flex
    flex-direction column
    overflow-x hidden

.has-top-notification
    $height = $layout-default-top-notification-height
    :deep(#header)
        top: 'calc(%s + env(safe-area-inset-top, 0))' % $height
    :deep(.navigation-top)
        top 'calc((var(--layout-header-height) + %s) + env(safe-area-inset-top, 0))' % $height
.has-tabs
    padding-bottom 'calc(%s + env(safe-area-inset-bottom, 0))' % $layout-default-tabs-height

.navigation-top
.search
.banner
    margin-bottom $md

.navigation-top
    margin-top - $md
    position sticky
    top calc(var(--layout-header-height) + env(safe-area-inset-top, 0))
    width 100%
    z-index $layout-navbar-index

    @media (display-mode: standalone)
        top var(--layout-header-height)

    &.invest-theme
        background #0a052c
        background var(--main-background-color, #0a052c)

    &.leaderboard-theme
        background-color #000113
        background var(--main-background-color, #000113)
        margin-bottom 0

    &.no-sticky
        position absolute
        margin-top 0

.tabs
    background-color $dark-900
    bottom 0
    left 0
    padding-bottom 0
    padding-bottom env(safe-area-inset-bottom, 0)
    position fixed
    width 100%
    z-index $layout-tabs-index

.wrapper
    display flex
    flex-direction column
    min-height 100%
    flex-grow 1

    &:not(.remove-top-padding)
        padding-top $layout-default-wrapper-top-padding

.footer
    // padding-bottom $layout-default-tabs-height // support for old
    // padding-bottom 'calc(%s + env(safe-area-inset-bottom, 0))' % $layout-default-tabs-height
    z-index 10

.body
    display flex
    flex-direction column
    flex-grow 1
    position relative
    width 100%

    &.main-layout-item
        min-height 50px

    &:not(.remove-bottom-margin)
        margin-bottom $layout-default-body-bottom-margin

    &.leaderboard-theme
        margin-bottom 0

    &.game-related
        height 'calc(%s - %s)' % (calc(var(--vh, 1vh) * 100) safe-top(0))
        position fixed
        padding-top var(--layout-header-height)
        padding-bottom 'calc(%s + %s)' % (var(--layout-bottom-header-height) safe-bottom(0))

.banner
    position relative
    z-index 1

.promotion-links-container
    margin 8px 13px -2px

.search
    position relative
    z-index 2

.bonus-button
    bottom 150px
</style>
