/** '--vh'
 * variable contains value which equals 'window.innerHeight / 100'
 * getWindowHeight sometimes returns wrong value, it is well known issues,
 * to solve this problem we use timeouts with different delays based on TIMEOUTS_DELAYS_ARRAY
 */
const TIMEOUTS_DELAYS_ARRAY = [10, 30, 90, 270, 600, 1000, 1500, 2100, 3000];
let TIMEOUTS_IDS_ARRAY = [];

function getOrientation() {
    let { orientation } = window;

    if (orientation === undefined) {
        // No JavaScript orientation support. Work it out.
        if (document.documentElement.clientWidth > document.documentElement.clientHeight) {
            orientation = 'landscape';
        } else {
            orientation = 'portrait';
        }
    } else if (orientation === 0 || orientation === 180) {
        orientation = 'portrait';
    } else {
        orientation = 'landscape';
    } // Assumed default, most laptop and PC screens.
    return orientation;
}

function getScale() {
    let screenWidth;
    const {
        innerWidth,
        screen: {
            width,
            height,
        },
    } = window;
    // Get viewport width
    const viewportWidth = document.documentElement.clientWidth;
    // Abort. Screen width is greater than the viewport width (not fullscreen).
    if (width > viewportWidth) {
        console.log('Aborted viewport scale measurement. Screen width > viewport width');
        return 1;
    }
    // Get the orientation corrected screen width
    const orientation = getOrientation();
    if (orientation === 'portrait') {
        screenWidth = Math.min(height, width);
    } else {
        screenWidth = Math.max(height, width);
    }
    // Calculate viewport scale
    return screenWidth / innerWidth;
}

function recalcWindowHeightVariable() {
    TIMEOUTS_IDS_ARRAY.forEach(clearTimeout);
    TIMEOUTS_IDS_ARRAY = [];

    TIMEOUTS_DELAYS_ARRAY.forEach((delay) => {
        const timeoutID = setTimeout(() => {
            const scale = getScale();
            if (scale < 1) return;
            const currentWindowInnerHeight = window.innerHeight * scale;
            document.documentElement.style.setProperty('--vh', `${currentWindowInnerHeight * 0.01}px`);
        }, delay);
        TIMEOUTS_IDS_ARRAY.push(timeoutID);
    });
}

/* -- '--vh' -- */

function onResizeWindowHandler() {
    recalcWindowHeightVariable();
}

function onGestureEndHandler() {
    recalcWindowHeightVariable();
}

function addDynamicCSSListeners() {
    window.addEventListener('resize', onResizeWindowHandler);
    window.addEventListener('orientationchange', onResizeWindowHandler);
    window.addEventListener('gestureend', onGestureEndHandler);
}

function calculateInitialValues() {
    onResizeWindowHandler();
}

let isStarted = false;
export const start = () => {
    if (isStarted) return;

    calculateInitialValues();
    addDynamicCSSListeners();

    isStarted = true;
};
