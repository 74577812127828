<template lang="pug">
LangSelect
    template(#default="{ langs, currentLang, changeLang }")
        .menu-lang-select {{ $filters.capitalize(selectedLangVisualKey) }}
            CountryIcon.menu-lang-select__country-icon(
                :code="currentLang"
                rounded
            )
            VSelectNative.menu-lang-select__lang-input(
                :items="langs"
                :value="currentLang"
                @input="changeLang($event.value)"
            )
</template>

<script>
import CountryIcon from '@/components/CountryIcon.vue';
import LangSelect from '@/components/LangSelect.vue';
import { LangItemsEnum } from '@/constant/constants';

export default {
    components: {
        LangSelect,
        CountryIcon,
    },

    inject: ['$filters'],

    computed: {
        selectedLangVisualKey() {
            return LangItemsEnum.getItemByOneWinKey(this.currentLang).visualKey;
        },
    },
};
</script>

<style scoped lang="stylus">
.menu-lang-select
    display flex
    align-items center
    justify-content center
    padding 7px 12px
    border-radius $radius-sm
    background rgba(151, 174, 225, 0.11)
    text-shadow 0 3px 6px rgba(0, 0, 0, 0.2)
    font-size 13px
    margin-left 10px
    min-width 18px
    overflow hidden
    position relative
    flex-shrink 0
    height 29px
    width 60px

.menu-lang-select__country-icon
    height 12px
    width 12px
    margin-left 8px
    flex-shrink 0

.menu-lang-select__lang-input
    height 100%
    left 0
    opacity 0
    position absolute
    top 0
    width 100%
</style>
