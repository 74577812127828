<template lang="pug">
div
    .menu__list(key="list")
        Draggable(
            v-model="actualNavigationItems"
            :forceFallback="true"
            :supportPointer="false"
            handle=".menu__list-item-drag-icon"
        )
            TransitionGroup(name="flip-list")
                // Есть некоторые ограничения по разметке айтемов; если что-то поменял и сломалось – читай доку
                // https://github.com/SortableJS/Vue.Draggable#gotchas
                .menu__list-item(
                    v-for="(item, i) in actualNavigationItems"
                    :key="item.name"
                    @click="onClickListItem(item)"
                )
                    .menu__list-item-divider(v-if="item.IS_DIVIDER")
                        VIcon.menu__list-item-drag-icon(
                            v-if="editMode"
                            icon="burger_item_handle"
                        )
                    template(v-else)
                        .menu__list-item-content
                            VIcon.menu__list-item-icon(
                                v-if="item.icon"
                                :icon="item.icon"
                                :class="{ 'animate': editMode }"
                                :style="item.animationStyles.icon"
                            )
                            .menu__list-item-icon-placeholder(
                                v-else
                            )
                            img.menu__list-item-name-image(
                                v-if="item.image"
                                :src="item.image"
                            )
                            span.menu__list-item-name(
                                v-else
                                :class="{ 'animate': editMode }"
                                :style="item.animationStyles.label"
                            ) {{ navigationText[item.name] || $filters.locale(item.lang) }}
                        VIcon.menu__list-item-drag-icon(
                            v-if="editMode"
                            icon="burger_item_handle"
                        )
    .menu__edit(
        v-show="editMode"
        key="edit"
    )
        VButton.menu__edit-save-button(
            theme="primary"
            @click="saveMenuClickHandler"
        ) {{ text.save }}
        .menu__edit-cancel
            VButton.menu__text-button(
                theme="text"
                @click="cancelEditModeClickHandler"
            ) {{ text.cancel }}
            VButton.menu__text-button(
                theme="text"
                @click="resetEditModeClickHandler"
            ) {{ text.reset }}

    .menu__edit(
        v-show="!editMode"
        key="notEdit"
    )
        VButton.menu__text-button(
            theme="text"
            @click="enterEditModeClickHandler"
        ) {{ text.edit }}
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';

import { getMainNavigationMenuOrderMap } from '@/utils/navigation';
import { generateRandomFloat } from '@/utils/number';

export default {
    name: 'MenuItemsList',

    components: {
        Draggable,
    },

    inject: ['$filters'],

    props: {
        burgerMenuIsOpened: Boolean,
    },

    data() {
        return {
            actualNavigationItems: null,
            isEditMode: false,
        };
    },

    computed: {
        ...mapGetters({
            lang: 'lang',
            isAllowedTv: 'tv/isAllowedTv',
            isAllowedInvest: 'invest/isAllowedInvest',
            isAuthed: 'isAuthed',
            socket: 'socket',
            defaultOrderedBurgerMenuItems: 'navigationMenu/burgerMenuItems',
            userOrderedBurgerMenuItems: 'navigationMenu/orderedBurgerMenuItems',
        }),

        editMode: {
            get() {
                return this.isEditMode;
            },
            set(val) {
                this.isEditMode = val;
                this.$emit('update:editMode', val);
            },
        },
        text() {
            return this.lang.burgerMenu;
        },
        navigationText() {
            return this.lang.navigation;
        },
    },

    created() {
        this.setActualNavigationItems(this.userOrderedBurgerMenuItems);
    },

    methods: {
        ...mapActions('navigationMenu', [
            'saveMainNavigationMenuOrderMap',
        ]),

        setActualNavigationItems(items) {
            this.actualNavigationItems = items.map(
                (item) => {
                    const iconDuration = generateRandomFloat(0.2, 0.55);
                    const iconDelay = generateRandomFloat(0.3, 0.8);
                    const labelDuration = generateRandomFloat(0.2, 0.35);
                    const labelDelay = generateRandomFloat(0.1, 0.6);

                    return {
                        ...item,
                        animationStyles: {
                            icon: `animation-duration: ${iconDuration}s; animation-delay: -${iconDelay}s`,
                            label: `animation-duration: ${labelDuration}s; animation-delay: -${labelDelay}s`,
                        },
                    };
                },
            );
        },

        onClickListItem({ route }) {
            if (this.editMode) return;

            if (route) {
                this.$router.push(route);
                this.$burgerMenu.close();
            }
        },
        exitEditMode() {
            this.editMode = false;
        },
        enterEditModeClickHandler() {
            this.setActualNavigationItems(this.userOrderedBurgerMenuItems.slice());
            this.editMode = true;
        },
        async saveMenuClickHandler() {
            await this.saveMainNavigationMenuOrderMap(getMainNavigationMenuOrderMap(this.actualNavigationItems));
            this.setActualNavigationItems(this.userOrderedBurgerMenuItems);
            this.exitEditMode();
        },
        cancelEditModeClickHandler() {
            this.setActualNavigationItems(this.userOrderedBurgerMenuItems);
            this.exitEditMode();
        },
        resetEditModeClickHandler() {
            this.setActualNavigationItems(this.defaultOrderedBurgerMenuItems.slice());
        },
    },
};
</script>

<style scoped lang="stylus">
.menu__list
    margin-bottom 15px

.flip-list-move
    transition transform 0.5s

.menu__list-item
    display flex
    align-items center
    justify-content space-between
    padding 9px 0
    will-change opacity
    user-select none

    &.active .menu__list-item-drag-icon
        color alpha(#94a6cd, 1)

.menu__list-item-content
    display flex
    align-items center
    font-size 14px
    font-weight 600

    .menu__list-item-name-image
        height 14px

    &:nth-child(2n)
        .menu__list-item-icon.animate
            transform-origin 50% 10%
            animation-name jiggle1
            animation-iteration-count infinite
            animation-duration .33s

        .menu__list-item-name.animate
            transform-origin 30% 5%
            animation-name jiggle2
            animation-iteration-count infinite
            animation-duration .33s

    &:nth-child(2n-1)
        .menu__list-item-icon.animate
            transform-origin 30% 5%
            animation-name jiggle2
            animation-iteration-count infinite
            animation-duration .33s

        .menu__list-item-name.animate
            transform-origin 50% 10%
            animation-name jiggle1
            animation-iteration-count infinite
            animation-duration .33s

.menu__list-item-icon,
.menu__list-item-icon-placeholder
    width 25px
    height 25px
    margin-right 15px
    will-change transform

.menu__list-item-drag-icon
    margin-left 10px
    color alpha(#94a6cd, .2)
    transition color .2
    will-change transform

    &:active
        color alpha(#94a6cd, 1)

.menu__list-item-divider
    height 1px
    width 100%
    position relative
    padding 10px 0

    &::after
        content ''
        display block
        width 90%
        height 1px
        background linear-gradient(to right, alpha(#d3deff, 0.2), rgba(235, 241, 255, 0))
        position absolute
        top 50%
        left 0

    .menu__list-item-drag-icon
        position absolute
        right 0
        top calc(50% - 8px)

.sortable-ghost
    opacity 0.5

.sortable-chosen .menu__list-item-drag-icon
    color alpha(#94a6cd, 1)

.sortable-drag
    display none !important
    opacity 0 !important

.menu__edit-save-button
    width 100%
    margin-bottom 15px

.menu__edit
    color alpha(#94a6cd, .8)

.menu__edit-cancel
    display flex
    justify-content space-between

.menu__text-button
    color alpha(#94a6cd, .8)
</style>
