<template>
  <div
    v-if="socialList.length"
    ref="socialContainer"
    :class="$style.social"
  >
    <div :class="$style.socialTitle">
      {{ $store.getters.lang.Footer.social.title }}
    </div>
    <div :class="$style.socialButtons">
      <SocialItemWrapper
        v-for="socialItem in socialList"
        :key="socialItem.social"
        :social="socialItem.social"
      >
        <template #default="{ href, gradientClassName, iconName, text }">
          <a
            rel="noopener"
            target="_blank"
            :class="[$style.socialLink, gradientClassName]"
            :href="href"
          >
            <VIcon
              :icon="`social/${iconName}`"
              :size="socialItem.height || 18"
            />
            <div
              v-if="!shortButton"
              :class="$style.socialText"
            >
              {{ text }}
            </div>
          </a>
        </template>
      </SocialItemWrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SocialItemWrapper from '@/components/SocialItemWrapper.vue';
import SocialsEnum from '@/constant/SocialsEnum';

export default {
    name: 'SocialSection',

    components: {
        SocialItemWrapper,
    },

    data: () => ({
        socialContainerWidth: 0,
    }),

    computed: {
        ...mapGetters([
            'socialsWithLinks',
        ]),

        socialList() {
            const overrides = {
                [SocialsEnum.YOUTUBE]: {
                    height: 15,
                },
                [SocialsEnum.VKONTAKTE]: {
                    height: 36,
                },
            };

            return this.socialsWithLinks
                .map((social) => ({
                    social,
                    ...overrides[social],
                }));
        },

        shortButton() {
            const minWidthButton = 90;
            const widthMargin = 15;
            const widthMargins = (this.socialList.length - 1) * widthMargin;
            const minWidthButtons = minWidthButton * this.socialList.length;
            const minWidthButtonsContainer = minWidthButtons + widthMargins;

            return this.socialContainerWidth < minWidthButtonsContainer;
        },
    },

    mounted() {
        window.addEventListener('resize', this.setContainerWidth, {
            passive: true,
        });
        this.setContainerWidth();
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.setContainerWidth, {
            passive: true,
        });
    },

    methods: {
        setContainerWidth() {
            this.socialContainerWidth = this.$refs.socialContainer?.offsetWidth ?? 0;
        },
    },
};
</script>

<style lang="stylus" module>
.social
.socialButtons
    display flex

.social
    flex-direction column
    align-items center
    width 100%

.socialButtons
    min-width 100%

.hidden
    visibility hidden
    position relative
    margin-top -35px

.socialTitle
    margin-bottom 15px
    font-weight 600
    font-size 10px
    line-height 13px
    color alpha($dark-10, .5)

.socialLink
    display flex
    align-items center
    justify-content center
    height 35px
    border-radius 8px
    padding 0 5px
    flex 1

    &:not(:first-child)
        margin-left 9px

.socialText
    font-weight 600
    font-size 10px
    line-height 13px
    margin 0 5px 0 5px
</style>
