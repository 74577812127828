<template lang="pug">
div(:class="$style.container")
    VIcon(
        v-for="payment in $options.payments"
        :key="payment.iconName"
        :size="15"
        :class="$style.icon"
        :icon="`payment-full/${payment.iconName}`"
        @click="onButtonClick(payment.paymentMethodShortValue)"
    )
</template>

<script>
import { FOOTER_PAYMENTS, FOOTER_PAYMENTS_EXTENSION_2 } from '@/constant/constants';

import { MODAL_REGISTRATION } from '@mobile/constant/modalName';
import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    name: 'PaymentSection',

    inject: ['$modal'],

    methods: {
        onButtonClick(paymentDefaultAbbreviation) {
            if (!this.isAuthed) {
                this.$modal.open(MODAL_REGISTRATION, {
                    closeOnBackgroundClick: false,
                    onClose: () => {
                        if (this.isAuthed) {
                            this.$router.push({
                                name: ROUTE_NAME.WALLET_DEPOSIT,
                                params: { paymentDefaultAbbreviation },
                            });
                        }
                    },
                });

                return;
            }

            this.$router.push({ name: ROUTE_NAME.WALLET_DEPOSIT, params: { paymentDefaultAbbreviation } });
        },
    },

    payments: [...FOOTER_PAYMENTS, ...FOOTER_PAYMENTS_EXTENSION_2],
};
</script>

<style lang="stylus" module>
.container
    display flex
    align-items center
    justify-content center
    flex-wrap wrap
    margin 0 -15px -15px

.icon
    cursor pointer
    margin 0 16px 16px

    [class$='color']
        opacity 0
        transition opacity .2s ease

    [class$='mono']
        opacity 1
        transition opacity .2s ease

    &:active
    &:hover
        [class$='color']
            opacity 1

        [class$='mono']
            opacity 0
</style>
