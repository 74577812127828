<template lang="pug">
div(:class="fixed ? $style.fixed : $style.root")
    VButton(
        square-size="20px"
        theme="grey"
        :class="$style.closeButton"
        @click="hideApplicationPromo"
    )
        VIcon(
            icon="close"
            :class="$style.closeIcon"
        )

    VIcon(
        icon="app/promoIcon"
        :class="$style.promoIcon"
    )

    div(:class="$style.description")
        div(:class="$style.descriptionTitle") {{ title }}
        div(:class="$style.descriptionSubtitle") {{ text.subtitle }}
        div(:class="$style.descriptionStars")
            VIcon(
                v-for="i in 5"
                :key="i"
                icon="star"
                :class="$style.starIcon"
            )
    div(:class="$style.installButtonContainer")
        PWAInstall(
            :mobile-app="$platform.mobile.android.any"
            @click="hideApplicationPromo"
        )
            VButton(
                :class="$style.installButton"
                theme="primary-flat"
            ) {{ text.install }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import PWAInstall from '@mobile/elements/PWAInstall';

export default {
    name: 'MobileAppPromoBlock',

    components: {
        PWAInstall,
    },

    props: {
        fixed: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapGetters(['langMobile']),

        text() {
            return this.langMobile.appPromoBanner;
        },

        title() {
            return (
                this.$platform.pwa.any && this.$platform.mobile.android.any
                    ? this.text.androidPwaTitle
                    : this.text.title
            );
        },
    },
    methods: {
        ...mapActions('layout', ['hideApplicationPromo']),
    },
};
</script>

<style lang="stylus" module>
.root
    height $layout-app-promo-height
    display flex
    align-items center
    padding 10px 15px
    background #fff

.closeButton
    flex-shrink 0

.fixed
    composes root
    position fixed
    left 0
    right 0
    top 0
    top safe-top(0)

.description
    flex-grow 1
    margin-left 10px
    margin-right 15px
    max-width 100px

.descriptionTitle
    line-height 14.32px
    font-size 12px
    color #090F1E
    white-space pre-line

.descriptionSubtitle
    color alpha(#090F1E, .56)
    font-size 9px
    line-height 10.74px
    white-space pre-line

.descriptionStars
    display flex
    margin-top auto

.promoIcon
    width 55px
    font-size 55px !important
    margin-left 15px

.starIcon
    font-size 12px !important
    width 12px
    color #F7B500

    &:not(:last-child)
        margin-right 4px

.closeIcon
    font-size 6px !important
    color #5C72A3

.installButtonContainer
    margin-left auto

.installButton
    min-height 30px !important
    border-radius 20px !important
    line-height 12px
    font-size 11px
</style>
