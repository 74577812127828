import { GUARD_SHOW_VERIFY } from '@/constant/constants';
import { ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS } from '@/constant/rules';
import { AUTH } from '@/plugins/AuthRules';

import * as MODAL_NAME from '@mobile/constant/modalName';

export default {
    [MODAL_NAME.MODAL_LOGIN]: {
        component: () => import('@mobile/modals/LoginModal.vue'),
        meta: {
            [AUTH]: false,
        },
    },
    [MODAL_NAME.MODAL_NOT_LOGGED]: {
        component: () => import('@mobile/modals/NotLoggedModal.vue'),
        meta: {
            [AUTH]: false,
        },
    },
    [MODAL_NAME.MODAL_NOT_ENOUGH_MONEY]: {
        component: () => import('@mobile/modals/NotEnoughMoneyModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_SUCCESS_REGISTRATION]: {
        component: () => import('@mobile/modals/SuccessRegistrationModal.vue'),
    },
    [MODAL_NAME.MODAL_REGISTRATION]: {
        component: () => import('@mobile/modals/RegistrationABModal.vue'),
        meta: {
            [AUTH]: false,
            [ROUTE_META_ALLOW_REGISTER_FROM_PARTNERS]: true,
        },
    },
    [MODAL_NAME.MODAL_BONUS_EXPRESS]: {
        component: () => import('@mobile/modals/BonusExpressModal.vue'),
    },
    [MODAL_NAME.MODAL_BONUS_PROFILE]: {
        component: () => import('@mobile/modals/BonusProfileModal.vue'),
    },
    [MODAL_NAME.MODAL_DEPOSIT]: {
        component: () => import('@mobile/modals/DepositModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_WITHDRAWAL_BLOCKED]: {
        component: () => import('@mobile/modals/WithdrawalBlockedModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_WITHDRAWAL_CREATED]: {
        component: () => import('@mobile/modals/WithdrawalCreatedModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_CASE_WIN]: {
        component: () => import('@mobile/modals/CaseWinModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_WALLET_ACTION]: {
        component: () => import('@mobile/modals/WalletActionModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_WALLET_SELECT]: {
        component: () => import('@mobile/modals/WalletSelectModal.vue'),
    },
    [MODAL_NAME.MODAL_WALLET_LOGIN]: {
        component: () => import('@mobile/modals/WalletSelectModalUser.vue'),
    },
    [MODAL_NAME.MODAL_DATE_PICKER]: {
        component: () => import('@mobile/modals/DatePickerModal.vue'),
    },
    [MODAL_NAME.MODAL_VERIFICATION]: {
        component: () => import('@mobile/modals/VerificationModal.vue'),
        meta: {
            [AUTH]: true,
            [GUARD_SHOW_VERIFY]: true,
        },
    },
    [MODAL_NAME.MODAL_VIP_DOMAIN]: {
        component: () => import('@mobile/modals/VipDomainModal.vue'),
    },
    [MODAL_NAME.MODAL_BETS_SALE]: {
        component: () => import('@mobile/modals/BetsSaleModal.vue'),
    },
    [MODAL_NAME.MODAL_TV_FILTER]: {
        component: () => import('@mobile/modals/TvFilterModal.vue'),
    },
    [MODAL_NAME.MODAL_QUALITY_SELECT]: {
        component: () => import('@mobile/modals/QualitySelectModal.vue'),
    },
    [MODAL_NAME.MODAL_CASES_THEME]: {
        component: () => import('@mobile/modals/CasesThemeModal.vue'),
    },
    [MODAL_NAME.MODAL_PROMO_CODE]: {
        component: () => import('@mobile/modals/PromoCodeModal.vue'),
    },
    [MODAL_NAME.MODAL_POKER_CHANGE_NICKNAME]: {
        [AUTH]: true,
        component: () => import('@mobile/modals/ChangePokerNicknameModal.vue'),
    },
    [MODAL_NAME.CASINO_MULTI_CURRENCY_ERROR_MODAL]: {
        component: () => import('@mobile/modals/CasinoMultiCurrencyErrorModal.vue'),
    },
    [MODAL_NAME.SOCIAL_AUTH_TELEGRAM_CONFIRMATION]: {
        component: () => import('@mobile/modals/SocialAuthTelegramConfirmationModal.vue'),
    },
    [MODAL_NAME.INVEST_MODAL]: {
        component: () => import('@mobile/modals/Invest/InvestModal.vue'),
    },
    [MODAL_NAME.INVEST_DIVIDENDS_MODAL]: {
        component: () => import('@mobile/modals/Invest/InvestModalDividends.vue'),
    },
    [MODAL_NAME.INVEST_DONE_MODAL]: {
        component: () => import('@mobile/modals/Invest/InvestModalDone.vue'),
    },
    [MODAL_NAME.INVEST_ERROR_MODAL]: {
        component: () => import('@mobile/modals/Invest/InvestModalError.vue'),
    },
    [MODAL_NAME.MODAL_DEPOSIT_SUCCESS]: {
        component: () => import('@mobile/modals/DepositSuccessModal.vue'),
    },
    [MODAL_NAME.MODAL_INFO_MESSAGE]: {
        component: () => import('@mobile/modals/InfoMessageModal.vue'),
    },
    [MODAL_NAME.MODAL_CASINO_CASHBACK]: {
        component: () => import('@mobile/modals/Bonuses/CasinoCashbackModal.vue'),
    },
    [MODAL_NAME.MODAL_BONUS_DEPOSIT]: {
        component: () => import('@mobile/modals/Bonuses/DepositBonusModal.vue'),
    },
    [MODAL_NAME.MODAL_EXPRESS_BONUS]: {
        component: () => import('@mobile/modals/Bonuses/ExpressBonusModal.vue'),
    },
    [MODAL_NAME.MODAL_LEADERBOARD_BONUS]: {
        component: () => import('@mobile/modals/Bonuses/LeaderboardBonusModal.vue'),
    },
    [MODAL_NAME.MODAL_BONUS_UNIVERSAL]: {
        component: () => import('@mobile/modals/Bonuses/UniversalBonusModal.vue'),
    },
    [MODAL_NAME.MODAL_PAYMENT_INSTRUCTION]: {
        component: () => import('@mobile/modals/PaymentInstructionModal.vue'),
        [AUTH]: true,
    },
    [MODAL_NAME.MODAL_DEPOSIT_ERROR]: {
        component: () => import('@mobile/modals/DepositErrorModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_DEPOSIT_MESSAGE_ERROR]: {
        component: () => import('@mobile/modals/DepositMessageErrorModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_APPLICATION_DEPLOYMENT_MANAGER]: {
        component: () => import('@mobile/modals/ApplicationDeploymentManagerModal.vue'),
    },
    [MODAL_NAME.MODAL_LOCALE]: {
        component: () => import('@mobile/modals/LocaleModal.vue'),
    },
    [MODAL_NAME.MODAL_DEPOSIT_INDIA_INSTRUCTION]: {
        component: () => import('@mobile/modals/DepositIndiaInstructionModal.vue'),
    },
    [MODAL_NAME.MODAL_LOYALTY_EXCHANGE]: {
        component: () => import('@mobile/modals/LoyaltyExchangeModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_CANCEL_FREESPINS]: {
        component: () => import('@mobile/modals/CancelFreespinsModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
    [MODAL_NAME.MODAL_COUNTRY_SELECT]: {
        component: () => import('@mobile/modals/CountrySelectModal.vue'),
        meta: {
            [AUTH]: true,
        },
    },
};
