import isEqual from 'lodash.isequal';

import aviatorGameLogo from '@/assets/images/aviator-game-logo.svg';
import { SERVICE_LIVE, SERVICE_PREMATCH } from '@/constant/constants';
import {
    convertArrayToNavigationItemsOrderMap,
    convertNavigationItemsOrderMapToArray,
    getMainNavigationMenuOrderFromLocalStorage,
    getTopNavigationItemsSortFunction,
    NAVIGATION_ITEM_HARDCODED_SORTING_KEY,
    NAVIGATION_ITEM_HARDCODED_SORTING_VALUE_HOME,
    NAVIGATION_ITEM_SORTING_KEY,
    NAVIGATION_ITEMS_SORTING_VALUES,
    saveMainNavigationMenuOrderMapInLocalStorage,
} from '@/utils/navigation';

import ROUTE_NAME from '@mobile/constant/routeName';
import { BURGER_NAVIGATION_ROUTE_NAMES, injectBurgerDividers } from '@mobile/utils/burger';

const isPojo = (value) => typeof value === 'object' && value !== null;

const checkEqualFirstValue = (val1, val2) => {
    if (isPojo(val1) && isPojo(val2)) {
        return (
            Object
                .entries(val1)
                .every(([key, value]) => checkEqualFirstValue(value, val2[key]))
        );
    }

    return val1 === val2;
};

const excludedTabCurrent = [ROUTE_NAME.CASINO_GAME];

const badgeNewObj = {
    text: 'NEW',
    background: 'linear-gradient(-260deg, #7F66D2 0%, #5484F2 100%)',
};

const sortTopForPreferCasino = [
    ROUTE_NAME.HOME,
    ROUTE_NAME.CASINO,
    ROUTE_NAME.CASINO_LIVE,
    ROUTE_NAME.BETS_LIVE,
    ROUTE_NAME.BETS_PREMATCH,
    ROUTE_NAME.CASINO_GAMES,
    ROUTE_NAME.TVBET,
    ROUTE_NAME.INVEST,
    ROUTE_NAME.VIRTUAL_SPORT,
    ROUTE_NAME.POKER,
    ROUTE_NAME.CASES,
    ROUTE_NAME.CASINO_GAME_AVIATOR,
    ROUTE_NAME.TV,
    ROUTE_NAME.STATS,
];

const sortTopForPreferInvest = [
    ROUTE_NAME.HOME,
    ROUTE_NAME.INVEST,
];

const excludedRoutesFromTopNav = [
    ROUTE_NAME.BONUSES,
];

const sortNavList = (preferList, navList) => navList
    .slice()
    .sort((a, b) => {
        const [indexA, indexB] = [a, b].map(({ name }) => preferList.indexOf(name));
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    });

export default {
    namespaced: true,

    getters: {
        topNavigationMenuItemsOrderArray: (_, __, ___, rootGetters) => (
            rootGetters.user?.hamburger_menu
                ?? getMainNavigationMenuOrderFromLocalStorage()
        ),
        topNavigationMenuItemsOrderMap: (_, getters) => convertArrayToNavigationItemsOrderMap(
            getters.topNavigationMenuItemsOrderArray,
        ),
        allTopNavigationMenuItemsArr: (state, getters, rootState, rootGetters) => [
            {
                name: ROUTE_NAME.HOME,
                route: { name: ROUTE_NAME.HOME },
                routeActive: [ROUTE_NAME.HOME],
                hidden: rootGetters.isReducedFunctionalityInvest,
                icon: 'home/home',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.HOME,
                [NAVIGATION_ITEM_HARDCODED_SORTING_KEY]: NAVIGATION_ITEM_HARDCODED_SORTING_VALUE_HOME,
            },
            {
                name: ROUTE_NAME.BETS_LIVE,
                route: {
                    name: ROUTE_NAME.BETS,
                    params: {
                        service: SERVICE_LIVE,
                    },
                    query: {
                        tab: 'events',
                    },
                },
                routeActive: [
                    ROUTE_NAME.BETS_LIVE,
                    ROUTE_NAME.BETS_VSPORT,
                    { name: ROUTE_NAME.BETS, params: { service: SERVICE_LIVE } },
                    { name: ROUTE_NAME.BETS_SPORT, params: { service: SERVICE_LIVE } },
                    { name: ROUTE_NAME.BETS_TOURNAMENT, params: { service: SERVICE_LIVE } },
                    { name: ROUTE_NAME.BETS_MATCH, params: { service: SERVICE_LIVE } },
                ],
                hidden: rootGetters.isReducedFunctionalityInvest,
                icon: 'home/live',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LIVE,
            },
            {
                name: ROUTE_NAME.BETS_PREMATCH,
                route: {
                    name: ROUTE_NAME.BETS,
                    params: {
                        service: SERVICE_PREMATCH,
                    },
                    query: {
                        tab: 'sports',
                    },
                },
                routeActive: [
                    ROUTE_NAME.BETS_PREMATCH,
                    { name: ROUTE_NAME.BETS, params: { service: SERVICE_PREMATCH } },
                    { name: ROUTE_NAME.BETS_SPORT, params: { service: SERVICE_PREMATCH } },
                    { name: ROUTE_NAME.BETS_TOURNAMENT, params: { service: SERVICE_PREMATCH } },
                    { name: ROUTE_NAME.BETS_MATCH, params: { service: SERVICE_PREMATCH } },
                    ROUTE_NAME.BETS_CUSTOM,
                    ROUTE_NAME.BETS_CUSTOM_EVENT,
                    ROUTE_NAME.BETS_OUTRIGHTS_SPORT,
                ],
                hidden: rootGetters.isReducedFunctionalityInvest,
                icon: 'home/line',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LINE,
            },
            {
                name: ROUTE_NAME.CASINO,
                route: { name: ROUTE_NAME.CASINO },
                routeActive: [ROUTE_NAME.CASINO],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/casino',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.CASINO,
            },
            {
                name: ROUTE_NAME.CASINO_LIVE,
                route: { name: ROUTE_NAME.CASINO_LIVE },
                routeActive: [ROUTE_NAME.CASINO_LIVE],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/livegames',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LIVE_GAMES,
            },
            {
                name: ROUTE_NAME.BETS_RESULT,
                route: { name: ROUTE_NAME.BETS_RESULT },
                routeActive: [
                    ROUTE_NAME.BETS_RESULT,
                    ROUTE_NAME.BETS_RESULT_TOURNAMENT,
                    ROUTE_NAME.BETS_RESULT_SPORT,
                    ROUTE_NAME.BETS_RESULT_LAST,
                ],
                hidden: rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.STATS,
                route: { name: ROUTE_NAME.STATS },
                routeActive: [
                    ROUTE_NAME.STATS,
                ],
                hidden: rootGetters.isReducedFunctionalityInvest,
                icon: 'home/stats',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.STATISTICS,
            },
            {
                name: ROUTE_NAME.BETS_CYBERSPORT,
                route: { name: ROUTE_NAME.BETS_CYBERSPORT },
                routeActive: [
                    ROUTE_NAME.BETS_CYBERSPORT,
                    ROUTE_NAME.BETS_CYBERSPORT_SPORT,
                    ROUTE_NAME.BETS_CYBERSPORT_TOURNAMENT,
                    ROUTE_NAME.BETS_CYBERSPORT_MATCH,
                ],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/e-sport',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.CYBERSPORT,
            },
            {
                name: ROUTE_NAME.CASINO_GAME_FANTASY_SPORT,
                route: { name: ROUTE_NAME.CASINO_GAME_FANTASY_SPORT },
                routeActive: [ROUTE_NAME.CASINO_GAME_FANTASY_SPORT],
                lang: { en: 'Fantasy Sport', ru: 'Fantasy Sport' },
                icon: 'home/fantasy-sport',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.FANTASY_SPORT,
            },
            {
                name: ROUTE_NAME.CASINO_GAME_AVIATOR,
                image: aviatorGameLogo,
                route: { name: ROUTE_NAME.CASINO_GAME_AVIATOR },
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/aviator',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.AVIATOR,
            },
            {
                name: ROUTE_NAME.CASINO_GAMES_ONE_WIN,
                route: { name: ROUTE_NAME.CASINO_GAMES_ONE_WIN },
                routeActive: [ROUTE_NAME.CASINO_GAMES_ONE_WIN],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/1win',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.GAMES_ONE_WIN,
            },
            {
                name: ROUTE_NAME.BETGAMES,
                route: { name: ROUTE_NAME.BETGAMES },
                routeActive: [ROUTE_NAME.BETGAMES],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/betgames',
                badge: badgeNewObj,
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.BETGAMES,
            },
            {
                name: ROUTE_NAME.TVBET,
                route: { name: ROUTE_NAME.TVBET },
                routeActive: [ROUTE_NAME.TVBET],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/tvbet',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.TVBET,
            },
            {
                name: ROUTE_NAME.CASINO_GAMES,
                route: { name: ROUTE_NAME.CASINO_GAMES },
                routeActive: [ROUTE_NAME.CASINO_GAMES],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/games',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.GAMES,
            },
            {
                name: ROUTE_NAME.VIRTUAL_SPORT,
                route: { name: ROUTE_NAME.VIRTUAL_SPORT },
                routeActive: [ROUTE_NAME.VIRTUAL_SPORT],
                hidden: rootGetters.isReducedFunctionalityInvest,
                icon: 'home/v-sport',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.VSPORT,
            },
            {
                name: ROUTE_NAME.BONUSES,
                route: { name: ROUTE_NAME.BONUSES },
                routeActive: [ROUTE_NAME.BONUSES],
                icon: 'home/bonuses-m',
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.BONUSES,
            },
            {
                name: ROUTE_NAME.TV,
                route: { name: ROUTE_NAME.TV },
                lang: { en: '1win Tv', ru: '1win Tv' },
                routeActive: [ROUTE_NAME.TV],
                hidden: !rootGetters['tv/isAllowedTv'] || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/tv',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.TV,
            },
            {
                name: ROUTE_NAME.POKER,
                route: { name: ROUTE_NAME.POKER },
                routeActive: [ROUTE_NAME.POKER],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                icon: 'home/poker',
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.POKER,
            },
            {
                name: ROUTE_NAME.LEADERBOARD,
                route: { name: ROUTE_NAME.LEADERBOARD },
                routeActive: [
                    ROUTE_NAME.LEADERBOARD,
                ],
                icon: 'home/leaderboard',
                hidden: rootGetters.isReducedFunctionalityBetsOnly
                    || rootGetters.isReducedFunctionalityInvest
                    || !rootGetters['permissions/isTester'],
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.LEADERBOARDS,
            },
            {
                name: ROUTE_NAME.INVEST,
                route: { name: ROUTE_NAME.INVEST },
                lang: { en: '1win invest', ru: '1win invest' },
                routeActive: [ROUTE_NAME.INVEST],
                hidden: !rootGetters['invest/isAllowedInvest'],
                icon: 'home/invest',
                scrollTop: true,
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.INVEST,
            },
            {
                name: ROUTE_NAME.INVEST,
                route: { name: ROUTE_NAME.INVEST, hash: '#how-it-works' },
                lang: { ru: 'Как это работает' },
                routeActive: [ROUTE_NAME.INVEST],
                hidden: !rootGetters['invest/isAllowedInvest'] || !rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.INVEST,
                route: { name: ROUTE_NAME.INVEST, hash: '#for-what' },
                lang: { ru: 'Куда идут инвестиции' },
                routeActive: [ROUTE_NAME.INVEST],
                hidden: !rootGetters['invest/isAllowedInvest'] || !rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.INVEST,
                route: { name: ROUTE_NAME.INVEST, hash: '#about' },
                lang: { ru: 'О компании' },
                routeActive: [ROUTE_NAME.INVEST],
                hidden: !rootGetters['invest/isAllowedInvest'] || !rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.INVEST,
                route: { name: ROUTE_NAME.INVEST, hash: '#faq' },
                lang: { ru: 'FAQ' },
                routeActive: [ROUTE_NAME.INVEST],
                hidden: !rootGetters['invest/isAllowedInvest'] || !rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.INVEST,
                route: { name: ROUTE_NAME.INVEST, hash: '#contacts' },
                lang: { ru: 'Контакты' },
                routeActive: [ROUTE_NAME.INVEST],
                hidden: !rootGetters['invest/isAllowedInvest'] || !rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.CASES,
                route: { name: ROUTE_NAME.CASES },
                routeActive: [
                    ROUTE_NAME.CASES,
                    ROUTE_NAME.CASES_LIST,
                    ROUTE_NAME.CASE,
                ],
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.CASES,
            },
            {
                name: ROUTE_NAME.TV_NEW_EPISODES,
                route: { name: ROUTE_NAME.TV_NEW_EPISODES },
                lang: { en: 'New episodes', ru: 'Новые серии' },
                routeActive: [ROUTE_NAME.TV_NEW_EPISODES],
                hidden: rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.TV_COMING_SOON,
                route: { name: ROUTE_NAME.TV_COMING_SOON },
                lang: { en: 'Soon', ru: 'Скоро' },
                routeActive: [ROUTE_NAME.TV_COMING_SOON],
                hidden: rootGetters.isReducedFunctionalityInvest,
            },
            {
                name: ROUTE_NAME.TWAINSPORT,
                route: { name: ROUTE_NAME.TWAINSPORT },
                routeActive: [ROUTE_NAME.TWAINSPORT],
                badge: badgeNewObj,
                hidden: rootGetters.isReducedFunctionalityBetsOnly || rootGetters.isReducedFunctionalityInvest,
                [NAVIGATION_ITEM_SORTING_KEY]: NAVIGATION_ITEMS_SORTING_VALUES.TWAINSPORT,
            },
        ],

        burger: (state, getters) => getters.baseFilter(BURGER_NAVIGATION_ROUTE_NAMES),

        top: (state, getters) => (neededItemsNamesArr) => (
            getters
                .baseFilter(neededItemsNamesArr)
                .filter(({ name }) => !excludedRoutesFromTopNav.includes(name))
        ),

        baseFilter: (state, getters, rootState, rootGetters) => (neededItemsNamesArr) => {
            let resultItems = neededItemsNamesArr
                ? getters.allTopNavigationMenuItemsArr.filter(
                    (it) => neededItemsNamesArr.find((el) => el === it.name),
                )
                : getters.allTopNavigationMenuItemsArr;

            resultItems = resultItems
                .filter((it) => !!it)
                .filter((it) => !it.hidden)
                .filter((it) => !it.auth || rootGetters.isAuthed)
                .filter((it) => !it.checkLang || it.checkLang.includes(rootGetters.currentLang));

            if (rootGetters.isPreferCasino) {
                resultItems = sortNavList(sortTopForPreferCasino, resultItems);
            }

            if (rootGetters.isPreferInvest) {
                resultItems = sortNavList(sortTopForPreferInvest, resultItems);
            }

            return resultItems;
        },

        orderedTopNavigationMenuItems: (state, getters) => (neededItemsNamesArr) => {
            let resultItems = getters.top(neededItemsNamesArr);

            const { topNavigationMenuItemsOrderMap } = getters;
            if (topNavigationMenuItemsOrderMap) {
                const sortFn = getTopNavigationItemsSortFunction(topNavigationMenuItemsOrderMap);
                resultItems = resultItems.slice().sort(sortFn);
            }

            return resultItems;
        },

        burgerMenuItems: (state, getters) => {
            const burgerMenuItems = getters.burger;
            injectBurgerDividers(burgerMenuItems);
            return burgerMenuItems;
        },

        orderedBurgerMenuItems: (state, getters) => {
            let resultItems = getters.burgerMenuItems.slice();
            const { topNavigationMenuItemsOrderMap } = getters;
            if (topNavigationMenuItemsOrderMap) {
                resultItems = resultItems.sort(getTopNavigationItemsSortFunction(topNavigationMenuItemsOrderMap));
            }
            return resultItems;
        },

        bottom: (state, getters, rootState, rootGetters) => {
            const bottom = [
                {
                    name: 'home',
                    route: { name: ROUTE_NAME.HOME },
                    icon: 'navigation/home',
                    routeActive: [ROUTE_NAME.HOME],
                },
                {
                    name: 'live',
                    route: { name: ROUTE_NAME.BETS_LIVE },
                    currentRoute: null,
                    icon: 'navigation/live',
                    routeActive: [
                        ROUTE_NAME.BETS,
                        ROUTE_NAME.BETS_SPORT,
                        ROUTE_NAME.BETS_TOURNAMENT,
                        ROUTE_NAME.BETS_MATCH,
                        ROUTE_NAME.BETS_VSPORT,
                        ROUTE_NAME.BETS_CYBERSPORT,
                        ROUTE_NAME.BETS_CYBERSPORT_TOURNAMENT,
                        ROUTE_NAME.BETS_CYBERSPORT_SPORT,
                    ],
                },
                {
                    name: 'coupons',
                    route: { name: ROUTE_NAME.BETS_COUPONS },
                    currentRoute: null,
                    icon: 'navigation/ticket',
                    routeActive: [ROUTE_NAME.BETS_COUPONS],
                },
                {
                    name: 'casino',
                    route: { name: ROUTE_NAME.CASINO },
                    currentRoute: null,
                    icon: 'navigation/casino',
                    routeActive: [
                        ROUTE_NAME.CASINO,
                        ROUTE_NAME.CASINO_PROVIDER,
                        ROUTE_NAME.CASINO_PROVIDERS,
                        ROUTE_NAME.CASINO_CATEGORY,
                    ],
                    hidden: rootGetters.isReducedFunctionalityBetsOnly,
                },
                {
                    name: 'live-games',
                    route: { name: ROUTE_NAME.CASINO_LIVE },
                    currentRoute: null,
                    icon: 'navigation/games',
                    routeActive: [
                        ROUTE_NAME.CASINO_LIVE,
                        ROUTE_NAME.CASES,
                        ROUTE_NAME.CASES_LIST,
                        ROUTE_NAME.CASE,
                        ROUTE_NAME.CASINO_GAMES,
                        ROUTE_NAME.CASINO_GAMES_ONE_WIN,
                    ],
                    hidden: rootGetters.isReducedFunctionalityBetsOnly,
                },
            ];

            return bottom.filter((it) => !it.hidden);
        },

        isRouteActive: () => (currentRoute, { route, routeActive }) => {
            const hash = route.hash ?? '';
            const currentRouteHash = currentRoute.hash ?? '';

            if (hash !== currentRouteHash) {
                return false;
            }

            const routes = [routeActive].flat().filter(Boolean);
            const isCurrentRoute = (x) => checkEqualFirstValue(x, currentRoute);

            return routes.includes(currentRoute.name) || routes.some(isCurrentRoute);
        },

        activeClass: (state, getters) => (currentRoute, { route, routeActive, name }) => (
            getters.isRouteActive(currentRoute, { route, routeActive, name })
                ? 'route-active'
                : ''
        ),

        getTabByRouteName: (state, getters) => (name) => getters.bottom.find((it) => it.routeActive.includes(name)),
    },

    actions: {
        beforeRoute({ commit, getters }, { to }) {
            const { name: routeName } = to;
            const tab = getters.getTabByRouteName(routeName);

            if (tab && !excludedTabCurrent.includes(routeName)) {
                commit('updateBottomTabCurrentRoute', { tab, route: to });
            }
        },

        tabBeforeClick({ commit, getters }, { tab, currentRoute }) {
            const currentTab = getters.getTabByRouteName(currentRoute.name);
            const route = !currentTab || currentTab.name === tab.name
                ? tab.route
                : tab.currentRoute || tab.route;

            commit('updateBottomTabCurrentRoute', { tab, route });
        },

        /**
         * Be careful: this method affects both mobile and desktop main navigation panels
         */
        async saveMainNavigationMenuOrderMap({ commit, getters, rootGetters }, navigationItemKeyToOrderMap) {
            const orderConvertedToArray = convertNavigationItemsOrderMapToArray(navigationItemKeyToOrderMap);
            try {
                saveMainNavigationMenuOrderMapInLocalStorage(orderConvertedToArray);
                if (rootGetters.isAuthed && !isEqual(orderConvertedToArray, getters.topNavigationMenuItemsOrderArray)) {
                    await rootGetters.socket.send('USER:profile-hamburger-menu', { menu: orderConvertedToArray });
                }
            } catch (e) {
                console.warn('Could not save main navigation panel order.', e);
            } finally {
                commit('userProfile', { hamburger_menu: orderConvertedToArray }, { root: true });
            }
        },
    },

    mutations: {
        updateBottomTabCurrentRoute(state, { tab, route }) {
            tab.currentRoute = {
                name: route.name,
                query: route.query,
                hash: route.hash,
                params: route.params,
            };
        },
    },

};
