<template lang="pug">
PushNotificationAbstract
    template(#default="{ showed, loading, bonusAmount, onSubscribe, onRefuse }")
        div(
            v-if="showed"
            :class="[$style.pushNotificationContainer, { 'has-tabs': hasTabs }]"
        )
            div(:class="$style.pushNotificationBackground")
            div(:class="$style.pushNotificationBlock")
                div(:class="$style.pushNotificationContent")
                    div(:class="$style.pushNotificationIndicator")
                    div
                        div(:class="$style.pushNotificationTitle") {{ text.title }}
                        Spinner(
                            v-if="loading"
                            size="sm"
                        )
                        div(
                            v-else
                            :class="$style.pushNotificationAmount"
                        ) +{{ bonusAmount }}
                        div(:class="$style.pushNotificationDescription") {{ description(bonusAmount) }}
                    div(:class="$style.pushNotificationContentRight")
                        div(:class="$style.pushNotificationContentRightWrapper")
                            VPicture(
                                :class="$style.pushNotificationContentImg"
                                :picture=`{
                                    png: $options.giftWithCoinsImage,
                                    webp: $options.giftWithCoinsImageWebp,
                                }`
                            )
                div(:class="$style.pushNotificationButtons")
                    BaseButton(
                        theme="green"
                        @click="onSubscribe"
                    ) {{ text.subscribe }}
                    BaseButton(
                        theme="secondary-filled"
                        @click="onRefuse"
                    ) {{ text.refuse }}
</template>

<script>
import { mapGetters } from 'vuex';

import giftWithCoinsImageWebp from '@/assets/images/gift-with-coins.png?format=webp,sizes[]=300,sizes[]=150';
import giftWithCoinsImage from '@/assets/images/gift-with-coins.png?sizes[]=300,sizes[]=150';
import PushNotificationAbstract from '@/components/PushNotificationAbstract';
import Spinner from '@/components/Spinner.vue';
import VPicture from '@/components/VPicture.vue';
import { formatString } from '@/plugins/LanguageTools';

import BaseButton from '@mobile/components/RebornComponents/BaseComponents/BaseButton/BaseButton.vue';

export default {
    name: 'PushNotification',

    components: {
        Spinner,
        BaseButton,
        PushNotificationAbstract,
        VPicture,
    },

    props: {
        hasTabs: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapGetters(['langCommon']),

        text() {
            return this.langCommon.pushModal;
        },
    },

    methods: {
        description(amount) {
            return formatString(this.text.description, amount);
        },
    },

    giftWithCoinsImage,
    giftWithCoinsImageWebp,
};
</script>

<style lang="stylus" module>
.pushNotificationContainer
    position fixed
    bottom 0
    bottom env(safe-area-inset-bottom, 0)
    z-index $push-notification-index
    width 100%

.pushNotificationBackground
    height 343px
    width 100%
    bottom 0
    background-image linear-gradient(180deg, alpha(#090F1E, 0) 0%, #090F1E 100%)
    position absolute
    pointer-events none

.pushNotificationBlock
    position absolute
    bottom 0
    padding 15px
    background radial-gradient(100% 400% at 0% 0%, alpha(#0ee023, 0.12) 0%, alpha(#0ee023, 0) 100%),
               linear-gradient(78.89deg, #1E283F 0%, #141B2E 100%)
    border-radius 16px
    overflow hidden
    width calc(100% - 20px)
    margin 0 10px 10px

.pushNotificationContent
    display flex
    position relative
    justify-content space-between

.pushNotificationIndicator
    position absolute
    width 4px
    top 0
    bottom 0
    left -15px
    border-top-right-radius 45px
    border-bottom-right-radius 45px
    background linear-gradient(to left top, #009746 0%, #38C172 100%)

.pushNotificationTitle
    font-family $ff-heading
    font-weight 600
    font-size 13px
    line-height 1.2
    letter-spacing 0.6px
    text-shadow 0 6px 22px alpha(#da8805, .3)

.pushNotificationAmount
    font-family $ff-heading
    font-weight 900
    font-size 24px
    line-height 1.2
    text-shadow 0 6px 22px alpha(#da8805, .3)
    background linear-gradient(to right bottom, #FBC709, #FF8500)
    -webkit-background-clip text
    background-clip text
    -webkit-text-fill-color transparent
    margin-top 5px
    display inline-block

.pushNotificationDescription
    font-size 12px
    line-height 1.2
    opacity .7
    white-space pre
    margin-top 5px

.pushNotificationContentRight
    position relative
    display flex
    justify-content center
    flex-grow 1
    flex-shrink 0

.pushNotificationContentRightWrapper
    width 113px
    position absolute
    right 10px

    @media screen and (max-width 350px)
        right -10px

.pushNotificationContentImg
    max-width none
    user-select none
    pointer-events none

.pushNotificationButtons
    margin-top 15px
    display grid
    grid-gap 10px
    grid-template-columns 1fr 1fr
</style>
