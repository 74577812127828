import { setAppPromoDelayTimer } from '@mobile/plugins/MobileAppPromoPlugin';

export default {
    namespaced: true,

    state: {
        showApplicationPromo: false,
    },

    mutations: {
        setShowApplicationPromo(state, payload) {
            state.showApplicationPromo = payload;
        },
    },

    actions: {
        hideApplicationPromo({ commit }) {
            commit('setShowApplicationPromo', false);
            setAppPromoDelayTimer();
        },
    },

    getters: {
        showedBottomNavigation: (_, __, ___, rootGetters) => (
            !rootGetters.isReducedFunctionalityPoker
            && !rootGetters.isReducedFunctionalityInvest
        ),
        showApplicationPromo: (state) => state.showApplicationPromo,
    },
};
