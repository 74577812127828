<template lang="pug">
.support-call
    slot(:call="call")
</template>

<script>
export default {
    name: 'SupportCall',

    methods: {
        call() {
            this.$emit('click');
            window.open(`tel:${this.$store.getters.supportNumber}`);
        },
    },
};
</script>
