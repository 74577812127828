<template lang="pug">
.menu-social-list
    a.menu-social-list__link(
        v-for="socialItem in socialList"
        :key="socialItem.key"
        :href="socialItem.href"
        rel="noopener"
        target="_blank"
    )
        VIconSvgGradient.menu-social-list__gradient-icon(
            module="social"
            :icon="socialItem.icon"
            :gradient="socialItem.gradient"
            gradientTagName="path"
        )
</template>

<script>
import { mapGetters } from 'vuex';

import VIconSvgGradient from '@/components/Icons/VIconSvgGradient.vue';

export default {
    name: 'MenuSocialList',
    components: { VIconSvgGradient },
    computed: {
        ...mapGetters(['currentLang', 'socialLinks']),

        socialList() {
            return [
                {
                    href: this.socialLinks.vkontakte,
                    icon: 'social/vk',
                    gradient: 'social-vk-gradient',
                    key: 'vk',
                },
                {
                    href: this.socialLinks.telegram,
                    icon: 'social/tg',
                    gradient: 'social-tg-gradient',
                    key: 'tg',
                },
            ].filter(({ href }) => href);
        },
    },
};
</script>

<style scoped lang="stylus">
.menu-social-list
    display flex

.menu-social-list__link
    width 30px
    height 30px
    display flex
    align-items center
    justify-content center
    border-radius $radius-sm
    background rgba(151, 174, 225, 0.11)

    &:not(:first-child)
        margin-left 5px
</style>
