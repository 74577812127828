import isWebView from 'is-ua-webview';

import { AppStorage, NEXT_APP_PROMO_SHOW_TIME } from '@/plugins/AppStorage';
import { isPwa, parseMobileUserAgent } from '@/plugins/Platform';
import { PromiseEnum } from '@/store/promise';

function isAppPromoTimerExpired() {
    const now = Date.now();
    return AppStorage.getOr(NEXT_APP_PROMO_SHOW_TIME, now) <= now;
}

export function setAppPromoDelayTimer() {
    AppStorage.set(NEXT_APP_PROMO_SHOW_TIME, Date.now() + 1000 * 60 * 60 * 12); // 12 часов
}

function clearAppPromoDelayTimer() {
    AppStorage.remove(NEXT_APP_PROMO_SHOW_TIME);
}

function shouldApplicationPromoBeShowed(isAuthed) {
    if (isWebView(navigator.userAgent)) {
        return false;
    }

    if (isAuthed) {
        if (!isPwa() || parseMobileUserAgent(navigator.userAgent).android.any) {
            return isAppPromoTimerExpired();
        }
    }

    return false;
}

export default ({ store }) => ({
    async install() {
        if (process.env.IS_MOBILE_APP) {
            return;
        }

        await store.getters.promiseByName[PromiseEnum.AUTH];

        store.commit('layout/setShowApplicationPromo', shouldApplicationPromoBeShowed(store.getters.isAuthed));
        store.watch(
            (_, getters) => getters.isAuthed,
            (isAuthed) => {
                clearAppPromoDelayTimer();
                store.commit('layout/setShowApplicationPromo', shouldApplicationPromoBeShowed(isAuthed));
            },
        );
    },
});
