<template lang="pug">
FiltersControl(:active="!!timeFilter")
    template(#select)
        VSelectNative.select(
            :items="items"
            :value="currentFilterValue"
            @input="onFilterSelect"
        )
</template>

<script>
import {
    mapGetters, mapState, mapMutations,
} from 'vuex';

import { SERVICE_PREMATCH, BETS_TAB_EVENTS } from '@/constant/constants';

import FiltersControl from './FiltersControl.vue';

const filterTypes = {
    DEFAULT: 0,
    TIME: 1,
    DAY: 2,
};

const datetimeOptionsMonthDay = Object.freeze({
    month: 'long',
    day: 'numeric',
});

export default {
    name: 'FilterControlPrematch',

    components: {
        FiltersControl,
    },

    inject: ['$filters'],

    computed: {
        ...mapGetters([
            'dateTimeFormat',
            'lang',
        ]),

        ...mapState('xbet', [
            'timeFilter',
        ]),

        ...mapState(`bets/mobileHeader/${SERVICE_PREMATCH}`, [
            'activeTab',
        ]),

        text() {
            return this.lang['filter-control-prematch'];
        },

        currentFilterValue() {
            if (!this.timeFilter) {
                return 'default';
            }

            const cur = this.timeFilter.date || this.timeFilter.hoursToStart;
            return cur.toString();
        },

        hours() {
            return [1, 2, 3, 4, 5, 6, 12].map((hour) => ({
                type: filterTypes.TIME,
                label: `${this.text.during} ${hour} ${this.text.h}`,
                value: hour,
            }));
        },

        days() {
            return Array.from({ length: 6 }, (it, i) => {
                const date = new Date();
                date.setDate(date.getDate() + i);
                return {
                    type: filterTypes.DAY,
                    value: `${date
                        .getDate()
                        .toString()
                        .padStart(2, '0')}/${(date.getMonth() + 1).toString()
                        .padStart(2, '0')}`,
                    label: this.$filters.datetime(date, datetimeOptionsMonthDay),
                };
            });
        },

        isDefaultHot() {
            return this.activeTab === BETS_TAB_EVENTS
                && this.$route.name === this.ROUTE_NAME.BETS
                && this.$route.params.service === SERVICE_PREMATCH;
        },

        items() {
            return [
                {
                    type: filterTypes.DEFAULT,
                    label: this.isDefaultHot ? this.text.hot : this.text.all,
                    value: 'default',
                },
                ...this.hours,
                ...this.days,
            ];
        },
    },

    methods: {
        ...mapMutations('xbet', [
            'setTimeFilter',
        ]),

        async onFilterSelect({ type, value }) {
            switch (type) {
                case filterTypes.TIME:
                    this.setTimeFilter({ date: false, hoursToStart: value });
                    break;
                case filterTypes.DAY:
                    this.setTimeFilter({ date: value });
                    break;
                default:
                    this.setTimeFilter(null);
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.sel
    width 100%
</style>
