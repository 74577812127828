<template lang="pug">
.v-select
    VSelectNative.select(
        :items="items"
        :value="value"
        :placeholder="placeholder"
        :label-key="labelKey"
        :value-key="valueKey"
        :class="{ 'not-selected': notSelected }"
        @input="$emit('input', $event)"
    )

    VIcon.icon(
        icon="chevron-down"
        margin
        square
    )
</template>

<script>
import VSelectNative from './VSelectNative.vue';

export default {
    components: {
        VSelectNative,
    },

    props: {
        // types are same as VSelectNative
        value: {
            type: [String, Object],
            default: null,
        },
        labelKey: {
            type: String,
            default: null,
        },
        valueKey: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        items: {
            type: Array,
            required: true,
        },
    },

    computed: {
        notSelected() {
            return this.value === null;
        },
    },
};
</script>

<style lang="stylus" scoped>
$control-height = 36px

.v-select
    height $control-height
    position relative
    width 100%

    .select
        appearance none
        background-color $dark-800
        border-color transparent
        border-radius $radius-md
        border-style solid
        border-width 1px
        color $white
        font-size 13px
        font-weight $fw-normal
        height 100%
        overflow hidden
        padding 0 $md + 12px 0 $md
        transition all .2s
        width 100%

        &.not-selected
            Input_label()

        &:focus
            border-color $primary-800

    .option
        color $white

    .icon
        font-size 10px
        position absolute
        right 12px
        top 50%
        transform translateY(-50%)
</style>
