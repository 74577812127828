<template>
  <div :class="showCashback ? $style.rootTriple : $style.root">
    <CashbackTimerTight
      v-if="showCashback"
      :class="$style.cashback"
      @click.native="onCashbackTimerClick"
    />
    <FreeMoneyLink
      :class="$style.freeMoney"
      :is-diminished="showCashback"
    />
    <BonusLink :class="$style.bonus" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import BonusLink from '@mobile/components/Bonus/BonusLink.vue';
import FreeMoneyLink from '@mobile/components/FreeMoney/FreeMoneyLink.vue';
import CashbackTimerTight from '@mobile/components/Timers/CashbackTimerTight.vue';
import { MODAL_CASINO_CASHBACK } from '@mobile/constant/modalName';
import ROUTE_NAME from '@mobile/constant/routeName';

export default {
    components: {
        BonusLink,
        CashbackTimerTight,
        FreeMoneyLink,
    },

    inject: ['$modal'],

    computed: {
        ...mapGetters([
            'isAuthed',
            'cashbackAllowed',
        ]),

        showCashback() {
            return this.isAuthed && this.cashbackAllowed;
        },
    },

    methods: {
        onCashbackTimerClick() {
            if (this.$router.currentRoute.name !== ROUTE_NAME.BONUSES) {
                this.$router.push({ name: ROUTE_NAME.BONUSES });
            }
            this.$modal.open(MODAL_CASINO_CASHBACK);
        },
    },
};
</script>

<style lang="stylus" module>
.root
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 5px

.item
    border-radius 8px
    min-width 0

    &:first-child
        border-top-left-radius 12px
        border-bottom-left-radius 12px

    &:last-child
        border-top-right-radius 12px
        border-bottom-right-radius 12px

.cashback
.freeMoney
.bonus
    composes item

.rootTriple
    composes root
    grid-template-columns repeat(3, 1fr)

    @media (max-width 520px)
        grid-template-columns repeat(2, 1fr)
        grid-template-areas 'free-money bonus' 'cashback cashback'

        .cashback
            grid-area cashback
            border-radius 12px

        .freeMoney
            grid-area free-money
            border-radius 12px 8px 8px 12px

        .bonus
            grid-area bonus
            border-radius 8px 12px 12px 8px
</style>
