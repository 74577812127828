<script>
import Carousel from '@mobile/components/carousels/Carousel.vue';

export default {
    name: 'FluidCarousel',

    components: {
        Carousel,
    },

    props: {
        activeSlideIndex: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            offset: 0,
            duration: 0,
        };
    },

    methods: {
        changeOffsetAndDurationHandler({ offset, duration }) {
            if (offset === 0) this.duration = duration;
            setTimeout(() => {
                this.duration = 0;
            }, duration);
            this.offset = offset;
        },
        getSlideStyle(isActive) {
            const style = { 'transition-duration': `${this.duration}ms` };
            const scaleDifference = 0.29 * Math.abs(this.offset);
            const scaleValue = isActive ? 1 - scaleDifference : 0.71 + scaleDifference;
            style.transform = `scale(${scaleValue})`;
            return style;
        },
        getSlideClasses(isActive) {
            return {
                'fluid-carousel-slide_active': isActive,
                'fluid-carousel-slide_scroll-to-left': this.offset > 0,
            };
        },
        isActiveSlide(index) {
            return index === this.activeSlideIndex;
        },
    },

    render(h) {
        const wrappedSlides = this.$slots.default
            .filter((slide) => slide && slide.data)
            .map(
                (slide, index) => h('div', {
                    style: {
                        ...this.getSlideStyle(this.isActiveSlide(index)),
                    },
                    class: {
                        'fluid-carousel-slide': true,
                        ...this.getSlideClasses(this.isActiveSlide(index)),
                    },
                }, [slide]),
            );

        return h(
            'Carousel',
            {
                on: {
                    ...this.$listeners,
                    changeOffsetAndDuration: this.changeOffsetAndDurationHandler,
                },
                attrs: {
                    ...this.$attrs,
                    ...this.$props,
                },
            },
            wrappedSlides,
        );
    },
};
</script>

<style lang="stylus" scoped>
.fluid-carousel-slide
    transition-delay 0s
    transition-duration 0s
    transition-property all
    transition-timing-function ease-out

    &.fluid-carousel-slide_active.fluid-carousel-slide_scroll-to-left
    &:last-child
        transform-origin left center

    &.fluid-carousel-slide_active:not(.fluid-carousel-slide_scroll-to-left)
    &:first-child
        transform-origin right center
</style>
