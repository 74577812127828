var render, staticRenderFns
import script from "./FluidCarousel.vue?vue&type=script&lang=js&"
export * from "./FluidCarousel.vue?vue&type=script&lang=js&"
import style0 from "./FluidCarousel.vue?vue&type=style&index=0&id=c15c79d0&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c15c79d0",
  null
  
)

export default component.exports