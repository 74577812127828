<template lang="pug">
.lang-select
    slot(
        :langs="$options.langOptionList"
        :currentLang="currentLang"
        :changeLang="changeLang"
    )
</template>

<script>
import { mapGetters } from 'vuex';

import { LangItemsEnum } from '@/constant/constants';

export default {
    computed: {
        ...mapGetters([
            'lang',
            'currentLang',
        ]),
    },

    methods: {
        changeLang(lang) {
            this.$store.dispatch('setCurrentLanguage', { lang });
        },
    },

    langOptionList: LangItemsEnum.sortedValuesByOneWinKey.map(
        ({ label, oneWinKey }) => ({ label, value: oneWinKey }),
    ),
};
</script>
