<template lang="pug">
select.v-select-native(
    :value="currentValue"
    @input="onSelect"
)
    option(
        v-if="placeholder"
        value=""
        disabled
        selected
    ) {{ placeholder }}

    option.option(
        v-for="(opt, i) of options"
        :key="i"
        :value="opt.value"
    ) {{ opt.label }}
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Object],
            default: null, // null, если нужно показать плейсхолдер
        },
        placeholder: {
            type: String,
            default: null,
        },
        labelKey: {
            type: String,
            default: 'label',
        },
        valueKey: {
            type: String,
            default: 'value',
        },
        items: { // String[] || { [labelKey]: String, [valueKey]: any, ...any }[]
            type: Array,
            required: true,
        },
    },

    computed: {
        currentValue() {
            const val = this.value;
            if (val === null || typeof val === 'string') {
                return val;
            }
            return val[this.valueKey];
        },

        options() {
            if (typeof this.items[0] === 'string') {
                return this.items.map((item) => ({
                    value: item.toString(),
                    label: item.toString(),
                }));
            }

            return this.items.map((item) => ({
                value: item[this.valueKey].toString(),
                label: item[this.labelKey].toString(),
            }));
        },
    },
    methods: {
        onSelect(e) {
            const selectedItemIndex = this.options.findIndex(
                (o) => o.value === e.target.value,
            );
            this.$emit('input', this.items[selectedItemIndex]);
        },
    },
};
</script>
