<template lang="pug">
.back(
    :is="route ? 'RouterLink' : 'a'"
    v-on="!route && $listeners"
    v-bind="route && { to: route }"
)
    VIcon.back-icon(
        icon="chevron-left-bold"
        margin
        square
    )
    slot
    .back-page-name.helper-line {{ $filters.locale(label) }}
</template>

<script>
import VIcon from '@/components/Icons/VIcon.vue';

export default {
    components: {
        VIcon,
    },

    inject: ['$filters'],

    props: {
        label: {
            type: [Object, String],
            required: true,
        },
        route: {
            type: [Object, String, Boolean],
            default: false,
        },
    },
};
</script>

<style lang="stylus" scoped>
.back
    align-items center
    color $white
    display inline-flex
    height 35px
    margin $xs 0
    padding 0 $md
    user-select none

    .back-icon
    .back-page-name
        font-size 13px
</style>
