<template lang="pug">
label.v-checkbox(
    :class="`${theme}-theme`"
    role="checkbox"
    :aria-checked="checked"
    :aria-disabled="disabled"
)
    span.input(
        :class=`{
            'checked': checked,
            'disabled': disabled,
        }`
    )
    input.input-original(
        type="checkbox"
        aria-hidden="true"
        v-bind="$attrs"
        :disabled="disabled"
        :checked="checked"
        @change="$emit('input', !checked)"
    )
    slot
</template>

<script>
export default {
    name: 'VCheckbox',

    props: {
        checked: Boolean,
        disabled: Boolean,
        theme: {
            validator: (val) => ['checkbox', 'switch'].includes(val),
            default: 'checkbox',
        },
    },
};
</script>

<style lang="stylus" scoped>
.v-checkbox
    display inline-block
    user-select none
    cursor pointer
    color $white
    position relative

    .input-original
        z-index -1
        opacity 0
        position absolute
        top 0
        left 0
        height 0
        width 0

    &.checkbox-theme

        .input
            display inline-block
            width 14px
            height 14px
            border-radius 4px
            background-color $white
            position relative

            &.checked // TODO дизайн состояния
                background-color $primary-700

                &:after
                    content ''
                    position absolute
                    top 3px
                    left 4px
                    width 6px
                    height 5px
                    border 2px solid $white
                    border-top none
                    border-right none
                    background transparent
                    transform rotate(-45deg)

            &.disabled // TODO дизайн состояния
                opacity .5

    &.switch-theme

        .input
            display inline-block
            width 45px
            height 21px
            border-radius 15px
            background-color $dark-400
            position relative
            transition background-color .15s ease-out

            &:after
                content ''
                position absolute
                top 4px
                left 5px
                width 13px
                height 13px
                border-radius 50%
                background-color $white
                transition transform .15s ease-out

            &.checked
                background-image $primary-gradient
                box-shadow $primary-shadow

                &:after
                    transform translateX(22px)

            &.disabled
                opacity .5
</style>
