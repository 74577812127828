<template lang="pug">
.event-sport-tabs
    Tabs(
        :items="Object.values(tabs)"
        :active="tabs[active]"
        @select="tabChanged"
    )
</template>

<script>
import { BETS_TAB_EVENTS, BETS_TAB_SPORTS, SERVICE_LIVE } from '@/constant/constants';

import Tabs from '@mobile/components/Tabs.vue';

export default {
    name: 'EventsOrSportsTabs',

    components: {
        Tabs,
    },

    props: {
        active: {
            type: String,
            required: true,
            validator: (val) => [BETS_TAB_EVENTS, BETS_TAB_SPORTS].includes(val),
        },
    },

    computed: {
        text() {
            return this.$store.getters.lang['events-or-sports-tabs'];
        },

        tabsLive() {
            return {
                events: this.text.events,
                sports: this.text.sports,
            };
        },

        tabsPrematch() {
            return {
                sports: this.text.sports,
                events: this.text.events,
            };
        },

        tabs() {
            return this.$route.params.service === SERVICE_LIVE
                ? this.tabsLive
                : this.tabsPrematch;
        },
    },

    methods: {
        tabChanged(tabName) {
            const [selectedTab] = Object
                .entries(this.tabs)
                .find(([, val]) => val === tabName);

            this.$emit('tabChanged', selectedTab);
        },
    },
};
</script>

<style lang="stylus" scoped>
.event-sport-tabs
    flex-grow 1
</style>
