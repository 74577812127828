<template lang="pug">
header#header(:class="headerClass")
    MobileAppPromoBlock(
        v-if="showApplicationPromo"
        :fixed="isGameRelatedView"
    )

    .header-content
        BaseBack(
            v-if="isGameRelatedView"
            :route="backBtnRoute"
            :label="text.back"
        )
        RouterLink.logo-wrapper.logo-link(
            v-if="!isGameRelatedView"
            :to="{ name: ROUTE_NAME.HOME }"
            :class="{ invest: isInvestPage }"
        )
            VIcon.logo-invest(
                v-if="isInvestPage"
                icon="invest/1-w-invest"
                ref="logo"
                :size="22"
            )
            Logo.logo(
                v-else
                mobile
                ref="logo"
            )
        img.locale-flag(
            v-if="showFlag"
            :src="localeFlag"
        )

        transition(name="fade" mode="out-in")
            .controls(v-if="authChecked || isAuthed" ref="controls")
                .controls-wrapper(v-if="isAuthed")
                    transition(name="fade")
                        .balance(v-if="showUserInfo")
                            .balance-container
                                .balance-text ID {{ user.id }}
                                .balance-value.name-value
                                    VButton.small-deposit-button(
                                        v-if="displaySmallDepositButton"
                                        theme="green"
                                        @click="onDepositClick"
                                    )
                                        VIcon(icon="plus")
                                    | {{ user.name }}

                        .balance(v-else-if="showBalance")
                            .balance-container
                                .balance-text(v-if="!isGameRelatedView") {{ text.balance }}

                                .balance-value(:class="{'with-balance-button': displaySmallDepositButton}")
                                    .deposit-button.small(
                                        v-if="displaySmallDepositButton"
                                        @click="onDepositClick"
                                    )
                                        VIcon(icon="plus")
                                    span.balance-trunc {{ $filters.trunc(balance) }}
                                    span.balance-fraction {{ $filters.fraction(balance, true) }}
                                    span.balance-currency &nbsp;{{ $filters.currency(user.currency) }}

                    .deposit-button(
                        v-if="!displaySmallDepositButton"
                        @click="onDepositClick"
                    ) {{ text.depositShort }}

                    .control-item(
                        v-if="!isGameRelatedView"
                        @click.stop="goProfile"
                    )
                        VButton.profile-button(:theme="buttonIsBack ? undefined : 'dark'")
                            VIcon(
                                :icon="buttonIsBack ? 'close' : 'user'"
                                margin
                                square
                            )

                BurgerMenuButton.burger-button(
                    v-if="isBurgerMenuAllowed"
                    @click="onBurgerClick"
                )

    .header-buttons(v-if="!isGameRelatedView && !isAuthed")
        VButton.login.control-item(
            size="md"
            theme="primary"
            @click="openLoginModal"
        )
            span.control-item__title {{ text.login }}
            VIcon.control-item__icon(
                icon="user"
                margin
                square
            )

        VButton.registration.control-item(
            size="md"
            theme="success"
            @click="openRegistrationModal"
        )
            .control-item__inner
                span.control-item__title {{ langCommon.registration.registrationButtonText }}
                small.control-item__subtitle(v-if="isRegisterStarted")
                    | {{ langCommon.registration.registrationButtonTextComplete }}

</template>

<script>
import { mapGetters } from 'vuex';

import Logo from '@/components/Logo/Logo.vue';
import {
    HEADER_INVEST,
    HEADER_LEADERBOARD,
    NO_STICKY_HEADER,
    ROUTE_META_CUSTOM_HEADER_BOTTOM,
    META_USE_GAME_RELATED_VIEW,
} from '@/constant/constants';
import { AppStorage, REGISTER_FORM } from '@/plugins/AppStorage';
import { PromiseEnum } from '@/store/promise';
import { getElementStyleValues } from '@/utils/dom';

import BaseBack from '@mobile/components/BaseBack.vue';
import BurgerMenuButton from '@mobile/components/BurgerMenuButton.vue';
import MobileAppPromoBlock from '@mobile/components/MobileAppPromoBlock.vue';
import { MODAL_DEPOSIT, MODAL_LOGIN, MODAL_REGISTRATION } from '@mobile/constant/modalName';
import ROUTE_NAME from '@mobile/constant/routeName';
import cssVars from '@mobile/styles/variables.module.styl';

export default {
    components: {
        BaseBack,
        BurgerMenuButton,
        Logo,
        MobileAppPromoBlock,
    },

    inject: [
        '$filters',
        '$modal',
    ],

    props: {
        profilePage: {
            type: Boolean,
            default: false,
        },
        backRoute: {
            type: [Object, String],
            default: null,
        },
    },

    data: () => ({
        authChecked: false,
        ROUTE_NAME,
        displaySmallDepositButton: false,
        prevRoute: undefined,
    }),

    computed: {
        ...mapGetters([
            'showBalance',
            'user',
            'balance',
            'lang',
            'langCommon',
            'currentLang',
            'isBurgerMenuAllowed',
            'localeFlag',
            'showLocaleFlag',
        ]),
        ...mapGetters('layout', ['showApplicationPromo']),
        showUserInfo() {
            return [
                ROUTE_NAME.PROFILE,
                ROUTE_NAME.PROFILE_WALLETS,
            ].includes(this.$route.name);
        },
        buttonIsBack() {
            return this.profilePage && this.$route.params.prevRoute;
        },
        text() {
            return this.lang.Header;
        },
        isRegisterStarted() {
            return AppStorage.reactiveStorageObject[REGISTER_FORM];
        },
        backBtnRoute() {
            return this.backRoute || this.prevRoute || { name: ROUTE_NAME.HOME };
        },
        isInvestPage() {
            return this.customHeader === HEADER_INVEST;
        },
        isLeaderboardPage() {
            return this.customHeader === HEADER_LEADERBOARD;
        },
        customHeader() {
            return this.$route.meta?.[ROUTE_META_CUSTOM_HEADER_BOTTOM];
        },
        isGameRelatedView() {
            return this.$route.meta?.[META_USE_GAME_RELATED_VIEW];
        },
        headerClass() {
            return {
                'invest-theme': this.isInvestPage,
                'no-sticky': this.$route.meta?.[NO_STICKY_HEADER],
                'game-related': this.isGameRelatedView,
                'leaderboard-theme': this.isLeaderboardPage,
            };
        },
        reCalcButtonSizesAggregate() {
            return `${this.showUserInfo} ${this.balance} ${this.currentLang} ${this.$route.name}`;
        },
        showFlag() {
            return !this.isGameRelatedView && this.showLocaleFlag;
        },
    },

    watch: {
        showApplicationPromo: {
            handler: 'recalcHeaderHeightVariables',
            immediate: true,
        },
        isGameRelatedView(newValue) {
            this.prevRoute = newValue ? this.$route.params.prevRoute : undefined;
            this.recalcHeaderHeightVariables();
        },
        '$modal.isOpen': {
            handler: 'recalcHeaderHeightVariables',
            immediate: true,
        },
        isAuthed: {
            handler: 'recalcHeaderHeightVariables',
            immediate: true,
        },
    },

    async mounted() {
        await this.checkAuth();

        this.unwatch = this.$watch('reCalcButtonSizesAggregate', this.reCalcButtonSizes, { immediate: true });
        window.addEventListener('resize', this.reCalcButtonSizes, { passive: true });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.reCalcButtonSizes);
        this.unwatch();
    },

    methods: {
        recalcHeaderHeightVariables() {
            let topHeaderHeight = 0;
            let bottomHeaderHeight = 0;

            if (this.isGameRelatedView && !this.$modal.isOpen) {
                bottomHeaderHeight = Number.parseInt(cssVars.layoutDefaultGameRelatedHeaderHeight, 10);
            } else {
                topHeaderHeight += Number.parseInt(
                    this.$modal.isOpen || this.isAuthed
                        ? cssVars.layoutDefaultHeaderHeightWithoutButtons
                        : cssVars.layoutDefaultHeaderHeight,
                    10,
                );
            }

            if (this.showApplicationPromo && !this.$modal.isOpen) {
                topHeaderHeight += Number.parseInt(cssVars.layoutAppPromoHeight, 10);
            }

            document.documentElement.style.setProperty('--layout-header-height', `${topHeaderHeight}px`);
            document.documentElement.style.setProperty('--layout-bottom-header-height', `${bottomHeaderHeight}px`);
        },

        async checkAuth() {
            try {
                await Promise.all([
                    this.$store.getters.promiseByName[PromiseEnum.AUTH],
                    this.$store.getters.promiseByName[PromiseEnum.AUTH_TOKEN],
                ]);
            } finally {
                this.authChecked = true;
            }
        },

        openLoginModal() {
            this.$modal.open(MODAL_LOGIN);
        },

        openRegistrationModal() {
            this.$modal.open(MODAL_REGISTRATION);
        },

        goProfile() {
            if (!this.profilePage) {
                this.$modal.close();
                this.$router.push({ name: this.ROUTE_NAME.PROFILE });
            } else if (this.$route.params.prevRoute) {
                this.$router.$prevRoute().go();
            }
        },

        onDepositClick() {
            if (this.isGameRelatedView) {
                this.$modal.open(MODAL_DEPOSIT);
                return;
            }
            this.$router.push({ name: ROUTE_NAME.WALLET_DEPOSIT });
        },

        calcControlsButtonSizes(
            {
                stateVariableName,
                widthDifferenceBetweenModes = 0,
                extraSpaceBetweenElements = 0,
                minWidthForCalculation = 0,
            } = {},
        ) {
            try {
                if (!stateVariableName) return;
                if (this.$el.clientWidth < minWidthForCalculation) {
                    this[stateVariableName] = true;
                    return;
                }
                const paddings = getElementStyleValues(this.$el, ['paddingLeft', 'paddingRight']);
                const paddingsSum = Math.ceil(Object.values(paddings).reduce((acc, val) => acc + Number(val), 0));
                const elWidth = this.$el.clientWidth - paddingsSum;
                const logoWidth = this.$refs.logo?.$el.clientWidth ?? 0;
                const controlsWidth = this.$refs.controls.clientWidth;
                if (this[stateVariableName]) {
                    this[stateVariableName] = elWidth < (logoWidth + controlsWidth + widthDifferenceBetweenModes);
                } else {
                    this[stateVariableName] = elWidth < (logoWidth + controlsWidth + extraSpaceBetweenElements);
                }
            } catch (e) {
                console.info('Error header buttons size calc, that\'s probably okay but here is error message:', e);
            }
        },

        reCalcButtonSizes() {
            if (!this.isAuthed) {
                return;
            }

            this.calcControlsButtonSizes({
                stateVariableName: 'displaySmallDepositButton',
                widthDifferenceBetweenModes: this.showUserInfo ? 100 : 70,
                extraSpaceBetweenElements: 15,
                minWidthForCalculation: 340,
            });
        },

        onBurgerClick() {
            this.$burgerMenu.open();
        },
    },
};
</script>

<style lang="stylus" scoped>
#header
    display flex
    flex-direction column
    background-color $dark-900
    height var(--layout-header-height)
    padding-bottom 0
    padding-top 0
    position sticky
    top 0
    top var(--safe-area-inset-top, 0)
    width 100%
    z-index $layout-header-index

    @media (display-mode: standalone)
        top 0

    &.game-related
        position fixed
        height var(--layout-bottom-header-height)
        border-bottom-width 0
        padding-left 0
        padding-left var(--safe-area-inset-left)
        padding-right 3px
        padding-right calc(3px + var(--safe-area-inset-right))
        top auto
        bottom 0
        bottom var(--safe-area-inset-bottom, 0)

        .control-item, .deposit-button
            height 24px
            border-radius 6px
            padding 0 10px
            font-size 12px

    &.invest-theme
        background #0a052c
        background var(--main-background-color, #0a052c)

    &.leaderboard-theme
        background-color #000113
        background var(--main-background-color, #000113)

    &.no-sticky
        position absolute

.header-content
    display flex
    align-items center
    flex-grow 1
    padding-left $md
    padding-right $md
    border-bottom 1px solid $dark-700

    & > *
        flex-shrink 0

.balance
    display flex
    align-items flex-end

    .balance-container
        text-align right
        flex none

        .balance-text
            color alpha(#94a6cd, .7)
            font-size 11px
            line-height 1.2
            margin-bottom 1px

        .balance-value
            font-size 14px

            &.with-balance-button
                display flex
                align-items center

            .balance-trunc
                font-weight 500
                line-height 17px

            .balance-fraction
            .balance-currency
                font-weight $fw-light
                line-height 17px

        .name-value
            white-space nowrap
            overflow hidden
            text-overflow ellipsis
            max-width 130px

.deposit-button
    height 30px
    border-radius 8px
    margin-left 10px
    box-shadow 0 6px 18px 0 alpha(#0EA151, .3)
    background-image linear-gradient(248deg, #009746 100%, #38c172 0%)
    font-weight 500
    font-size 13px
    line-height 18px
    letter-spacing -0.15px
    padding 0 14px
    display flex
    align-items center
    justify-content center
    flex-direction column
    position relative
    overflow hidden

    &::before
        content ''
        position absolute
        top calc(50% - 57px)
        width 65px
        height 92px
        animation flare-2 5s infinite ease-in-out
        background-image radial-gradient(19.58% 37.96% at 16.68% 41.55%, alpha(#fff, 0.6) 0%, alpha(#fff, 0) 100%)

    &.small
        height 18px
        width 18px
        padding 0
        margin-right 7px
        border-radius 50%

.small-deposit-button
    display inline-block
    height 18px
    width 18px
    padding 0
    min-width 0
    min-height 0
    border-radius 50%
    font-size 10px
    margin-right 7px

.profile-button
    padding 0
    min-width 30px
    height 30px
    border-radius 8px
    font-size 12px

.burger-button
    margin-left 15px

.controls
.controls-wrapper
    align-items center
    display flex
    flex-direction row
    margin-left auto

    .control-item:not(:first-child)
        margin-left $sm

.control-item__inner
    display inline-flex
    flex-flow column nowrap
    align-items center
    justify-content center
    padding 0 5.5px

.login
    border-radius 10px
    font-size 14px
    flex-grow 0.67

    .control-item__icon
        display none
        font-size 13px
        margin 0

    .control-item__title
        margin-top -1px

.control-item__title
    font-weight 500

.control-item__subtitle
    font-size 8px
    font-weight 400
    opacity .7

.registration
    border-radius 10px
    overflow hidden
    position relative
    transform translateZ(0)
    font-size 14px
    flex-grow 1

    &::before
        background-color alpha(white, .2)
        bottom 0
        content ''
        position absolute
        top 0
        transform skewX(-30deg)
        width 30px
        will-change left
        z-index 1
        animation flare 5s infinite ease

    :deep(.control-item__inner)
        white-space nowrap

    .control-item__title
        margin-top -1px

.back-button
    height 30px
    width 30px
    min-width 30px
    padding 0 0
    border-radius 8px
    margin-right 9px

.logo-link
    display flex
    align-items center
    justify-content center
    width 68px
    height 34px

    :deep(img)
        min-width 90px
        height 45px
        margin-top 3%

    &.invest
        width fit-content
        height fit-content

@media all and (max-width: 339px)
    #header > *
        flex-shrink 1
        flex-shrink revert

.locale-flag
    height 15px
    width 15px
    margin-left 3px
    position relative
    bottom -2px
    border-radius 50%

.header-buttons
    display flex
    padding 10px 15px 5px

    & > *
        margin-right 10px

        &:last-child
            margin-right 0
</style>
