<template lang="pug">
.control(:class="controlClass")
    VIcon.control-left.control-left-icon(
        v-if="newIconLeft"
        :icon="newIconLeft"
        square
    )

    .control-left(v-if="$slots.left")
        slot(name="left")

    .input-container
        input.input(
            ref="input"
            :class="inputClass"
            :type="passwordVisible ? 'text' : type"
            :value="value"
            v-bind="$attrs"
            :disabled="disabled"
            :name="name"
            :autocomplete="autocomplete"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            v-deferred-focus
            @keydown="$emit('keydown', $event)"
            @keyup.enter="$emit('enter', $event)"
        )

        VInputCustomMessage(
            v-if="customMessage"
            :customMessage="customMessage"
            @customMessageAction="$emit('customMessageAction', customMessage.actionName)"
            @customMessageClose="$emit('customMessageClose')"
        )

        .error-text(v-show="showError") {{ errorText }}

    .control-right
        .toggler-visible-password-container(
            v-if="toggleVisiblePassword"
            :class="{ visible: passwordVisible }"
            @mousedown="toggleVisiblePass"
        )
            VIcon(icon="eye")
        .error-dot(v-if="showError") &bull;
        VIcon(
            v-if="newIconRight"
            :icon="newIconRight"
            @click.stop="onRightClick"
            margin
            square
        )
        slot(
            v-if="$slots.right"
            name="right"
        )
    .control-action(
        v-if="checkValue && actionLabel && actionHandler"
        @click="actionHandler"
    ) {{ actionLabel }}
</template>

<script>
import { createTextMaskInputElement } from 'text-mask-core';

import VInputCustomMessage from '@/components/VInputCustomMessage.vue';
import { V_INPUT_CUSTOM_MESSAGE_PROP_VALIDATOR } from '@/constant/constants';
import deferredFocus from '@/directives/deferredFocus';

export default {
    components: { VInputCustomMessage },

    directives: { deferredFocus },

    inheritAttrs: false,

    props: {
        name: {
            type: String,
            default: null,
        },
        autocomplete: {
            type: String,
            default: null,
        },
        iconLeft: {
            type: String,
            default: null,
        },
        disableLeftPadding: Boolean,
        iconRight: {
            type: String,
            default: null,
        },
        disableRightPadding: Boolean,
        loading: Boolean,
        maxlength: {
            type: [Number, String],
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        disabled: Boolean,
        // validation props
        error: Boolean,
        errorText: {
            type: String,
            default: null,
        },
        mask: {
            type: Array, // mask, see https://text-mask.github.io/text-mask/
            default: null,
        },
        theme: {
            type: String,
            default: 'default',
            validator: (it) => [
                'default',
                'grey',
                'light-grey',
                'white',
                'white-outline',
            ].includes(it),
        },
        actionLabel: {
            type: String,
            default: null,
        },
        actionHandler: {
            type: Function,
            default: null,
        },
        checkAction: Boolean,
        customMessage: {
            type: Object,
            validator: V_INPUT_CUSTOM_MESSAGE_PROP_VALIDATOR,
            default: null,
        },
        toggleVisiblePassword: Boolean,
        focusTrigger: Boolean,
    },

    data: () => ({
        isFocused: false,
        showError: false,
        passwordVisible: false,
    }),

    computed: {
        newIconLeft() {
            if (this.$slots.left) return undefined;
            if (this.iconLeft) return this.iconLeft;
            if (this.type === 'search') return 'search';
            return undefined;
        },
        newIconRight() {
            if (this.$slots.right) return undefined;
            if (this.iconRight) return this.iconRight;
            if (this.loading) return 'spinner';
            if (this.type === 'search' && this.value) return 'times-circle';
            return undefined;
        },
        controlClass() {
            return {
                focused: this.isFocused,
                disabled: this.disabled,
                'disable-left-padding': this.disableLeftPadding,
                'disable-right-padding': this.disableRightPadding,
                [this.theme]: this.theme,
            };
        },
        inputClass() {
            return {
                error: this.showError,
                'has-value': this.value?.length > 0,
                [this.theme]: this.theme,
            };
        },
        checkValue() {
            return this.checkAction === undefined ? this.value : this.checkAction;
        },
    },

    watch: {
        mask() {
            this.initMaskController();
        },
        error(value) {
            if (!value) {
                this.hideError();
                return;
            }
            if (!this.isFocused) {
                this.enableErrorShow();
            }
        },
        focusTrigger() {
            this.focus();
        },
    },

    mounted() {
        if (this.mask) {
            this.initMaskController();
        }
    },

    methods: {
        onRightClick() {
            if (this.loading) return;
            if (this.type === 'search') {
                this.$emit('input', '');
                this.$nextTick(() => this.$refs.input.focus());
            }
        },

        onBlur(event) {
            this.isFocused = false;
            if (this.error) this.enableErrorShow();
            this.$emit('blur', event);
        },

        onFocus(event) {
            this.isFocused = true;
            if (this.showError) this.hideError();
            this.$emit('focus', event);
        },

        initMaskController() {
            this.maskController = createTextMaskInputElement({
                inputElement: this.$refs.input,
                mask: this.mask,
                guide: false,
            });
            this.maskController.update();
        },

        enableErrorShow() {
            this.showError = true;
        },

        hideError() {
            this.showError = false;
        },

        onInput(event) {
            let eventTargetValue = event.target.value;
            if (this.type === 'number') {
                if (event.target.value && +event.target.value === +this.value) {
                    eventTargetValue = this.value;
                }
            }

            if (this.maskController) {
                this.maskController.update();
            }

            this.$emit('input', eventTargetValue);

            // Для жесткой привязки value к значению дом элемента. Не совмесимо с масками
            if (!this.maskController) {
                this.$nextTick(() => {
                    if (this.value !== eventTargetValue) event.target.value = this.value;
                });
            }
        },
        focus() {
            this.$refs.input.focus();
        },
        toggleVisiblePass(e) {
            e.preventDefault();
            this.passwordVisible = !this.passwordVisible;
        },
    },
};
</script>

<style lang="stylus" scoped>
$control-height = 36px
$icon-height = ($control-height / 3)

.control
    display flex
    height $control-height
    width 100%
    border-radius $radius-md
    padding 0 $md
    transition all .2s
    position relative

    &.disabled
        opacity .8

    &.disable-left-padding
        padding-left 0

    &.disable-right-padding
        padding-right 0

    &.default
        background-color $dark-800

        &.focused
            border-color $primary-800

    // Темы
    &.grey
        background-color #ebebeb

        &.focused
            border-color $gray-600

    &.light-grey
        background-color alpha($dark-10, .2)
        color #6a7690

    &.white
        background-color #fff

.input-container
    width 100%
    height 100%
    position relative

    .error-text
        display flex
        align-items center
        top 0
        left 0
        width 100%
        height 100%
        position absolute
        color #d53e3e
        font-size 14px
        pointer-events none
        user-select none

.input
    font-size 14px
    font-stretch normal
    font-style normal
    font-weight $fw-normal
    letter-spacing normal
    line-height normal
    height 100%
    width 100%
    text-decoration none
    padding 0
    display flex
    align-items center

    &.default
        caret-color $primary-500
        color $white

    &.grey
        caret-color $gray-600
        color $gray-800

        &::placeholder
            color #7b7b7b

    &.light-grey
        caret-color #1A88F3
        color alpha(#090F1E, .8)
        font-size 13px

    &.white-outline
        caret-color #1A88F3
        font-size 13px
        color alpha(#090F1E, .8)

    &.error
        opacity 0

        &::placeholder
            opacity 0

    &::placeholder
      color alpha(#6a7690, .5)
      font-size 13px

    &::placeholder
        Input_label()

    &:not(.light-grey):not(.white-outline)
        &:-webkit-autofill
        &:-webkit-autofill:hover
        &:-webkit-autofill:focus
            -webkit-text-fill-color: $white

    &[type="date"]
        &:before
            content attr(placeholder)
            Input_label()

        &::-webkit-date-and-time-value
            text-align left

        &::-webkit-datetime-edit-fields-wrapper
            padding 0

        &::-webkit-clear-button
            display none

        &::-webkit-calendar-picker-indicator
            pointer-events auto
            width 100%
            position absolute
            height 100%
            top 0
            left 0
            cursor pointer
            opacity 0

        &::-webkit-datetime-edit-day-field
        &::-webkit-datetime-edit-month-field
        &::-webkit-datetime-edit-year-field
            background transparent
            color currentColor
            padding 0

        &::placeholder-shown
            opacity 0

        &:not(.has-value)::-webkit-datetime-edit
            opacity 0

.control-left
.control-right
    display flex
    align-items center
    justify-content center
    height 100%
    color $gray-300
    font-size $icon-height

.control-left-icon

    &:not(:first-child)
        margin-left .5em

    &:not(:last-child)
        margin-right .5em

.icon-spinner
    animation spinner .75s infinite linear

.error-dot
    color #d53e3e
    font-size 18px
    margin-bottom 3px

    &:not(:last-child)
        margin-right $xs

.control-action
    display flex
    align-items center
    font-size 13px
    font-weight 500
    color $primary-750
    cursor pointer

.toggler-visible-password-container
    height 100%
    display flex
    align-items center
    padding 0 10px
    cursor pointer
    color alpha(#6A7690, .2)

    &.visible
        color alpha(#6A7690, .8)
</style>
