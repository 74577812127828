import {
    UserIntention,
} from '@/constant/constants';
import { PromiseEnum } from '@/store/promise';
import { isCommonGame } from '@/utils/casinoGames';

import {
    MODAL_REGISTER as DESKTOP_MODAL_REGISTER,
    MODAL_DEPOSIT as DESKTOP_MODAL_DEPOSIT,
} from '@desktop/constant/modalName';
import DESKTOP_ROUTE_NAME from '@desktop/constant/routeName';
import {
    MODAL_NOT_ENOUGH_MONEY as MOBILE_MODAL_NOT_ENOUGH_MONEY,
    MODAL_NOT_LOGGED as MOBILE_MODAL_NOT_LOGGED,
} from '@mobile/constant/modalName';
import MOBILE_ROUTE_NAME from '@mobile/constant/routeName';

export default {
    inject: [
        '$modal',
        'userIntention',
    ],

    methods: {
        /**
         * Функция пытается открыть игру.
         * Если это не common игра, то мы просто редиректим на обычную версию игры.
         * Если это common игра, то функция попытается открыть ее если это возможно
         * или редиректнет на другую версию игры.
         * Если и другую версию игры мы не хотим показывать по какой-либо причине,
         * то покажем одну из модалок логина/реги/депозита.
         *
         * Если отрывать игры по прямому роуту:
         *   Для не common игр:
         *      Роутер будет пофикшен методом fixRoutePotentialIncoherence из общего миксина.
         *      И в итоге будет открыта обычная версия игры.
         *   Для common игр:
         *      Вначале мы пытаемся понять, следует ли вообще открыть игру по заданным в роуте параметрам.
         *      Это делается методом shouldOpenCommonGame:
         *          Если следует
         *              Компонент откроет игру.
         *          Если не следует
         *              Компонент вызовет метод tryOpenGame, чтобы либо редиректнуть на другую доступную версию игры,
         *              либо открыть модалку.
         *
         * В итоге, чтобы открыть любую игру из кода, достаточно вызвать этот метод.
         * Альтернативно, можно сделать редирект на нужный роут, и все тоже отработает как надо.
         * @param id
         * @param hasDemo
         * @returns {boolean}
         *      true - удалось найти подходящую версию игры для юзера и был сделан редирект на нее
         *      false - поняли что не можем показать никакую версию игры, открыли какую-то из модалок
         */
        async tryOpenGame({ id, hasDemo }) {
            if (!isCommonGame(id)) {
                // Если это не common игра, то мы просто редиректим на страницу обычной игры
                this.redirectToGame(id);
                return true;
            }

            // Логика ниже, по факту, затрагивает только common игры

            await this.$store.getters.promiseByName[PromiseEnum.AUTH];
            if (this.isAuthed) {
                await this.$store.getters.promiseByName[PromiseEnum.USER_BALANCE];
            }

            const {
                isAuthed,
                balanceCurrentEmpty,
                'casino/hasFreeSpinsByGameId': hasFreeSpinsByGameId,
            } = this.$store.getters;

            if (isAuthed && balanceCurrentEmpty) {
                const hasUserGameFreeSpins = hasFreeSpinsByGameId(id);

                // Если авторизован, баланс нулевой, демо агра и есть фриспины => редиректнем на обычную игру
                if (hasUserGameFreeSpins && hasDemo) {
                    this.redirectToGame(id);
                    return true;
                }

                // Если авторизован, баланс нулевой и нет демо версии игры и нет фриспинов => откроем модалку депозита
                if (!hasUserGameFreeSpins && !hasDemo) {
                    this.userIntention.log(UserIntention.OPEN_CASINO_GAME, { id });

                    this.$modal.open(
                        this.$store.getters.isMobile
                            ? MOBILE_MODAL_NOT_ENOUGH_MONEY
                            : DESKTOP_MODAL_DEPOSIT,
                        {
                            closeOnBackgroundClick: false,
                            ...(
                                this.$store.getters.isMobile && {
                                    props: {
                                        message: this.$store.getters.lang['casino-modal-no-money'].description,
                                    },
                                }
                            ),
                        },
                    );

                    return false;
                }
            }

            // Если юзер не авторизован или имеет нулевой баланс, и есть демо версия игры => откроем демо версию
            if ((!isAuthed || balanceCurrentEmpty) && hasDemo) {
                this.redirectToGame(id, true);
                return true;
            }

            // Если не авторизован, и нет демо версии игры => откроем модалку реги
            if (!isAuthed && !hasDemo) {
                this.$modal.open(
                    this.$store.getters.isMobile
                        ? MOBILE_MODAL_NOT_LOGGED
                        : DESKTOP_MODAL_REGISTER,
                    {
                        closeOnBackgroundClick: !this.$store.getters.isMobile,
                        ...(
                            this.$store.getters.isMobile && {
                                props: {
                                    message: this.$store.getters.lang['casino-modal-auth'].description,
                                },
                            }
                        ),
                        onClose: () => {
                            if (!this.$store.getters.isAuthed) {
                                return;
                            }

                            if (this.$store.getters.balanceCurrentEmpty) {
                                this.$modal.open(
                                    this.$store.getters.isMobile
                                        ? MOBILE_MODAL_NOT_ENOUGH_MONEY
                                        : DESKTOP_MODAL_DEPOSIT,
                                );
                                return;
                            }

                            this.$nextTick(() => this.redirectToGame(id));
                        },
                    },
                );

                return false;
            }

            this.redirectToGame(id);
            return true;
        },

        /**
         * @param id
         * @param demoRequired
         * @private
         */
        redirectToGame(id, demoRequired = false) {
            this.$router.push({
                name: this.$store.getters.isMobile
                    ? MOBILE_ROUTE_NAME.CASINO_GAME
                    : DESKTOP_ROUTE_NAME.CASINO_GAME,
                params: {
                    id,
                    isDemo: demoRequired ? 'demo' : undefined,
                },
            });
        },
    },
};
