<template lang="pug">
label.v-radio(
    role="radio"
    :class="[{ 'v-radio--disabled': disabled, [size]: size }, `theme-${theme}`]"
    :aria-checked="checked"
    :aria-disabled="disabled"
)
    span.input(
        :class=`{
            'checked': checked,
            'disabled': disabled,
        }`
    )
    input.input-original(
        type="radio"
        aria-hidden="true"
        v-bind="$attrs"
        :disabled="disabled"
        :checked="checked"
        :value="value"
        @input="$emit('input', value)"
        @change="$emit('input', value)"
    )
    slot
</template>

<script>
export default {
    name: 'VRadio',

    model: {
        prop: 'modelValue',
        event: 'input',
    },

    props: {
        value: {
            required: true,
            validator: () => true,
        },
        disabled: Boolean,
        modelValue: {
            type: [String, Number, Boolean],
            default: undefined,
        },
        size: {
            type: String,
            default: null,
            validator: (it) => ['md'].includes(it),
        },
        theme: {
            type: String,
            default: 'default',
            validator: (theme) => ['default', 'grey'].includes(theme),
        },
    },

    computed: {
        checked() {
            return this.modelValue === this.value;
        },
    },
};
</script>

<style lang="stylus" scoped>
$size = 16px
$sizeMd = 20px

.v-radio
    align-items center
    color $white
    cursor pointer
    display inline-flex
    font-size 13px
    position relative
    user-select none

    &--disabled
        color #3e4760

    .input
        display inline-block
        background-color $dark-325
        border-radius $radius-circle
        color #090f1e
        flex none
        height $size
        width $size
        margin-right $xs
        position relative
        transition all .15s

        &::after
            content ''
            background-color currentColor
            border-radius $radius-circle
            height 5px
            width 5px
            position absolute
            left 50%
            top 50%
            transform translate(-50%, -50%)
            transition background-color .15s

        &.checked // TODO дизайн состояния
            background-image $primary-gradient
            box-shadow $primary-shadow
            color $white

        &.disabled // TODO дизайн состояния
            opacity .5

    &.theme-grey
        .input
            background-color rgba(151, 174, 225, 0.2);

    &.md .input
        height $sizeMd
        width $sizeMd

        &::after
            height 7px
            width 7px

    .input-original
        height 0
        left 0
        opacity 0
        position absolute
        top 0
        width 0
        z-index -1
</style>
