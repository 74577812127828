<template lang="pug">
.slider_universal-slide
    VPicture.slider_universal-slide-background(
        :picture="this.slide.background"
        fit="cover"
    )
    .slider_universal-slide-content
        .slider_universal-slide-info
            .slider_universal-slide-sport(
                v-if="slide.sport"
                :style="sportStyle"
            )
                VIcon(
                    :icon="slide.sport"
                    module="sport"
                )
            .slider_universal-slide-date(
                v-if="date"
                :style="dateStyle"
            )
                span.slider_universal-slide-date-from(v-if="date[0]")
                    | {{ $filters.capitalize(date[0].month) }}&nbsp;
                    span.slider_universal-slide-date-num {{ date[0].day }}
                span.slider_universal-slide-date-to(v-if="date[1]")
                    | &nbsp;- {{ $filters.capitalize(date[1].month) }}&nbsp;
                    span.slider_universal-slide-date-num {{ date[1].day }}
        .slider_universal-slide-heading(:style="headingStyle") {{ heading.text }}
        .slider_universal-slide-subheading(:style="subheadingStyle") {{ subheading.text }}
</template>

<script>
import { mapGetters } from 'vuex';

import VPicture from '@/components/VPicture.vue';
import { getMaximumFontSizeFromRect } from '@/utils/getMaximumFontSizeFromRect';

export default {
    name: 'UniversalBanner',

    components: {
        VPicture,
    },

    inject: ['$filters'],

    props: {
        slide: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            windowWidth: 375,
        };
    },

    computed: {
        ...mapGetters(['langCommon']),
        sportStyle() {
            return {
                fontSize: `${this.getScaledNum(12)}px`,
            };
        },
        dateArray() {
            const { eventDate } = this.slide;
            const type = (typeof eventDate).toLowerCase();
            if (type === 'string' && this.validDate(eventDate)) {
                return [eventDate];
            }
            if (type === 'object' && eventDate.length && eventDate.slice(0, 2).reduce((acc, it) => acc && this.validDate(it), true)) {
                return eventDate;
            }
            return 0;
        },
        date() {
            if (!this.dateArray) return false;
            return this.dateArray.map((date) => ({
                day: +date.split('.')[0],
                month: this.langCommon.months[`month_${+date.split('.')[1]}`].trim(),
            }));
        },
        dateStyle() {
            return {
                fontSize: `${this.getScaledNum(10)}px`,
                marginBottom: `${this.getScaledNum(1)}px`,
            };
        },
        heading() {
            return getMaximumFontSizeFromRect({
                text: this.$filters.locale(this.slide.heading),
                minSize: 13,
                maxSize: 25,
                maxWidth: 165,
                maxHeight: 42,
                styles: {
                    fontFamily: "'SF Pro Display', 'SF Pro Text',  sans-serif",
                    fontWeight: 900,
                    letterSpacing: (fontSize) => `${(2 - 7 * fontSize) / 750}px`,
                },
            });
        },
        headingStyle() {
            return {
                fontSize: `${this.getScaledNum(this.heading.fontSize)}px`,
                letterSpacing: `${(2 - 7 * this.getScaledNum(this.heading.fontSize)) / 750}px`,
            };
        },
        subheading() {
            return getMaximumFontSizeFromRect({
                text: this.$filters.locale(this.slide.subheading),
                minSize: 10,
                maxSize: 12,
                maxWidth: 230,
                maxHeight: 32,
                styles: {
                    fontFamily: "'SF Pro Text', sans-serif",
                    fontWeight: 500,
                },
                charsForWrap: 20,
            });
        },
        subheadingStyle() {
            return {
                fontSize: `${this.getScaledNum(this.subheading.fontSize)}px`,
            };
        },
    },

    mounted() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        validDate(date) {
            if (!/^\d{1,2}.\d{1,2}$/.test(date)) {
                return false;
            }
            const [day, month] = date.split('.').map((it) => +it);
            return day >= 1 && day <= 31 && month >= 1 && month <= 12;
        },
        getScaledNum(num) {
            if (this.windowWidth < 420) {
                return (this.windowWidth - 30) * (num / 345);
            }
            if (this.windowWidth < 620) {
                return num * 1.13;
            }
            return (this.windowWidth - 30) * num * (1.13 / 590);
        },
        onResize() {
            this.windowWidth = document.documentElement.clientWidth;
        },
    },
};
</script>

<style lang="stylus" scoped>
.slider_universal-slide
    position absolute
    bottom 0
    left 0
    right 0
    top 0
    border-radius $radius-md
    background-size cover
    background-position center

    .slider_universal-slide-background
        height 100%
        width 100%
        position absolute

        img
            height 100%
            width 100%

    .slider_universal-slide-content
        position absolute
        display flex
        height 100%
        width 100%
        flex-direction column
        justify-content center
        align-items flex-start
        user-select none
        margin-left calc((100vw - 30px) * 25 / 345)

        .slider_universal-slide-info
            display flex
            align-items center
            margin-bottom 7px

            .slider_universal-slide-sport
                margin-right .42em

            .slider_universal-slide-date
                opacity .7
                font-weight 500

        .slider_universal-slide-heading
            font-weight 900
            font-family $ff-heading
            white-space pre-line
            text-shadow 1px 1px 3px #000

        .slider_universal-slide-subheading
            font-weight 500
            opacity .7
            white-space pre-line
            text-shadow 1px 1px 3px #000
            margin-top 8px
</style>
