<template lang="pug">
.burger__container(
    :class="burgerContainerClass"
    ref="burgerContainerRef"
)
    transition(
        name="burger__background-animation"
    )
        .burger__background(
            v-if="$burgerMenu.isOpened"
            @click.stop="$burgerMenu.close"
        )
    transition(
        name="burger-menu-animation"
        @before-enter="onStartAnimationHandler"
        @after-enter="onEndAnimationHandler"
        @before-leave="onStartAnimationHandler"
        @after-leave="onEndAnimationHandler"
    )
        .burger__menu-vertical-inset-wrapper(
            v-if="$burgerMenu.isOpened"
        )
            .burger__menu-horizontal-inset-wrapper
                .burger__menu-container
                    .burger__burger-menu
                        slot
                    .burger__close-button(
                        @click="$burgerMenu.close"
                    )
                        VIcon.burger__close-button-icon(
                            icon="close"
                        )
</template>

<script>
export default {
    name: 'Burger',

    data() {
        return {
            animationInProgress: false,
        };
    },

    computed: {
        isBurgerContainerOpened() {
            return this.$burgerMenu.isOpened || this.animationInProgress;
        },
        burgerContainerClass() {
            return { 'is-opened': this.isBurgerContainerOpened };
        },
    },

    mounted() {
        this.initBurgerNotAllowedActionsPreventer();
    },

    methods: {
        initBurgerNotAllowedActionsPreventer() {
            /* To prevent scroll problems
                    - background scroll problem (safari)
                    - ignored scroll problem (old ios, safari)
               Only allowed burger actions will pop up.
            */
            this.$refs.burgerContainerRef.addEventListener('touchmove', (event) => {
                if (event.burgerAllowedTouchmoveAction) return;
                if (event.cancelable) event.preventDefault();
            });
        },
        onStartAnimationHandler() {
            this.animationInProgress = true;
        },
        onEndAnimationHandler() {
            this.animationInProgress = false;
        },
    },
};
</script>

<style lang="stylus" scoped>
$animationDuration = .3s
$burgerMenuWidth = 'calc(275px + %s)' % var(--safe-area-inset-right)

.burger__container
    height 0
    width 100%
    position fixed
    z-index 4900
    top 0
    left 0
    overflow hidden

    &.is-opened
        height 100%

    .burger__background
        position absolute
        right 0
        top 0
        left 0
        bottom 0
        backdrop-filter blur(6px)

    .burger__menu-vertical-inset-wrapper
        height calc(var(--vh, 1vh) * 100)
        position absolute
        right 0
        top 0
        width $burgerMenuWidth

        padding-top 0px
        padding-top var(--safe-area-inset-top)
        //padding-bottom 0px                             // uncomment if you need bottom inset padding
        //padding-bottom var(--safe-area-inset-bottom)   // uncomment if you need bottom inset padding

        .burger__menu-horizontal-inset-wrapper
            //we need additional horizontal inset wrapper to fill right inset background
            height 100%
            width 100%
            position relative
            background linear-gradient(173deg,rgba(37,46,76,.98) 2%, rgba(25,32,51,.98))
            border-radius 20px 0 0 20px
            padding-right 0px
            padding-right var(--safe-area-inset-right)

            .burger__menu-container
                height 100%
                width 100%
                position relative

                .burger__close-button
                    position absolute
                    height 56px
                    width 50px
                    top 0
                    right 0
                    display flex
                    justify-content flex-start
                    align-items flex-end
                    margin-left auto

                    .burger__close-button-icon
                        position relative
                        bottom 10px
                        left 10px

                .burger__burger-menu
                    height 100%

/* animations */
.burger__background-animation-enter,
.burger__background-animation-leave-to
    opacity 0
.burger__background-animation-enter-active,
.burger__background-animation-leave-active
    transition all $animationDuration ease-in-out
.burger__background-animation-enter-to,
.burger__background-animation-leave
    opacity 1

.burger-menu-animation-enter,
.burger-menu-animation-leave-to
    opacity 0
    transform translateX($burgerMenuWidth)
.burger-menu-animation-enter-active,
.burger-menu-animation-leave-active
    transition all $animationDuration ease-in-out
.burger-menu-animation-enter-to,
.burger-menu-animation-leave
    opacity 1
    transform translateX(0)
/* -- animations -- */

</style>
