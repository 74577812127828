<template>
  <div :class="[$style.container, investRules && $style.invest]">
    <div :class="$style.main">
      <div>
        <div :class="$style.title">
          <span>{{ text.social.suportTitle }} 24/7</span>
        </div>
        <div :class="$style.subtitle">
          {{ text.social.subtitle }}
        </div>
      </div>
      <div :class="$style.icons">
        <SupportChatTrigger>
          <template #default="{ icon, open, spin }">
            <div
              :class="$style.button"
              @click="open"
            >
              <VIcon
                v-if="spin"
                spin
                :icon="icon"
              />
              <span v-else>{{ text.chat }}</span>
            </div>
          </template>
        </SupportChatTrigger>
        <div
          v-if="supportNumber"
          :class="$style.iconWrapper"
        >
          <VIcon
            icon="phone"
            :class="$style.icon"
            :size="10"
            @click="call"
          />
        </div>
      </div>
      <button :class="$style.buttonContacts">
        <VIcon
          :class="[$style.icon, showContacts && $style.opened]"
          icon="chevron-down"
          @click="showContacts = !showContacts"
        />
      </button>
    </div>
    <div
      v-if="showContacts"
      :class="$style.contacts"
    >
      <div>
        <div
          v-for="contact in contacts"
          :key="contact.nameKey"
          :class="$style.contactsName"
        >
          {{ contact.name }}
        </div>
      </div>
      <div>
        <div
          v-for="contact in contacts"
          :key="contact.id"
          :class="$style.contactsValue"
        >
          <a
            :href="`mailto:${contact.email}`"
            target="_blank"
          >
            {{ contact.email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import VIcon from '@/components/Icons/VIcon.vue';
import SupportChatTrigger from '@/components/SupportChatTrigger.vue';

import config from '@config/app.config';

export default {
    name: 'SupportSection',

    components: {
        SupportChatTrigger,
        VIcon,
    },

    props: {
        investRules: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        showContacts: false,
    }),

    computed: {
        ...mapGetters([
            'lang',
            'langCommon',
            'supportNumber',
        ]),

        text() {
            return this.lang.Footer;
        },

        contacts() {
            return config.contacts.map(({ nameKey, email }) => ({
                email,
                nameKey,
                name: this.langCommon.contacts[nameKey],
            }));
        },
    },

    methods: {
        call() {
            window.open(`tel:${this.supportNumber}`);
        },
    },
};
</script>

<style lang="stylus" module>
.container
    display flex
    flex-direction column
    background linear-gradient(107.15deg, #1E283F 0%, rgba($dark-700, .6) 100%)
    padding 10px
    border-radius 12px

    &.invest
        background $gray-gradient

.main
    display flex
    align-items center

.title
    font-weight 600
    font-size 10px
    line-height 13px

.subtitle
    font-size 8px
    line-height 9px
    color alpha(#fff, .5)
    margin-top 3px

.icons
    display flex
    flex-grow 1
    flex-direction row
    justify-content flex-end

.button
    flex none
    padding 0 10px
    height 25px
    min-width 75px
    border-radius $radius-circle
    display flex
    align-items center
    justify-content center
    white-space nowrap
    background-image linear-gradient(107.15deg, #0095FF 0%, #0855C4 100%)
    box-shadow $primary-shadow
    font-weight 600
    font-size 11px
    line-height 13px
    letter-spacing .06px
    text-shadow 0px 3px 5px alpha($dark-900, .2)

.iconWrapper
    width 25px
    height 25px
    display flex
    align-items center
    justify-content center
    background-image linear-gradient(107.15deg, #0095FF 0%, #0855C4 100%)
    box-shadow $primary-shadow
    border-radius 50%

.icon
    color #fff
    width 10px
    height 10px
    transition transform .15s linear

    &.opened
        transform rotate(180deg)

.iconWrapper
.button
    margin-left 10px
    margin-right 0

.contacts
    margin-top 10px
    padding-top 10px
    border-top 1px solid alpha(#5c72a3, 0.5)
    display flex
    justify-content space-between

.contactsName
.contactsValue
    font-weight 400
    font-size 11px
    line-height 16px
    letter-spacing 0.2px

    &:not(:last-child)
        margin-bottom 8px

.contactsName
    color alpha(#8692ac, 0.65)

.buttonContacts
    display flex
    align-items center
    justify-content center
    height 24px
    width 24px
    background-color alpha(#4E5D86, 0.3)
    border-radius 6px
    margin-left 15px
</style>
