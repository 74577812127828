<script>
export default {
    render() {
        const children = this.$slots.default.map((child) => {
            child.data.class = 'menu-bar-item';

            const { propsData = {} } = child.componentOptions ?? {};

            if ('active' in propsData && propsData.active !== false) {
                child.data.class += ' active';
            }

            return child;
        });

        return (
            <section class="menu-bar" on={this.$listeners}>
                {children}
            </section>
        );
    },
};
</script>

<style lang="stylus" scoped>
.menu-bar
    align-items center
    display flex
    overflow-x scroll
    overflow-y hidden
    padding 0 $sm
    scrollbar-width none
    width 100%
    -webkit-overflow-scrolling touch

    &::-webkit-scrollbar
        display none !important
        width 0 !important
        background-color transparent !important

    &::-webkit-scrollbar-track
        background-color transparent !important

    &::-webkit-scrollbar-thumb
        background-color transparent !important

    &::after
        content ''
        padding-left $sm

:deep(.menu-bar-item)
    align-items center
    border-radius $radius-circle
    color $white
    display flex
    flex none
    font-size 14px
    font-weight $fw-semi-bold
    height 24px
    line-height 1
    padding 0 $sm
    white-space nowrap

    &:not(:first-child)
        margin-left $xs

    &.active
    &.router-link-exact-active
    &[active="true"]
        background-image $primary-gradient
        box-shadow $primary-shadow
</style>
