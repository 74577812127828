import {
    BETS_TAB_EVENTS,
    BETS_TAB_SPORTS,
    SERVICE_LIVE,
    SERVICE_PREMATCH,
} from '@/constant/constants';

import mutations from './MobileHeaderMutations';

function createMobileHeaderStore(service) {
    return {
        namespaced: true,
        state: {
            activeTab: service === SERVICE_LIVE ? BETS_TAB_EVENTS : BETS_TAB_SPORTS,
            collapseOpenned: true,
            hotFilter: false,
            favouriteFilter: false,
            sportsFilter: [],
            options: {},
        },
        mutations,
    };
}

export default {
    namespaced: true,
    modules: {
        [SERVICE_LIVE]: createMobileHeaderStore(SERVICE_LIVE),
        [SERVICE_PREMATCH]: createMobileHeaderStore(SERVICE_PREMATCH),
        esport: createMobileHeaderStore('esport'),
    },
};
