import { createStore as defaultCreateStore } from '@/store/MainStore';

import MobileHeaderStore from '@mobile/store/bets/mobileHeader/MobileHeaderStore';
import Layout from '@mobile/store/layout';
import NavigationMenu from '@mobile/store/navigationMenu';

/**
 * @returns {import('vuex').Store}
 */
export function createStore() {
    const store = defaultCreateStore();

    // register mobile modules
    store.registerModule(['bets', 'mobileHeader'], MobileHeaderStore);
    store.registerModule(['navigationMenu'], NavigationMenu);
    store.registerModule(['layout'], Layout);

    store.commit('setIsMobile', true);

    return store;
}
