<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'CasinoRandomGameGetter',

    props: {
        categoryId: {
            type: [String, Number],
            required: true,
        },
    },

    computed: {
        ...mapState([
            'locale',
        ]),
        ...mapGetters([
            'isAuthed',
            'balance',
        ]),
        ...mapState('casino', [
            'gamesByCategoryId',
        ]),

        games() {
            return this.gamesByCategoryId[this.categoryId] ?? [];
        },

        onlyDemo() {
            return !this.isAuthed || this.balance === 0;
        },
    },

    watch: {
        locale() {
            if (this.games.length) {
                this.loadGames(true);
            }
        },
    },

    methods: {
        ...mapActions('casino', [
            'loadGamesByCategoryId',
        ]),

        loadGames(force = false) {
            return this.loadGamesByCategoryId({
                categoryId: this.categoryId,
                force,
            });
        },

        async getRandomGame() {
            await this.loadGames();

            const validGames = this.onlyDemo ? this.games : this.games.filter(({ hasDemo }) => hasDemo);

            const randomIndex = Math.floor(Math.random() * validGames.length - 1);

            return validGames[randomIndex];
        },
    },

    render() {
        return this.$scopedSlots.default({
            getRandomGame: this.getRandomGame,
        });
    },
};
</script>
