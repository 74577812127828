<template lang="pug">
div(:class="$style.cashbackTimer")
    img(
        :src="$options.cashbackBG"
        :class="$style.cashbackTimerBg"
    )
    img(
        :src="$options.cashbackImg"
        :class="$style.cashbackTimerImg"
    )
    div(:class="$style.rightBlock")
        div(:class="$style.cashbackDescription") {{ cashbackDescription }}
        div(:class="$style.cashbackText")
            span(:class="$style.cashbackTextValue") {{ cashbackTextTight }}
</template>

<script>
import cashbackBG from '@/assets/images/cashback/cashback-bg.png';
import cashbackImg from '@/assets/images/cashback/cashback-img.png';
import CashbackTimer from '@/mixins/CashbackTimer';

export default {
    mixins: [CashbackTimer],
    cashbackImg,
    cashbackBG,
};
</script>

<style lang="stylus" module>
.cashbackTimer
    height 50px
    width 100%
    min-width 130px
    display flex
    flex-direction column
    justify-content center
    display flex
    background linear-gradient(127.71deg, #6DFEC1 -33.87%, #28BC10 69.19%)
    box-shadow 0 5px 15px rgba(66, 227, 101, 0.4)
    border-radius 12px
    padding 0 10px
    position relative
    font-variant-numeric tabular-nums

.cashbackTimerBg
.cashbackTimerImg
    bottom 0
    left 0
    position absolute

.cashbackTimerBg
    height 50px
    border-radius 12px 0 0 12px

.cashbackTimerImg
    height 67px
    border-radius 0 0 0 12px

.rightBlock
    margin-left auto

.cashbackDescription
    font-weight 500
    font-size 10px
    line-height 12px
    text-align right
    color alpha(#090F1E, .8)
    padding-left 30px

.cashbackText
    color #090F1E
    font-size 13px
    font-weight 600
    text-align right
    margin-top 2px
    white-space nowrap
    text-overflow ellipsis
    overflow inherit
    line-height 16px

.cashbackTextValue
    display inline-block
    text-align left
</style>
