<template>
  <div :class="$store.getters.isMobile ? $style.rootMobile : $style.root">
    <div v-if="$slots.bookmakerIcon">
      <slot name="bookmakerIcon" />
    </div>

    <div
      v-for="listing in listings"
      :key="listing.href"
      :class="$style.listingWrapper"
    >
      <!-- eslint-disable-next-line vue/require-component-is -->
      <Component v-bind="listing.listeners">
        <img
          :src="listing.img"
          :class="$style.listing"
        >
      </Component>
      <div :class="$style.separator" />
    </div>

    <div
      v-if="$slots.ageLimit"
      :class="$style.ageLimitWrapper"
    >
      <slot name="ageLimit" />
    </div>
  </div>
</template>

<script>
import bestBitcoinCasino from '@/assets/images/listings/best-bitcoin-casino.png';
import casinoMentor from '@/assets/images/listings/casino-mentor.png';
import casinosAnalyzer from '@/assets/images/listings/casinos-analyzer.png';
import cricketBettingGuru from '@/assets/images/listings/cricket-betting-guru.png';
import cricketBettingWali from '@/assets/images/listings/cricket-betting-wali.png';

const blockRedirectCountries = ['RU'];

export default {
    computed: {
        listings() {
            const div = { is: 'div' };

            const a = (link) => ({
                is: 'a',
                href: link,
                target: '_blank',
            });

            return [
                {
                    img: casinoMentor,
                    listeners: a('https://casinomentor.com/reviews/1win-casino'),
                },
                {
                    img: bestBitcoinCasino,
                    listeners: a('https://www.bestbitcoincasino.com/review/1wincasino'),
                },
                {
                    img: casinosAnalyzer,
                    listeners: blockRedirectCountries.includes(this.$store.state.country)
                        ? div
                        : a('https://casinosanalyzer.com/online-casinos/1win.pro'),
                },
                {
                    img: cricketBettingWali,
                    listeners: a('https://cricketbettingwali.in/review/1-win-casino'),
                },
                {
                    img: cricketBettingGuru,
                    listeners: a('https://cricketbettingguru.com/best-cricket-betting-sites/1win-review/'),
                },
            ];
        },
    },
};
</script>

<style lang="stylus" module>
.root
    display flex

.listingWrapper
    display flex
    flex none

.listing
    height 25px

.separator
    height 25px
    width 1px
    background alpha(#97aee1, .11)
    margin 0 10px

.rootMobile
    display flex
    flex-wrap wrap
    max-width 156px

    .listing
        height 18px

    .listingWrapper
        margin 2px 4px

    .separator
        display none

.ageLimitWrapper
    display flex
    align-items center
    justify-content center
</style>
